/// <reference path="../../events/InfographicProfileEvent.ts" />
/// <reference path="../../utils/browser/clientInfo.ts" />

module media.hrratings.infographicProfile{

    import InfographicProfileEvent = media.hrratings.events.InfographicProfileEvent;
    import ClientInfo = media.hrratings.utils.browser.ClientInfo;

    export interface IGraphic {
        createGraphic():void;
    }

    export class CircleGraph extends createjs.EventDispatcher implements IGraphic{

        public data:any;
        public _categoryCounter:number;

        private containerName:string;
        private _theColors: Array<any>;
        private _cGWidht: number;
        private _cGHeight: number;
        private _innerRadius: number;
        private _radius: number;
        private _pie;
        private _allValues:Array<any>;
        private _maxValue: number;
        private _minValue: number;
        private _arc;
        //private _activeArc;
        private _outLineArc;
        private _stage;
        private _path;
        private _audienceCounter;
        private _actualIndex;
        private _color;
        private _colorBlack;
        private _currentColors;
        private _arrayToShow;
        private _linearActiveArc;
        private _version;
        private _initialArc;


        private _theSelected;



        private _thaTitle;
        private _thaSelect;
        private _thaOptions;
        private _thaShow;
        private _thaGraphCont;
        private _thaFooter;
        private _thaTooltip;



        constructor(public thedata:any, public thecontainerName:string){
            super();

            this.data= thedata;
            this.containerName= thecontainerName;
            this.setup();

        }

        private setup(){
            console.log("setup");
            this._thaTitle= $(".prof-header");
            this._thaSelect= $("#prof-category-select");
            this._thaOptions= $("#infographic-profile .prof-controlls-container");
            this._thaShow= $(".prof-description-titles");
            this._thaGraphCont= $("#profile-circle-graph-container");
            this._thaFooter= $("#infographic-profile .infographic-disclamer");
            this._thaTooltip= $("#infographic-profile .infographic-tooltip");

            this._categoryCounter= -1;
            this._actualIndex= 0;
            this._audienceCounter= 0;
            this._allValues= [];
            this._currentColors= [];
            this._theColors= ["#ff298e", "#ff527d", "#ff735a", "#ff9341", "#ffbd29"];
            this.createGraphic();
            this.complete;

        }

        private tooltipClose(){
            console.log("tooltipClose");
            var self= this;
            $("body").on("click", function(){
                TweenLite.to(self._thaTooltip, .1, {autoAlpha: '0', "display": "none"});
                //TweenLite.set(this.getPanel(), {top: '-100%'});
            })
        }

        public createGraphic(){
            console.log("createGraphic");
            var self= this;
            this.tooltipClose();
            //$(window).on("resize", function(){
            //    self.setResize();
            //});

            this.checkDevice();
        }

        private checkDevice():void{
            console.log("checkDevice");

            if(ClientInfo.IS_MOBILE){
                console.log("Mobile Graph Version");
                this._version= "device";
                this.deviceVersion();
            } else {
                console.log("Desktop Graph Version");
                this._version= "desktop";
                this.desktopVersion();
            }
        }

        private deviceVersion(){
            console.log("deviceVersion");
            this.setArrayToShow();
            this.setMeasures();

            this.setStageNumbers();
            this.setPie();
            this.getAllValues();
            this.getMaxValue();
            this.setColor();
            this.setColorBlack();
            this.setArc();
            this.setInitialArc();
            //this.setActiveArc();
            this.setLinearActiveArc();
            this.setOutLineArc();
            this.setStage();
            this.insertLineCircles();

            this.setSolidPath(this._arc);
            this.setActivePath();
            this.setLinearPath();
            this.insertTitles(this._actualIndex);
            //this.insertInnerCircle();
            this.onSelectChange();


            this._tlEntrance.play();
        }

        private desktopVersion(){
            console.log("desktopVersion");
            this.setArrayToShow();
            this.setMeasures();

            this.setStageNumbers();
            this.setPie();
            this.getAllValues();
            this.getMaxValue();
            this.setColor();
            this.setColorBlack();

            this.setArc();
            this.setInitialArc();
            //this.setActiveArc();
            this.setLinearActiveArc();
            this.setOutLineArc();
            this.setStage();
            this.insertLineCircles();

            this.setSolidPath(this._initialArc);
            this.setActivePath();
            this.setLinearPath();
            this.insertTitles(this._actualIndex);
            //this.insertInnerCircle();
            this.onSelectChange();

            _.delay(()=>{
                this.entranceAnim();
            },200);

        }

        resizeGraphic(){
            console.log("resizeGraphic");
            this.setArrayToShow();
            this.setMeasures();

            this.setStageNumbers();
            this.getAllValues();
            this.getMaxValue();
            this.setArc();
            this.setInitialArc();
            //this.setActiveArc();
            this.setLinearActiveArc();
            this.setOutLineArc();
            this.setStage();
            this.insertLineCircles();

            this.setSolidPath(this._arc);
            this.setActivePath();
            this.setLinearPath();
            this.insertTitles(this._actualIndex);
            //this.insertInnerCircle();
        }

        private reCreateGraphic(){
            console.log("reCreateGraphic");
            this.setArrayToShow();
            this.setMeasures();

            this.setStageNumbers();
            this.getAllValues();
            this.getMaxValue();
            this.setArc();
            this.setInitialArc();
            //this.setActiveArc();
            this.setLinearActiveArc();
            this.setOutLineArc();
            this.setStage();
            this.insertLineCircles();

            this.setSolidPath(this._initialArc);
            this.setActivePath();
            this.setLinearPath();
            this.insertTitles(this._actualIndex);
            //this.insertInnerCircle();

            this.pathTrans(this._arc, 0, 30);
            this._tlChange.reverse();

        }

        private entranceAnim(){
            console.log("entranceAnim");
            this._tlEntrance.play();
            this.pathTrans(this._arc, 250, 10);
        }

        private setResize(){
            console.log("setResize");
            var windowW:number= window.innerWidth;

            if(windowW <= 480 ){
                this.setWidth(280, 100);
            }if(windowW > 480 && windowW <= 745){
                this.setWidth(410, 130);
            }if(windowW > 745 && windowW <= 1020){
                this.setWidth(400,120);
            }if(windowW > 1020){
                this.setWidth(400, 120);
            }
        }

        private setWidth(width:number, innerRad:number){
            console.log("setWidth");
            if (width != this._cGWidht) {
                this._cGWidht = width;
                this._innerRadius = innerRad;
                this.reSizeGraph();
                if(this._cGWidht== 280){
                    var event = new InfographicProfileEvent(InfographicProfileEvent.WIDHTCHANGE);
                    event.data= true;
                    this.dispatchEvent(event);
                }else{
                    var event = new InfographicProfileEvent(InfographicProfileEvent.WIDHTCHANGE);
                    event.data= false;
                    this.dispatchEvent(event);
                }
            }
        }

        private setMeasures(){
            console.log("setMeasures");

            this._cGWidht= 400;
            this._cGHeight= 400;
            this._innerRadius = 120;

            //var windowW:number= window.innerWidth;
            //
            //if(windowW <= 480 ){
            //    this._cGWidht= 280;
            //    this._cGHeight= 280;
            //    this._innerRadius = 100;
            //}if(windowW > 480 && windowW <= 745){
            //    this._cGWidht= 410;
            //    this._cGHeight= 410;
            //    this._innerRadius = 130;
            //}if(windowW > 745 && windowW <= 1020){
            //    this._cGWidht= 400;
            //    this._cGHeight= 400;
            //    this._innerRadius = 120;
            //}if(windowW > 1020){
            //    this._cGWidht= 400;
            //    this._cGHeight= 400;
            //    this._innerRadius = 120;
            //}
        }

        private setStageNumbers(){
            console.log("setStageNumbers");

            this._radius = Math.min(this._cGHeight, this._cGWidht) / 2;
        }

        private setPie() {
            console.log("setPie");
            this._pie = d3.layout.pie()
                .sort(null)
                .value(function (d) {
                    return 1
                })
        }

        private getAllValues() {
            console.log("getAllValues");

            this._allValues = this._arrayToShow.map(function (d) {
                return d.value;
            });
        }

        private getMaxValue() {
            console.log("getMaxValue");
            this._maxValue = Math.max.apply(null, this._allValues);

            this._minValue= Math.min.apply(null, this._allValues);
        }

        private setArc() {
            console.log("setArc");
            var self = this;
            this._arc = d3.svg.arc()
                .innerRadius(self._innerRadius)
                .outerRadius(function (d) {
                    return (self._radius - self._innerRadius) *
                        (d.data.value / self._maxValue) +
                        self._innerRadius;
                });
        }

        private setInitialArc(){
            console.log("setInitialArc");
            var self = this;
            this._initialArc = d3.svg.arc()
                .innerRadius(self._innerRadius)
                .outerRadius(self._innerRadius);
        }


        private setActiveArc() {
            var self = this;
            var minus;
            if(this._cGWidht== 280){
                minus= 12;
            }else{
                minus= 20
            }
            //this._activeArc = d3.svg.arc()
            //    .innerRadius(self._innerRadius-minus)
            //    .outerRadius(self._innerRadius+2);
        }

        private setLinearActiveArc() {
            console.log("setLinearActiveArc");
            var self = this;

            var minus;
            if(this._cGWidht== 280){
                minus= 12;
            }else{
                minus= 20
            }
            this._linearActiveArc = d3.svg.arc()
                .innerRadius(self._innerRadius)
                .outerRadius(self._cGWidht/2);
        }

        private setColor(){
            console.log("setColor");
             this._color = d3.scale.linear()
                 .domain([this._minValue,this._maxValue])
                .range(["#ff884d", "#ff4d5b"]);
        }
        private setColorBlack(){
            console.log("setColorBlack");
            this._colorBlack = d3.scale.linear()
                .domain([this._minValue,this._maxValue])
                .range(["#c3c3c3", "#a3a3a3"]);
        }

        private setOutLineArc() {
            console.log("setOutLineArc");
            var self = this;
            this._outLineArc = d3.svg.arc()
                .innerRadius(self._innerRadius)
                .outerRadius(self._radius);
        }

        private setStage() {
            console.log("setStage");
            this._stage = d3.select('#' + this.containerName).append('svg')
                .attr('class', "circle-graph")
                .attr('width', this._cGWidht)
                .attr('height', this._cGHeight)
                .append('g')
                .attr("transform", "translate(" + this._cGWidht / 2 + "," + this._cGHeight / 2 + ")");
        }

        private setArrayToShow(){
            console.log("setArrayToShow");
            this._arrayToShow= [];
            if(this._categoryCounter== -1){
                for(var category in this.data["categories"]){
                    var tempArray= this.data["categories"][category]["data"];
                    this._arrayToShow= this._arrayToShow.concat(tempArray);
                }
            }else{
                this._arrayToShow= this.data["categories"][this._categoryCounter]["data"];
            };
        }

        private insertInnerCircle(){
            var minus;
            if(this._cGWidht== 280){
                minus= 12;
            }else{
                minus= 20
            }
            this._stage.append("circle")
                .attr("fill", "none")
                .attr("stroke", "#d0cfcf")
                .attr("stroke-width", 1)
                .attr("r", this._innerRadius-minus);
        }

        private insertLineCircles(){
            console.log("insertLineCircles");

            for(var i= this._innerRadius; i< (this._cGWidht/2)-10; i+= ((this._cGWidht-170)/2)/8){
                this._stage.append("circle")
                    .attr("fill", "none")
                    .attr("stroke", "#d0cfcf")
                    .attr("stroke-width", 1)
                    .attr("r", i);
            }
        }

        private setLinearPath(){
            console.log("setLinearPath");
            var self= this;

            var activePaths= this._stage.append("g")
                .attr("class", "circle-linear-path-container")

            activePaths.selectAll(".pie-linear-arc")
                .data(this._pie(this._arrayToShow))
                .enter().append('path')
                .attr("fill", "none")
                .attr("class", "pie-linear-arc")
                .attr("stroke", "#d0cfcf")
                .attr("stroke-width", 1)
                .attr('d', this._linearActiveArc)
        }

        private setActivePath(){
            console.log("setActivePath");
            var self= this;

            var activePaths= this._stage.append("g")
                .attr("class", "circle-active-container")

            //activePaths.selectAll(".pie-active-arc")
            //    .data(this._pie(this._arrayToShow))
            //    .enter().append('path')
            //    .attr("class", "pie-active-arc")
            //    .attr("fill", "#e6e6e6")
            //    .attr('d', this._activeArc)

            activePaths.select(".pie-active-arc:nth-child("+ (this._actualIndex+1) +")")
                .attr('fill', function(d) { return self._color(self._theSelected.data.value); })

            //activePaths.selectAll("circle-pie-text")
            //    .data(this._pie(this._arrayToShow))
            //    .enter().append('text')
            //    .style("text-anchor", "middle")
            //    .attr("transform", function(d) { return "translate(" + self._activeArc.centroid(d) + ")"; })
            //    .attr("dy", ".35em")
            //    .attr("fill", "#d0cfcf")
            //    .attr("font-size", "9px")
            //    .text(function(d, i){
            //        return i+1
            //    })

        }

        private setSolidPath(arc) {
            console.log("setSolidPath");

            var self= this;

            this._path = this._stage.append("g")
                .attr("class", "circle-solid-container")
                .selectAll('.solid-arc')
                .data(this._pie(this._arrayToShow))
                .enter().append('path')
                .each(function(d, i){
                    if(i== self._actualIndex){
                        self._theSelected= d;
                    }
                })
                .attr('fill', function(d) { self._currentColors.push(self._color(d.data.value)); return self._colorBlack(d.data.value); })
                .attr('class', function (d, i) {
                    return "solid-arc solid-active" + i
                })
                .attr('d', arc)
                .on('click', function (d, i) {
                    //self.onclick(d, i);
                    var event = new InfographicProfileEvent(InfographicProfileEvent.CLICK);
                    self._theSelected= d;
                    self._actualIndex= i;
                    self.insertTitles(self._actualIndex);
                    self.changeActive(self._actualIndex, self._theSelected);
                    var theobject= [d, i]
                    event.data = theobject;
                    self.dispatchEvent(event);
                })
                .on('mouseover', function (d, i) {
                            //self.onclick(d, i);

                    if(self._version== "desktop"){
                        var event = new InfographicProfileEvent(InfographicProfileEvent.MOUSEOVER);

                        self.insertTitles(i);
                        self.changeActive(i, d);
                        var theobject= [d, i]
                        event.data = theobject;
                        self.dispatchEvent(event);
                    }else{
                        //return null;
                        var event = new InfographicProfileEvent(InfographicProfileEvent.CLICK);
                        self._theSelected= d;
                        self._actualIndex= i;
                        self.insertTitles(self._actualIndex);
                        self.changeActive(self._actualIndex, self._theSelected);
                        var theobject= [d, i]
                        event.data = theobject;
                        self.dispatchEvent(event);
                    }

                })
                .on('mouseout', function (d, i) {
                            //self.onclick(d, i);
                    if(self._version){

                        var event = new InfographicProfileEvent(InfographicProfileEvent.MOUSEOUT);
                        self.insertTitles(self._actualIndex);
                        self.changeActive(self._actualIndex, self._theSelected);
                        var theobject= [d, i]
                        event.data = theobject;
                        self.dispatchEvent(event, "lolololool");
                    }else{
                        return null;
                    }
                })

            d3.select(".circle-solid-container .solid-arc:nth-child("+ (this._actualIndex+1) +")")
                .attr('fill', function(d) { return self._color(self._theSelected.data.value); })


        }

        private pathTrans(arc, delay, distance){
            console.log("pathTrans");
            this._path.transition()
                .attr("d", arc)
                .delay(function (d, i) {
                    return delay + i * distance
                })
        }



        private complete=  _.delay(()=>{
            console.log("complete");
            var event= new InfographicProfileEvent(InfographicProfileEvent.ONCOMPLETE);
            event.data= [this. _arrayToShow, this._currentColors];
            this.dispatchEvent(event);
        },100);

        private changeActive(index, object){
            console.log("changeActive");

            var self= this;
            //$(".solid-arc:nth-child("+ index +")")

            if(this._version== "desktop"){
                d3.selectAll(".solid-arc")
                    .transition()
                    .duration(300)
                    .delay(100+10*index)
                    .attr("fill", function(d) {return self._colorBlack(d.data.value);});

                d3.select(".solid-arc:nth-child("+ (this._actualIndex+1) +")")
                    .transition()
                    .duration(1)
                    .attr('fill', function(d) {return self._color(d.data.value); });

                d3.select(".solid-arc:nth-child("+ (index+1) +")")
                    .transition()
                    .duration(1)
                    .attr('fill', function(d) { return self._color(object.data.value); });
            }else{
                d3.selectAll(".solid-arc")
                    .attr("fill", function(d) {return self._colorBlack(d.data.value);});

                d3.select(".solid-arc:nth-child("+ (this._actualIndex+1) +")")
                    .attr('fill', function(d) {return self._color(d.data.value); });

                d3.select(".solid-arc:nth-child("+ (index+1) +")")
                    .attr('fill', function(d) { return self._color(object.data.value); });
            }

        }


        private formatFloat(num: number) {
            var sFormat = num.toFixed(5);
            var dotIndex = sFormat.toString().indexOf('.');
            return sFormat.substr(0, dotIndex + 2);
        }

        private insertTitles(index){
            console.log("insertTitles");
            var showName= this._arrayToShow[index]["itemName"];
            var shortName;
            if(showName.length > 33){
                shortName= showName.substring(0, 33);
                shortName= shortName + "...";
            }else{
                shortName= this._arrayToShow[index]["itemName"];
            }
            $(".prof-description-titles .prof-show-title").text(shortName);
            _.delay(()=>{
                $(".profile-pie-svg-container .prof-show-title").text(this._arrayToShow[index]["itemName"]);
            },500);
            $(".prof-show-channel").text(this._arrayToShow[index]["channel"]);
            $(".prof-show-tv").text(this._arrayToShow[index]["emision"]);

            var original = this._arrayToShow[index]["value"];

            $(".prof-show-percent").text(this.formatFloat(original));
            $(".prof-show-percent").css("color", this._currentColors[index]);

            //var res = str.substring(0, 24);
        }

        private onSelectChange(){
            console.log("onSelectChange");

            var self= this;
            $(".prof-category-controll").on("change", function(){

                var val = $( ".prof-category-controll option:selected" ).val() || -1;
                self._currentColors= [];
                self._categoryCounter= val;
                self._actualIndex= 0;
                if(self._version== "desktop"){
                    self.redoGraph();
                }else{
                    self.exitGraph();
                    self.resizeGraphic();
                }

                _.delay(()=>{
                    var event = new InfographicProfileEvent(InfographicProfileEvent.CHANGE);
                    event.data = [self._arrayToShow, self._currentColors];
                    self.dispatchEvent(event);
                },750);
            })
        }

        private resetSelectBox(){
            console.log("resetSelectBox");
            $('.prof-category-controll').prop('selectedIndex',0);
        }
        private redoGraph(){
            console.log("redoGraph");
            this._tlChange.play();
            this.pathTrans(this._initialArc, 0, 30);
            _.delay(()=>{
                this.exitGraph();
                this.reCreateGraphic();
            },500);
        }



        private reSizeGraph(){
            console.log("reSizeGraph");
            this.exitGraph();
            this.resizeGraphic();
        }

        private exitGraph(){
            console.log("exitGraph");
            d3.select(".circle-graph").remove();
        }

        public deleteGraph(){
            console.log("deleteGraph");
            this.exitGraph();
            this.unbinds();
            this.resetSelectBox();
        }

        public reverseAnim(){
            console.log("reverseAnim");
            this._tlExit();
        }

        private unbinds(){
            console.log("unbinds");
            $("body").unbind("click");
            //$(window).unbind("resize");
            $(".prof-category-controll").unbind("change");
        }

        public hideElements(){
            console.log("hideElements");
            TweenLite.set([this._thaTitle, this._thaOptions, this._thaShow, this._thaGraphCont], {autoAlpha: 0})
        }

        public tooltipReset(){
            TweenLite.to(this._thaTooltip, .05, {autoAlpha :0})
        }

        private _tlExit(){
            TweenLite.to([this._thaShow,
                            this._thaGraphCont,
                            this._thaSelect,
                            this._thaOptions,
                            this._thaTitle,
                            this._thaFooter], 0.25, {autoAlpha:0} );
            TweenLite.to(this._thaTooltip, .05, {autoAlpha: 0, display: "block"} );
        }

        private get _tlEntrance (): TimelineLite {

            if(this.$tlEntrance == null){
                this.$tlEntrance = new TimelineLite({autoStart: false, ease:Power1.easeOut});

                this.$tlEntrance.to(this._thaShow, .25, {autoAlpha: 1} );
                this.$tlEntrance.to(this._thaGraphCont, .25, {autoAlpha: 1}, .15);
                this.$tlEntrance.to(this._thaSelect, .1, {autoAlpha: 1, delay:.2} );
                this.$tlEntrance.to(this._thaOptions, .1, {autoAlpha: 1, delay:.2},"-=.2" );
                this.$tlEntrance.to(this._thaTitle, .1, {autoAlpha: 1, delay:.2}, "-=.1");
                this.$tlEntrance.to(this._thaFooter, .15, {autoAlpha: 1, delay:.2},"-=.1" ).call(()=>{
                    TweenLite.to(this._thaTooltip, .05, {autoAlpha: .8, delay:".3" ,display: "block"} );
                })

            }
            return this.$tlEntrance;
        }

        private get _tlChange (): TimelineLite {

            if(this.$tlChange == null){
                this.$tlChange = new TimelineLite({autoStart: false, ease:Cubic.easeOut});
                this.$tlChange.fromTo(this._thaShow, .5,{autoAlpha: 1}, {autoAlpha: 0} );

            }
            return this.$tlChange;
        }


        private $tlChange: TimelineLite = null;
        private $tlEntrance: TimelineLite = null;

    }
}