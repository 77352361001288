/**
 * Created by Esteban on 10/22/14.
 */

/// <reference path="./AbstractDialogView.ts" />
/// <reference path="../events/ContactEvents.ts" />

module media.hrratings.views {

    import ViewManager   = media.hrratings.core.ViewManager;
    import ContactEvents = media.hrratings.events.ContactEvents;
    import RoutesEvents  = media.hrratings.events.RoutesEvents;

    export class ContactView extends AbstractDialogView{

        private _elPanel: HTMLElement;
        private _elSelectBox: HTMLElement;

        private _elContact: HTMLElement;
        private _elLoader: HTMLElement;
        private _elThx: HTMLElement;
        private _elError: HTMLElement;

        private _elNewsletter: HTMLElement;
        private _elNewsletterLoader: HTMLElement;
        private _elNewsletterThx: HTMLElement;
        private _elNewsletterError: HTMLElement;

        private _elBtBackContact: JQuery;
        private _elBtBackNewsletter: JQuery;
        private _elContactForm: HTMLElement;
        private _elNewsletterForm: HTMLElement;

        private _elBtTxt: JQuery;

        constructor(_target: HTMLElement) {
            super(_target);
            this._cacheElements();
            this._bind();
        }

        private _cacheElements () : void {
            this._elPanel               = $(this.target     ).find('#contact').get(0);
            this._elSelectBox           = $(this._elPanel   ).find('#select-box-form').get(0);
            this._elContact             = $(this._elPanel   ).find('#form-content').get(0);
            this._elLoader              = $(this._elPanel   ).find('#form-loader').get(0);
            this._elThx                 = $(this._elPanel   ).find('#form-thx').get(0);
            this._elError               = $(this._elPanel   ).find('#form-error').get(0);

            this._elNewsletter          = $(this._elPanel   ).find('#form-newsletter').get(0);
            this._elNewsletterLoader    = $(this._elPanel   ).find('#form-newsletter-loader').get(0);
            this._elNewsletterThx       = $(this._elPanel   ).find('#form-newsletter-thx').get(0);
            this._elNewsletterError     = $(this._elPanel   ).find('#form-newsletter-error').get(0);

            this._elBtBackContact       = $(this._elPanel   ).find('.bt-form-contact-back');
            this._elBtBackNewsletter    = $(this._elPanel   ).find('.bt-form-newsletter-back');
            this._elContactForm         = $(this._elPanel   ).find('#contact-form').get(0);
            this._elNewsletterForm      = $(this._elPanel   ).find('#newsletter-form').get(0);

            this._elBtTxt               = $('#contact-document-rector');
        }

        private _bind(): void {

            $(this._elSelectBox)["selectBox"]();
            this._elBtBackContact.on('click', () => { this._showContact(); });
            this._elBtBackNewsletter.on('click', () => { this._showNewsletter() ; });
            this._elBtTxt.on('click', () => { this._setMessageTo(); });
        }

        private _setMessageTo () : void {
            $("#form-message").val($.i18n.translate('tr-msn-1'));
            $("#bt-contact").trigger(":hover");
        }

        private _showElement (el: HTMLElement) : Q.Promise<void> {

            var d = Q.defer<void>();

            $(el).css({'display': 'block'});
            TweenLite.to(el, 0.25, {autoAlpha: 1, onComplete: function() { d.resolve(); }});

            return d.promise;
        }

        private _hideElements (elements: Array<any>) : Q.Promise<void> {

            var d = Q.defer<void>();
            TweenLite.to(elements, 0.25, {autoAlpha: 0, onComplete: function(){
                _.each(elements, function(el: HTMLElement){

                    $(el).css({'display': 'none'});
                });
                d.resolve();
            }});
            return d.promise;
        }

        private _hideElementsContact () : Q.Promise<void> {

            var d = Q.defer<void>();

            this._hideElements([
                this._elContact,
                this._elLoader,
                this._elThx,
                this._elError
            ]).then( () => { d.resolve(); });

            return d.promise;
        }

        private _hideElementsNewsletter () : Q.Promise<void> {

            var d = Q.defer<void>();

            this._hideElements([
                this._elNewsletter,
                this._elNewsletterLoader,
                this._elNewsletterThx,
                this._elNewsletterError
            ]).then( () => { d.resolve(); });

            return d.promise;
        }

        private _showLoaderContact () : void {

            this._hideElementsContact().then( () => { this._showElement(this._elLoader); });
        }

        private _showLoaderNewsletter () : void {

            this._hideElementsNewsletter().then( () => { this._showElement(this._elNewsletterLoader); });
        }

        private _showContact () : void {

            $("#form-name").val('');
            $("#form-email").val('');
            $("#form-business").val('');
            $("#form-message").val('');
            this._hideElementsContact().then( () => {
                this._showElement(this._elContact)
                    .then(function() { $("#form-name").focus(); });
            });
        }

        private _showNewsletter () : void {

            $("#form-email-newsletter").val('');
            this._hideElementsNewsletter().then( () => {
                this._showElement(this._elNewsletter)
                    .then(function() { $("#form-email-newsletter").focus(); });
            });
        }



        getPanel (): HTMLElement {

            return this._elPanel;
        }

        setupBindings():void {

            ko.applyBindings(this, this._elPanel);

            $('#form-email-newsletter, #form-email').attr('placeholder', $.i18n.translate('tr-placeholder-email'));
            $('#form-name').attr('placeholder', $.i18n.translate('tr-placeholder-name'));
            $('#form-business').attr('placeholder', $.i18n.translate('tr-placeholder-business'));

            $(this._elContactForm).validate({
                errorClass: 'field-error',
                rules: {
                    name: {
                        required: true,
                        minlength: 3
                    },
                    email: {
                        required: true,
                        email: true
                    },
                    message: {
                        required: true,
                        minlength: 10
                    }
                },
                messages: {
                    name: {
                        required: $.i18n.translate('tr-contact-val-name'),
                        minlength: $.i18n.translate('tr-contact-val-length-name')
                    },
                    email: $.i18n.translate('tr-contact-val-mail'),
                    message: {
                        required: $.i18n.translate('tr-contact-val-msg'),
                        minlength: $.i18n.translate('tr-contact-val-length-msg')
                    }
                }
            });

            $(this._elNewsletterForm).validate({
                errorClass: 'field-error',
                rules: {
                    email: {
                        required: true,
                        email: true
                    }
                },
                messages: {
                    email: $.i18n.translate('tr-contact-val-mail')
                }
            });
        }

        showThxContact () : void {

            this._hideElementsContact().then( () => { this._showElement(this._elThx); });
        }

        showThxNewsletter () : void {

            this._hideElementsNewsletter().then( () => { this._showElement(this._elNewsletterThx); });
        }

        showErrorContact () : void {

            this._hideElementsContact().then( () => { this._showElement(this._elError); });
        }

        showErrorNewsletter () : void {

            this._hideElementsNewsletter().then( () => { this._showElement(this._elNewsletterError); });
        }

        suscribeNewsletter = () : boolean => {

            if($(this._elNewsletterForm).valid()){

                this._showLoaderNewsletter();

                var event = new ContactEvents(ContactEvents.CONTACT_FORM_NEWSLETTER);
                event.email = $("#form-email-newsletter").val();

                this.dispatchEvent(event);
            }
            return false;
        }

        sendMail = (): boolean => {

            if($(this._elContactForm).valid()){

                this._showLoaderContact();

                var event = new ContactEvents(ContactEvents.CONTACT_FORM_SEND);
                event.name = $("#form-name").val();
                event.email = $("#form-email").val();
                event.company = $("#form-business").val();
                event.subject = $("#form-issue option:selected").text();
                event.message = $("#form-message").val();

                this.dispatchEvent(event);
            }

            return false;
        }

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>):void {

            TweenLite.set(this.target, {css:{display:'block'}});
            TweenLite.set(this._elPanel, {top: '0px', autoAlpha: 0});
            TweenLite.fromTo($(this._elPanel).find('.w-dialog-wrapper'), 0.45, {autoAlpha: 0}, {autoAlpha: 1});
            TweenLite.to(this.getPanel(), 0.25,{autoAlpha: 1, ease:Cubic.easeOut, onComplete: () => {
                d.resolve();
            }});

            $(this._elPanel).scrollTop(0);
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {
            $("#form-message").val("");
            TweenLite.to($(this._elPanel).find('.w-dialog-wrapper'), 0.25,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                TweenLite.set(this._elPanel, {autoAlpha: 0, top: '-100%'});
                TweenLite.set(this.target, {css:{display:'none'}});
                d.resolve();
            }});
        }



        private get _tlDialog (): TimelineLite {

            if(this.$tlDialog == null){
                this.$tlDialog = new TimelineLite({autoStart: false, ease: Cubic.easeOut});
            }
            return this.$tlDialog;
        }



        private $tlDialog: TimelineLite = null;
    }
}
