/**
 * Created by Ivan on 12/4/14.
 */

/// <reference path="./../events/FilterEvents.ts" />
/// <reference path="./AbstractDialogView.ts" />
/// <reference path="../filters/base/Filter.ts" />

interface IYearEntry {
    label: string;
}

interface IWeekEntry {
    year: number;
    week: boolean;
    label: string;
}

module media.hrratings.views {

    import Filter = media.hrratings.filters.Filter;

    export class AbstractFilterView extends AbstractDialogView {
        protected filter: Filter;
        protected types;

        public _elPanel: HTMLElement;

        public _elDescription: JQuery;
        public _elDropboxYear: HTMLSelectElement;
        public _elDropboxWeek: HTMLSelectElement;

        public koItemTitle: KnockoutObservable<string>;
        public koItemDescription: KnockoutObservable<string>;
        public koItemTimeSpan: KnockoutObservable<string>;
        public koItemUrl: KnockoutObservable<string>;
        public koItemDownloadFile: KnockoutObservable<string>;
        public koDisableDownload: KnockoutObservable<boolean>;
        public koWeeks: KnockoutObservableArray<IWeekEntry>;
        public koYears: KnockoutObservableArray<IYearEntry>;
        public onViewShowCallback: Function;
        public onViewHideCallback: Function;

        constructor(_target: HTMLElement) {
            super(_target);
            this.koItemTitle = ko.observable('');
            this.koItemTimeSpan = ko.observable('');
            this.koItemUrl = ko.observable('#');
            this.koItemDownloadFile = ko.observable('');
            this.koDisableDownload = ko.observable(false);
            this.koWeeks = ko.observableArray([]);
            this.koYears = ko.observableArray([]);
            this.types = [];
        }

        public getPanel (): HTMLElement {
            return this._elPanel;
        }

        public initializeFilter() {
            this.filter.onFilterReady(this.onFilterReady);
        }

        public onFilterReady = () => {
            console.warn('Override method onFilter ready!');
        };


        /**
         * @protected
         * @param d
         */
        open_hook (d: Q.Deferred<void>): void {
            if (this.onViewShowCallback) {
                this.onViewShowCallback();
            }

            this.showView().then(()=> {
                if (this.filter.isReady) {
                    this.filter.setTypes(this.types);
                }
               d.resolve();
            });
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d: Q.Deferred<void>): void {
            if (this.onViewHideCallback) {
                this.onViewHideCallback();
            }
            this.hideView().then(()=> {
                d.resolve();
            });
        }

        //@virtual meant to be override
        public _tlDialog (): TimelineLite {
            return new TimelineLite();
        }
    }
}