/**
 * Created by Esteban on 10/21/14.
 */

/// <reference path="./AbstractView.ts" />

module media.hrratings.views {

    export class AbstractAboutTab extends AbstractView {

        private _elBlock1: JQuery;
        private _elBlock2: JQuery;
        private _elBlock3: JQuery;
        private _elBlock4: JQuery;

        constructor(_target: HTMLElement){
            super(_target);

            this._bind();
        }

        private _bind (): void {
            this._elBlock1 = $(this.target).find('.first');
            this._elBlock2 = $(this.target).find('.second');
            this._elBlock3 = $(this.target).find('.third');
            this._elBlock4 = $(this.target).find('.fourth');
        }

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>):void {
            this._tlDialogClose.stop();
            this._tlDialogOpen.play(0);
            TweenLite.set(this.target,{css:{display:'block'}});
            setTimeout( () => { d.resolve(); }, 900);
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {
            this._tlDialogOpen.stop();
            this._tlDialogClose.play(0);
            setTimeout( () => {
                TweenLite.set(this.target,{css:{display:'none'}});
                $(this.target).scrollTop(0);
                d.resolve();
            }, 250);
        }

        private get _tlDialogOpen (): TimelineLite {
            if(this.$tlDialogOpen == null){
                this.$tlDialogOpen = new TimelineLite({autoStart: false, ease: Cubic.easeInOut});
                this.$tlDialogOpen.to(this._elBlock1, 0.25, {autoAlpha: 1}                  );
                this.$tlDialogOpen.to(this._elBlock2, 0.25, {autoAlpha: 0.8}                );
                this.$tlDialogOpen.to(this._elBlock3, 0.25, {autoAlpha: 1, marginTop: '0px'});
                this.$tlDialogOpen.to(this._elBlock4, 0.15, {autoAlpha: 1}                  );
            }
            return this.$tlDialogOpen;
        }

        private get _tlDialogClose (): TimelineLite {

            if(this.$tlDialogClose == null){
                this.$tlDialogClose = new TimelineLite({autoStart: false, ease: Cubic.easeInOut});
                this.$tlDialogClose.to([this._elBlock1, this._elBlock2, this._elBlock3, this._elBlock4], 0.25, {autoAlpha: 0, onComplete: () => {

                    this._elBlock3.css({"marginTop": "50px"});
                }});
            }
            return this.$tlDialogClose;
        }

        private $tlDialogOpen: TimelineLite = null;
        private $tlDialogClose: TimelineLite = null;
    }
}