/**
 * Created by raul on 10/16/14.
 */

module media.hrratings.events {
    export class RoutesEvents {
        static SERVICES_ROUTE               = "RoutesEvents.services_route";
        static METHODOLOGY_ROUTE            = "RoutesEvents.methodology_route";

        static ABOUT_ROUTE                  = "RoutesEvents.about_route";
        static CONTACT_ROUTE                = "RoutesEvents.contact_route";
        static SEARCH_ROUTE                 = "RoutesEvents.search_route";

        static LEGALS_WEB_ROUTE             = "RoutesEvents.legals_web_route";
        static LEGALS_ES_ROUTE              = "RoutesEvents.legals_es_route";
        static LEGALS_PANEL_ROUTE           = "RoutesEvents.legals_panel_route";

        static INFOGRAPHIC_PROFILE_ROUTE    = "RoutesEvents.infographic_profile_route";
        static INFOGRAPHIC_RANKING_ROUTE    = "RoutesEvents.infographic_ranking_route";
        static INFOGRAPHIC_VS_ROUTE         = "RoutesEvents.infographic_vs_route";

        static HOME_ROUTE                   = "RoutesEvents.home_route";
        static OUR_INFORMATION_ROUTE        = "RoutesEvents.our_information_route";
        static INFOGRAPHICS_ROUTE           = "RoutesEvents.infographics_route";
        static REPORTS_ROUTE                = "RoutesEvents.weekly_route";
        static PRESS_ROUTE                  = "RoutesEvents.press_route";
    }
}