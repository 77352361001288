/**
 * Created by Esteban on 10/24/14.
 */

module media.hrratings.events {

    export class HomeEvents extends createjs.Event {

        static ITEM_NEXT = "HomeEvents.item_next";
        static ITEM_PREV = "HomeEvents.item_prev";
        static SECTION_DATA = "HomeEvents.section_data";
        static FILTER_TYPE = "HomeEvents.filter_type";
        static FILTER_DATE = "HomeEvents.filter_date";

        public filter: {};
        constructor (type: string) {
            super(type,false,false);
        }
    }
}
