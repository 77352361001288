/**
 * Created by Ivan on 3/11/15.
 */

/// <reference path="../../services/HRServices.ts" />

module media.hrratings.filters {
    import HRService = media.hrratings.services.HRServices;

    export class Filter {
        protected APIService: HRService;
        protected children: Array<FilterItem>;
        protected enabledChildren: Array<FilterItem>;
        protected readyCallbacks: Array<Function>;
        protected itemsReady: boolean;
        protected types: Array<string>;

        constructor() {
            this.APIService = new HRService();
            this.readyCallbacks = [];
            this.itemsReady = false;
            this.children = [];
            this.types = [];
        }

        private onAPIItemsCompleted() {
            this.itemsReady = true;
            if (this.readyCallbacks.length > 0) {
                _.each(this.readyCallbacks, (cb: Function)=> {
                    cb();
                })
            }
        }

        protected updateAPIItems (updater: { (): JQueryXHR }) {
            if (this.itemsReady) {
                this.onAPIItemsCompleted();
            } else {
                console.log('updater:', updater);
                updater().done((data: any)=> {
                    console.log('API ITEMS:', data);
                    this.addFilterItems(data);
                    this.updateEnabledChildren();
                    this.onAPIItemsCompleted();
                });
            }
        }

        //@override
        protected addFilterItems(data: any) {
            _.each(data, (entry: any)=> {
                this.children.push(new FilterItem(entry));
            });
        }

        protected getLastPublicationParams(): {year: number; week: number} {
            var child = this.getLatestPublishedChild();

            return {
                year: child.date.year(),
                week: child.week
            };
        }

        protected getItemsBy(year, week): Array<FilterItem> {
            return _.filter(this.enabledChildren, (child: FilterItem)=> {
                return child.date.year() === year && child.week === week;
            });
        }

        private getItemsGroupedBy(groupType: string = ''): any {
            console.log('ItemsGroupByYear:', this.enabledChildren, groupType);
            return _.groupBy(this.enabledChildren, (child: FilterItem)=> {
                switch (groupType) {
                    case 'year':
                        return child.date.year();
                        break;
                    case 'month':
                        return child.date.month();
                        break;
                    case 'week':
                        return child.week;
                        break;
                    default:
                        return child.date.valueOf();
                }
            });
        }

        private getMonthNames(): Array<string> {
            return [
                $.i18n.translate('tr-jan'),
                $.i18n.translate('tr-feb'),
                $.i18n.translate('tr-mar'),
                $.i18n.translate('tr-apr'),
                $.i18n.translate('tr-may'),
                $.i18n.translate('tr-jun'),
                $.i18n.translate('tr-jul'),
                $.i18n.translate('tr-aug'),
                $.i18n.translate('tr-sep'),
                $.i18n.translate('tr-oct'),
                $.i18n.translate('tr-nov'),
                $.i18n.translate('tr-dec')
            ]
        }

        private updateEnabledChildren() {
            console.log('-> Children Total:', this.types, 'Load:', this.children.length);
            _.each(this.children, (item: FilterItem)=> {
                item.enabled = item.isTypeOf(this.types);
            });
            this.enabledChildren = _.where(this.children, { enabled: true });
            console.log('-> Children Enabled: ', this.enabledChildren.length);
        }

        // PUBLIC METHODS

        public getLatestPublishedChild(): FilterItem {
            var lastChild = _.max(this.children, (child: FilterItem) => {
                return child.date.valueOf();
            });
            return lastChild;
        }

        get isReady(): boolean {
            return this.itemsReady;
        }

        public getAvailableYears(): Array<number> {
            var yearsGroup = this.getItemsGroupedBy('year');
            console.log('groupYears:',yearsGroup)
            // flatten array of available years
            return _.map(yearsGroup, (item, key)=> {
                return key | 0;
            });
        }

        public getItemsTimeLine(year: number) {
            var monthNames = this.getMonthNames();
            var timeLineItems = [];
            var yearItems = this.getItemsGroupedBy('year');

            if (!yearItems[year]) console.warn('No items within year:', year, ' where found.');

            var weeksGroup = _.groupBy(yearItems[year], 'week');

            _.each(weeksGroup, (week: any, key: string)=> {
                if (week) {
                    var firstWeekChild = week[0];
                    var monthIndex = firstWeekChild.date.month();
                    timeLineItems.push({
                        label: monthNames[monthIndex] + ' - ' + $.i18n.translate('tr-date') + ' ' + key,
                        week: firstWeekChild.week,
                        year: firstWeekChild.date.year()
                    });
                }
            });
            return timeLineItems;
        }


        public setTypes(types: Array<string>) {
            this.types = types || [];
            this.updateEnabledChildren();
        }

        public getItemByDate(year, week) {
            var res = _.find(this.children, (item: FilterItem) => {
                return item.date.year() === year && item.week === week;
            });
            return res
        }

        public itemWithDateExists(year, week) {
            var res = this.getItemByDate(year, week);
            return res !== undefined;
        }

        public onFilterReady(callback: Function) {
            this.readyCallbacks.push(callback);
            if (this.itemsReady) {
                callback();
            }
        }

    }

}