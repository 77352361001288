/**
 * Created by raul on 10/15/14.
 */

/// <reference path="./IView.ts" />

module media.hrratings.views{

    export class AbstractView extends createjs.EventDispatcher implements IView {

        static inject:string[] = ["target"];

        private _isOpen: boolean = false;

        public target: HTMLElement;

        constructor(_target: HTMLElement) {
            super();
            this.target = _target;
        }

        get isOpen(): boolean {
            return this._isOpen;
        }

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>): void {
            d.resolve();
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>): void {
            d.resolve();
        }

        open(): Q.Promise<void> {
            var d = Q.defer<void>();
            this._isOpen = true;
            this.open_hook(d);
            return d.promise;
        }

        close(): Q.Promise<void> {
            var d = Q.defer<void>();
            this._isOpen = false;
            this.close_hook(d);
            return d.promise;
        }
    }
}
