/**
 * OpenInfographicsCommand
 * Created by Esteban on 11/20/14.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/InfographicsView.ts" />

module media.hrratings.commands {

    import ViewManager = core.ViewManager;
    import InfographicsView = views.InfographicsView;

    export class OpenInfographicsCommand {

        mainViewManager: ViewManager = null;

        execute () {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenInfographicsCommand.execute!");

            this.mainViewManager.openView(MainViews.Infographics);
        }
    }
}