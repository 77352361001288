/**
 * Created by had on 3/3/15.
 */
/**
 * Created by Esteban on 10/20/14.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/MethodologyView.ts" />

module media.hrratings.commands {

    import ViewManager = media.hrratings.core.ViewManager;
    import MethodologyView = media.hrratings.views.MethodologyView;

    export class OpenMethodologyCommand {

        mainViewManager: ViewManager = null; //injected

        execute (event) {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenMethodologyCommand.execute!");
            console.log("section: ", event.params.section);

            if(!this.mainViewManager.isViewOpen(MainViews.Methodology) ){
                this.mainViewManager.openView(MainViews.Methodology);
            }

            var methodologyView:MethodologyView = <MethodologyView>this.mainViewManager.getViewById(MainViews.Methodology);
            methodologyView.showTab(event.params.section, event.params.subsection);
        }
    }
}