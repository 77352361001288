/**
 * Created by Ivan on 5/21/15.
 */
/// <reference path="../base/FilterItem.ts" />

module media.hrratings.filters {
    export class WeeklyFilterItem extends FilterItem {
        constructor(jsonEntry: any) {
            super(jsonEntry);
        }

        protected getWeekFromLink(link: string): number {
            var match:any =  link.match(/Sem([0-9]+)/);
            return match && match[1] || -1;
        }
    }
}