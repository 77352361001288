/**
 * I18nStore.ts
 * Created by raul on 10/22/14.
 */
module media.hrratings.locale {
    export class I18nStore {

        private _i18n:I18nextStatic = $.i18n;

        init (lang:string, api:string) : JQueryDeferred<void> {
            console.log("---------------------------------", lang)

            var opts:I18nextOptions = <I18nextOptions>{
                lng: lang,
                ns: 'translation',
                debug: false,
                fallbackLng: false,
                resGetPath: api + 'diccionario/__lng__',
                //resGetPath: 'http://hrapi.grwpo.net/api/diccionario/__lng__',
                //resGetPath: 'data/translation.__lng__.json',
                dynamicLoad: true,
                detectLngFromHeaders: false
            };

            //useLocalStorage: true,
            //resGetPath: 'resources.json?lng=__lng__&ns=__ns__',
            //return this._i18n.init($.extend(opts, { resStore: this._store }));
            return this._i18n.init(opts);
        }

        t(key: string, options?: any):string {
            return this._i18n.t(key, options);
        }

        translate(key: string, options?: any):string {
            return this.t(key, options);
        }
    }
}