/// <reference path="./AbstractDialogView.ts" />

module media.hrratings.views {

    import ViewManager = media.hrratings.core.ViewManager;

    export class LegalsPanelView extends AbstractDialogView{

        private _elPanel: HTMLElement;

        constructor(_target:HTMLElement) {

            super(_target);

            this.setup();
        }

        private setup(): void {

            this.cacheElements();
        }

        getPanel (): HTMLElement {
            return this._elPanel;
        }

        cacheElements (): void {
            this._elPanel = $(this.target).find('#privacy-panel').get(0);
        }

        setupBindings(): void {

            ko.applyBindings(this, this._elPanel);
        }
    }
}