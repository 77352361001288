/**
 * Created by Esteban on 10/27/14.
 */

/// <reference path="./AbstractDialogView.ts" />
/// <reference path="../events/ArticleEvent.ts" />
/// <reference path="../vo/ArticleVO.ts" />

module media.hrratings.views {

    import ViewManager = core.ViewManager;
    import ArticleEvent = events.ArticleEvent;
    import ArticleVO = vo.ArticleVO;

    export class ArticleView extends AbstractDialogView{

        private _elPanel: HTMLElement;
        private _elContainer: HTMLElement;
        private _elTitle: HTMLElement;
        private _elSubtitle: HTMLElement;
        private _elDescription: HTMLElement;
        private _elBtDownload: HTMLElement;

        private _article: {title: any; subtitle: any; content: any; articleName: any; articleURL: any};

        constructor(_target:HTMLElement) {

            super(_target);

            this.setup();
        }

        private setup(): void {
            this._article = {
                title: ko.observable(''),
                content: ko.observable(''),
                subtitle: ko.observable(''),
                articleName: ko.observable(''),
                articleURL: ko.observable('')
            };

            this.cacheElements();
            this.bind();
        }

        getPanel (): HTMLElement {
            return this._elPanel;
        }

        cacheElements (): void {
            this._elPanel = $(this.target).find('#article').get(0);
            this._elContainer = $(this.target).find('#article-container').get(0);

            this._elTitle = $(this._elPanel).find('header h1').get(0);
            this._elSubtitle = $(this._elPanel).find('header h2').get(0);
            this._elDescription = $(this._elPanel).find('.content p').get(0);
            this._elBtDownload = $(this._elPanel).find('.content .download').get(0);
        }

        bind (): void {
            ko.applyBindings(this, this._elContainer);
        }

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>):void {

            TweenLite.set(this.target, {css:{display:'block'}});
            TweenLite.set(this.getPanel(), {top: '0px', autoAlpha: 0});
            TweenLite.to(this.getPanel(), 0.25,{autoAlpha: 1, ease:Cubic.easeOut, onComplete: () => {
                this._tlDialog.play();
                d.resolve();
            }});
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {

            TweenLite.to(this.getPanel(), 0.25,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                TweenLite.set(this.getPanel(), {top: '-100%'});
                TweenLite.set(this.target, {css:{display:'none'}});
                this._tlDialog.reverse();
                d.resolve();
            }});
        }

        /**
         *
         * @param data
         */
        loadResults (data: ArticleVO): void {

        }

        loadError(): void{
            console.log('No data found!');
        }

        get article (): any{
            return this._article;
        }

        /**
         *
         * @returns {TimelineLite}
         * @private
         */
        private get _tlDialog (): TimelineLite {
            if(this.$tlDialog == null){
                this.$tlDialog = new TimelineLite({autoStart: false, ease: Cubic.easeOut});
            }
            return this.$tlDialog;
        }

        private $tlDialog: TimelineLite = null;
    }
}