/**
 * Created by Esteban on 10/21/14.
 */

/// <reference path="./AbstractView.ts" />

module media.hrratings.views {

    export class AbstractDialogView extends AbstractView {

        constructor(_target:HTMLElement){
            super(_target);
        }

        getPanel (): HTMLElement {
            return document.createElement('none');
        }

        showView(): Q.Promise<void> {

            var d = Q.defer<void>();
            TweenLite.set(this.target, {css:{display:'block'}});
            TweenLite.set(this.getPanel(), {top: '0px', autoAlpha: 0});
            TweenLite.to(this.getPanel(), 0.25,{autoAlpha: 1, ease:Cubic.easeOut, onComplete: () => {
                d.resolve();
            }});
            return d.promise;
        }

        hideView(): Q.Promise<void> {
            var d = Q.defer<void>();
            TweenLite.to(this.getPanel(), 0.25,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                TweenLite.set(this.getPanel(), {top: '-100%'});
                TweenLite.set(this.target, {css:{display:'none'}});
                d.resolve();
            }});
            return d.promise;
        }

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>):void {
            this.showView().then(()=> {
                d.resolve();
            });
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {
            this.hideView().then(()=> {
                d.resolve();
            });
        }
    }
}