module media.hrratings.infographicProfile{

    export interface IGraphic {
        createGraphic():void;
    }

    export class PieGraph extends createjs.EventDispatcher implements IGraphic{

        public data:any;
        public _audienceCounter:any;
        public _actualIndex:number;
        public _categoryCounter:number;
        public graphColor:Array<any>;
        public windowSmall:boolean;

        private containerName:string;

        private _pGWidht: number;
        private _pGHeight: number;
        private _pieRadius: number;
        private _stage;
        private _arc;
        private _pie;
        private _current= [];
        private _color;
        private _indicatorStage1;
        private _indicatorStage2;

        private _version;

        private _theColors: Array<any>;



        constructor(public thedata:any, public thecontainerName:string){
            super();

            this.data= thedata;
            this.containerName= thecontainerName;
            this._audienceCounter= 0;
            this._actualIndex= 0;
            this._categoryCounter= 0;

            this._theColors= ["#ff298e", "#ff735a", "#ff9341", "#ffbd29"];
            //this.createGraphic();
        }

        public createGraphic(){
            console.log("createGraphic, pie");
            var self= this;
            if(window.innerWidth<= 480){
                self.windowSmall= true;
            }else{
                self.windowSmall= false;
            }

            this.doGraphic();
        }

        private checkDevice(){
            console.log("checkDevice");
            try {
                document.createEvent("TouchEvent");
                this._version= "device";
                return true;
            } catch (e) {
                this._version= "desktop";
                return false;
            }
        }

        public doGraphic(){
            console.log("doGraphic");
            this.checkDevice();
            this.setColor(this._actualIndex);
            this.setStage();
            this.setIndicatorStage();
            this.setArc();
            this.setPie();
            //this.setPieData();
            this.insertArcs(this._actualIndex);
            //this.insertIndexNumber();
            this.insertCircles();
        }

        private setColor(index){
            console.log("setColor");
            var thaColorbrit= chroma(this.graphColor[index]).brighten().hex();
            var thaColorsat= chroma(this.graphColor[index]).saturate().hex();
            var thaColor= this.graphColor[index];
            var thaColordes= chroma(this.graphColor[index]).desaturate().hex();
            var thaColordark= chroma(this.graphColor[index]).darken().hex();
            var thaColordarker= chroma(thaColordark).darken().hex();

            this._color= [];
            this._color= [thaColorbrit, thaColor, thaColorsat, thaColordes, thaColordark, thaColordarker ];
        }

        private setStage():void {
            console.log("setStage");
            this._pGWidht= 110;
            this._pGHeight= 110;
            var self = this;
            this._pieRadius = Math.min(self._pGWidht, self._pGHeight) / 2;
            this._stage = d3.select('#'+ self.containerName).append("svg")
                .attr("width", self._pGWidht)
                .attr("height", self._pGHeight)
                .attr("class", "pie-chart")
                .append("g")
                .attr("transform", "translate("+self._pGWidht/2+","+self._pGHeight/2+")");
        }

        private setIndicatorStage(){
            console.log("setIndicatorStage");
            this._indicatorStage1= d3.selectAll(".prof-circles-indicator1").append("svg")
                .attr("width", 112)
                .attr("height", 78)
            this._indicatorStage2= d3.selectAll(".prof-circles-indicator2").append("svg")
                .attr("width", 112)
                .attr("height", 78)
        }

        private setArc():void {
            console.log("setArc");
            var self= this;
            this._arc = d3.svg.arc()
                .outerRadius(self._pieRadius)
                .innerRadius(18);
        }

        private setPie():void {
            console.log("setPie");
            this._pie = d3.layout.pie().sort(null).value(function(d) { return d; })
        }

        private insertArcs(index):void {
            console.log("insertArcs");
            var self = this;
            this._stage.selectAll('path')
                .data(self._pie(self.data[index]["audience"][self._audienceCounter]))
                .enter()
                .append('path')
                .attr('d', self._arc)
                .attr('stroke', "#e6e6e6")
                .attr("stroke-width", 1)
                .each(function(d:any) { self._current.push(d); })
                .attr('fill', function(d, i){return self._color[i]})

        }

        public redoCircles(){
            console.log("redoCircles");
            this.deleteCircles();
            this.insertCircles();
        }

        private deleteCircles(){
            console.log("deleteCircles");
            d3.selectAll(".pie-circles-indicator-group").remove();
        }

        private insertCircles(){
            console.log("insertCircles");
            if(this.windowSmall== true){
                this._indicatorStage1.transition()
                    .attr("height", 175);
                this._indicatorStage2.transition()
                    .attr("height", 175);
            }else{
                this._indicatorStage1.transition()
                    .attr("height", 78);
                this._indicatorStage2.transition()
                    .attr("height", 78);
            }


            var self= this;
            for(var i= 1; i<= 2; i++){
                var stage;
                var thai= i;
                if(i== 1){
                    stage= this._indicatorStage1;
                }if(i== 2){
                    stage= this._indicatorStage2;
                }
                stage.append("g")
                    .attr("class", "pie-circles-indicator-group")
                    .attr("transform", "translate(7,7)")
                    .selectAll("circle")
                    .data(self._pie(self.data[self._actualIndex]["audience"][self._audienceCounter]))
                    .enter()
                    .append("circle")
                    .attr("cx", function(d, i){
                        if(self.windowSmall == true){
                            return 0
                        }else{
                            if(i%2 == 0){
                                return 0
                            }else{
                                return (i%2)*70
                            }
                        }
                    })
                    .attr("cy", function(d, i){
                        if(self.windowSmall == true){
                            return i*32;
                        }else{

                            if(i<=1){
                                return 0
                            }if(i<=3){
                                return 26
                            }else{
                                return 52
                            }
                        }

                    })
                    .attr("r", "6")
                    .attr('fill', function(d, i){return self._color[i]});
            }

        }

        private insertIndexNumber(){
            this._stage.append("text")
                .text(this._actualIndex+1)
                .attr("text-anchor", "middle")
                .attr("class", "prof-index-act")
                .attr("y", 5)
                .attr("fill", this.graphColor[this._actualIndex]);
        }

        private indexNumberChange(index){
            if(this._version== "desktop"){
                this._stage.select(".prof-index-act").transition()
                    .text(index+1)
                    .attr("fill", this.graphColor[index]);
            }else{
                this._stage.select(".prof-index-act")
                    .text(index+1)
                    .attr("fill", this.graphColor[index]);
            }
        }

       public updateGraphic(index):void {
           console.log("updateGraphic");
           var self= this;
           this.setColor(index);

           if(this._version== "desktop"){

           this._stage.selectAll("path")
               .data(this._pie(this.data[index]["audience"][this._audienceCounter]))
               .transition()
               .attr("fill" ,function(d, i){
                   return self._color[i]
               })
               .duration(500)
               .attrTween("d", this._arcTween.bind(this));


           this._indicatorStage1.selectAll(".pie-circles-indicator-group")
               .selectAll("circle")
               .data(this._pie(this.data[index]["audience"][this._audienceCounter]))
               .transition()
               .attr("fill" ,function(d, i){
                   return self._color[i]
               })
               .duration(500)

           this._indicatorStage2.selectAll(".pie-circles-indicator-group")
               .selectAll("circle")
               .data(this._pie(this.data[index]["audience"][this._audienceCounter]))
               .transition()
               .attr("fill" ,function(d, i){
                   return self._color[i]
               })
               .duration(500)

           }else{
               //this._stage.selectAll("path")
               //    .data(this._pie(this.data[index]["audience"][this._audienceCounter]))
               //    .attr("fill" ,function(d){
               //        return self._color(d["data"])
               //    })

               //d3.select(".pie-chart").remove();
               d3.select(".pie-chart g").selectAll("*").remove();
               this.insertArcs(index);

               //this.insertIndexNumber();



               //this._stage.selectAll(".prof-nom")
               //    .data(this._pie(this.data[index]["audience"][this._audienceCounter]))
               //    .attr("fill", "#333333")
               //    .attr("style", function(d){
               //        if(d["value"] != 0){
               //            return "opacity: 0.5"
               //        }else{
               //            return "opacity: 0"
               //        }
               //    })

               this._indicatorStage1.selectAll(".pie-circles-indicator-group")
                   .selectAll("circle")
                   .data(this._pie(this.data[index]["audience"][this._audienceCounter]))
                   .attr("fill" ,function(d, i){
                       return self._color[i]
                   })


               this._indicatorStage2.selectAll(".pie-circles-indicator-group")
                   .selectAll("circle")
                   .data(this._pie(this.data[index]["audience"][this._audienceCounter]))
                   .attr("fill" ,function(d, i){
                       return self._color[i]
                   })
           }
           //this.indexNumberChange(index);
       }



        public updateDataAudience(){
            console.log("updateDataAudience");
            this.updateGraphic(this._actualIndex);
        }

       private _arcTween(a, x): (t:any) => string {
           console.log("_arcTween");
           var self = this;

           var i = d3.interpolate(self._current[x], a);
           self._current[x]= i(0);
           return function(t) {
               return self._arc(i(t));
           };

       }


        public deleteGraph(){
            console.log("deleteGraph");
            this.exitGraph();
        }

        private exitGraph(){
            console.log("exitGraph");
            d3.select(".prof-circles-indicator1 svg").remove()
            d3.select(".prof-circles-indicator2 svg").remove()
            d3.select(".pie-chart").remove();
            d3.selectAll(".prof-circles-indicator svg").remove();
        }


    }
}