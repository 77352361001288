/**
 * AboutViewMediator.ts
 * Created by Esteban on 12/1/14.
 */

/// <reference path="../views/AboutView.ts" />
/// <reference path="../events/ApplicationEvent.ts" />

module media.hrratings.mediators {

    import EventDispatcher = soma.EventDispatcher;
    import LocaleEvent = media.hrratings.events.LocaleEvent;
    import AboutView = media.hrratings.views.AboutView;
    import ApplicationEvent = media.hrratings.events.ApplicationEvent;

    export class AboutViewMediator {

        static inject: string[] = ["target", "dispatcher"];

        constructor(target: AboutView, dispatcher: EventDispatcher) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });
        }
    }
}