/**
 * Created by Esteban on 10/20/14.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/AboutView.ts" />

module media.hrratings.commands {

    import ViewManager = core.ViewManager;
    import AboutView = views.AboutView;

    export class OpenAboutCommand {

        mainViewManager: ViewManager = null; //injected

        execute (event) {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenAboutCommand.execute!");
            console.log("section: ", event.params.data);

            this.mainViewManager.openView(MainViews.About);
            var aboutView:AboutView = <AboutView>this.mainViewManager.getViewById(MainViews.About);
            aboutView.showTab(event.params.data);
        }
    }
}