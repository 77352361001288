/**
 * Created by Esteban on 10/28/14.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/InfographicProfileView.ts" />

module media.hrratings.commands {

    import ViewManager = core.ViewManager;
    import InfographicProfileView = views.InfographicProfileView;

    export class OpenInfographicProfileCommand {

        mainViewManager: ViewManager = null; //injected

        execute (event) {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenInfographicProfileCommand.execute!");
            console.log("ID infographic profile:", event.params.itemId);

            if(!this.mainViewManager.isViewOpen(MainViews.InfographicProfile) ){
                this.mainViewManager.openView(MainViews.InfographicProfile);
            }
            var infographicView: InfographicProfileView = <InfographicProfileView>this.mainViewManager.getViewById(MainViews.InfographicProfile);
            infographicView.show(event.params.itemId, event.params.year);
        }
    }
}
