/**
 * WeeklyViewMediator.ts
 * Created by Esteban on 11/26/14.
 */

/// <reference path="../views/WeeklyView.ts" />
/// <reference path="../events/LocaleEvent.ts" />
/// <reference path="../events/FilterEvents.ts" />

module media.hrratings.mediators {

    import EventDispatcher  = soma.EventDispatcher;
    import WeeklyView       = media.hrratings.views.WeeklyView;
    import LocaleEvent      = media.hrratings.events.LocaleEvent;
    import PlaygroundEvents = media.hrratings.events.HomeEvents;
    import RoutesEvents     = media.hrratings.events.RoutesEvents;

    export class WeeklyViewMediator {

        static inject: string[] = ["target", "dispatcher", "lang"];

        constructor(target: WeeklyView, dispatcher: EventDispatcher, lang: string) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.initialize(lang);
            });

            dispatcher.addEventListener(RoutesEvents.REPORTS_ROUTE, (e: any) => {
                if (e.params.year && e.params.week) {
                    target.selectWeekly(e.params.year, e.params.week);
                } else {
                    target.selectLatestResults();
                }
            });
        }
    }
}
