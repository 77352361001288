module media.hrratings.icons {

    export class Profile {
        private _stage;

        private _widht;
        private _height;
        private _innerradius;
        private _radius;
        private _arc;
        private _path;
        private _pie;
        private _arrays;
        private _theColors;

        constructor(private _container){

            this._widht= 128;
            this._height= 128;
            this._radius= this._widht /2;
            this._innerradius= 30;

            this._arrays= [[65,40,79,64,98],[75,60,59,80,90]];
            this._theColors= ["#c0c0c0", "#ff794d" ];

            this.create();
        }

        private create():void {
            this.setStage();
            this.setArc();
            this.setPie();
            this.setSolidPath(this._arc);
        }

        private setStage():void {
            this._stage = d3
                .select(this._container)
                .append('svg')
                .attr('width', this._widht)
                .attr('height', this._height)
                .attr('class', "profile-icon")
                .append('g')
                .attr("transform", "translate(" + this._widht / 2 + "," + this._height / 2 + ")");
        }

        private setArc() {

            this._arc = d3.svg
                .arc()
                .innerRadius(this._innerradius)
                .outerRadius((d) => { return (this._radius - this._innerradius) * (d.data / 100) + this._innerradius; });
        }

        private setSolidPath(arc) {

            this._path = this._stage
                .selectAll('.solid-arc-icon')
                .data(this._pie(this._arrays[0]))
                .enter().append('path')
                .attr('fill', (d,i) => {
                    if (i === 0)    { return this._theColors[1] }
                    else            { return this._theColors[0] }
                })
                .attr('class', "solid-arc-icon")
                .attr('d', this._arc)
                .attr("stroke", "#f0f0f2")
                .attr("stroke-width", 2);
        }

        private setPie() {

            this._pie = d3.layout
                .pie()
                .sort(null)
                .value(function () { return 1} );
        }

        pathTrans (datanumber : number) {

            this._path.data(this._pie(this._arrays[datanumber]));

            this._path.transition()
                .attr("d", this._arc)
                .duration(500)
                .delay(function (d, i) { return 100 + i });
        }
    }
}