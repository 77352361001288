/**
 * Created by had on 11/20/14.
 */

/// <reference path="./AbstractDialogView.ts" />
/// <reference path="./infographicVs/SemiCircleGraph.ts" />
/// <reference path="../events/InfographicVsEvent.ts" />
/// <reference path="../events/FilterEvents.ts" />

module media.hrratings.views {

    import ViewManager = media.hrratings.core.ViewManager;
    import SemiCircleGraph = media.hrratings.infographicVs.SemiCircleGraph;
    import InfographicVsEvent = media.hrratings.events.InfographicVsEvent;
    import PlaygroundEvents = media.hrratings.events.HomeEvents;


    export class InfographicVsView extends AbstractDialogView{

        private _elPanel: HTMLElement;
        private _elBtPrev: HTMLElement;
        private _elBtNext: HTMLElement;
        private _elBtClose: HTMLElement;
        private _elDbHeader: HTMLElement;
        private _elDbFooter: HTMLElement;
        private _elDdlDateLeft: HTMLElement;
        private _elDdlDateRight: HTMLElement;
        private _elDdlChannelLeft: HTMLElement;
        private _elDdlChannelRight: HTMLElement;
        private _elDdlProgramLeft: HTMLElement;
        private _elDdlProgramRight: HTMLElement;


        private _vsInfographic: boolean;
        private _dataLeft: Array<any>;
        private _dataRight: Array<any>;
        private _maxValue: number;
        private _leftPeriod: any;
        private _rightPeriod: any;

        private _channelCounter: Array<any>;
        private _showCounter: Array<any>;


        private _data: any;

        public containerNameLeft: string;
        public containerNameRight: string;

        private _leftSemiCircleGraph: SemiCircleGraph;
        private _rightSemiCircleGraph: SemiCircleGraph;

        private _periodDates:Array<any>;
        private _lPeriodId:number;
        private _rPeriodId:number;

        private _thaTitDetail:JQuery;
        private _thaDetail:JQuery;
        private _thaDetailSpan:JQuery;

        //public availablePeriodDates:ko.observableArray;
        //public selectedPeriodDate:ko.observable;

        constructor(_target:HTMLElement) {

            super(_target);

            this._cacheElements();

            this._setup();
        }

        private _setup(): void {
            this.containerNameLeft= "vs-left-graph-container";
            this.containerNameRight= "vs-right-graph-container";
            this._thaTitDetail = $("#infographic-vs .infographic-disclamer .tit-cont h6");
            this._thaDetail = $("#infographic-vs .infographic-disclamer .content");

            this._channelCounter= [0,1];
            this._showCounter= [-1, -1];
            this._bindNavigation();
        }

        private _bindNavigation(): void {
            $(this._elBtClose).click( (e: JQueryEventObject) => {
                var loc = location.hash.toString().match(/.*Week[0-9]*/);
                location.hash = loc[0];
                e.preventDefault();
            });
        }

        getPanel (): HTMLElement {
            return this._elPanel;
        }

        setupBindings(): void {


            ko.applyBindings(this, this._elDdlDateLeft);
            ko.applyBindings(this, this._elDdlDateRight);

            ko.applyBindings(this, this._elDdlChannelLeft);
            ko.applyBindings(this, this._elDdlChannelRight);

            ko.applyBindings(this, this._elDdlProgramLeft);
            ko.applyBindings(this, this._elDdlProgramRight);
            ko.applyBindings(this, this._elDbFooter);
            ko.applyBindings(this, this._elDbHeader);
            ko.applyBindings(this, $(this._elPanel).find('#vs-left-graph-container .infographic-tooltip').get(0));
            ko.applyBindings(this, $(this._elPanel).find('#vs-left-controllers .infographic-tooltip').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.instruction-vs-1').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.instruction-vs-2').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.vs-right-titles-container').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.vs-left-titles-container').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.bt-close-dialog').get(0));

            //ko.applyBindings(this, $(this._elPanel).find('.infographic-footer').get(0));
        }

        private _cacheElements (): void {
            this._elPanel = $(this.target).find('#infographic-vs').get(0);

            this._elDbHeader = $(this._elPanel).find('.vs-header').get(0);
            this._elDbFooter = $(this._elPanel).find('.infographic-footer').get(0);
            this._elDdlDateLeft = document.getElementById('vs-date-a');
            this._elDdlDateRight = document.getElementById('vs-date-b');
            this._elDdlChannelLeft = document.getElementById('vs-channel-a');
            this._elDdlChannelRight = document.getElementById('vs-channel-b');
            this._elDdlProgramLeft = document.getElementById('vs-program-a');
            this._elDdlProgramRight = document.getElementById('vs-program-b');
            this._elBtNext = $(this._elPanel).find('.infographic-footer span.bt-next').get(0);
            this._elBtPrev = $(this._elPanel).find('.infographic-footer span.bt-prev').get(0);
            this._elBtClose = $(this._elPanel).find('.bt-close-dialog').get(0);

        }

        //private _bindNav (): void {
        //
        //    $(this._elBtNext)
        //        .on('click', () => {
        //
        //            if(!this._data.isNext){ return; }
        //            routie(routie.lookup("vs", {id: this._data.idNext}));
        //        });
        //
        //    $(this._elBtPrev)
        //        .on('click', () => {
        //
        //            if(!this._data.isPrev){ return; }
        //            routie(routie.lookup("vs", {id: this._data.idPrev}));
        //        });
        //}

        private _bind (): void {
            //this.periodBindings();
            this.getDataSide();
            this.getTheDataToshow();

            $(".vs-header h1").text(this._data.title);


            this._leftSemiCircleGraph= new SemiCircleGraph(this._dataLeft, this._maxValue, this.containerNameLeft, 0);
            this._rightSemiCircleGraph= new SemiCircleGraph(this._dataRight, this._maxValue ,this.containerNameRight, 1);

            this._leftSemiCircleGraph.createGraphic();
            this._rightSemiCircleGraph.createGraphic();

            this.knockoutLeft();
            this.knockoutRight();

            $("#vs-left-controllers .vs-channel-date")["selectBox"]();
            $("#vs-left-controllers .vs-channel-controlls")["selectBox"]();
            $("#vs-left-controllers .vs-show-controlls")["selectBox"]();

            $("#vs-right-controllers .vs-channel-date")["selectBox"]();
            $("#vs-right-controllers .vs-channel-controlls")["selectBox"]();
            $("#vs-right-controllers .vs-show-controlls")["selectBox"]();
            //this._knockOuttry();

        }

        /**
         *
         * @param id
         */
        show (id: string, year: string): void {

            if(this._vsInfographic){
                this.destroyProfGraph();
            }

            $(".infographic-vs .infographic-spinner").css("display", "block");
            TweenLite.set($(".infographic-vs .infographic-spinner"),{autoAlpha: 1});

            var event = new InfographicVsEvent(InfographicVsEvent.INFOGRAPHIC_VS);
            event.id = id;
            event.category = 'versus';
            event.year = year;
            this.dispatchEvent(event);
            this._vsInfographic= true;
        }

        /**
         *
         * @param data
         */
        loadResults (data: any): void {

            //console.log('----------');
            //console.log('loadResults Infographic VERSUS.');

            this._data = data;
            this._leftPeriod = data;
            this._rightPeriod = data;
            this._lPeriodId= data["id"];
            //console.log(this._lPeriodId)
            this._rPeriodId= data["id"];
            //console.log(this._rPeriodId)

            TweenLite.to($(".infographic-vs .infographic-spinner"), .05,{autoAlpha: 0, delay: 1, onComplete: () =>{
                $(".infographic-vs .infographic-spinner").css("display", "none");
                this._bind();
            }});


        }

        open_hook (d:Q.Deferred<void>): void {
            super.open_hook(d);
        }

        close_hook (d:Q.Deferred<void>):void {
            this._leftSemiCircleGraph.reverseAnim();
            _.delay(()=>{
                TweenLite.to(this.getPanel(), 0.25,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                    TweenLite.set(this.getPanel(), {top: '-100%'});
                    TweenLite.set(this.target, {css:{display:'none'}});
                    //console.log("cerrada vs view");
                    //destrroy infographic
                    this.destroyProfGraph();
                    d.resolve();
                }});
                $(this._elPanel).scrollTop(0);

            },450);
        }

        loadError (): void {
            //console.log('----------');
            //console.log('loadError Infographic Profile');
        }

        private destroyProfGraph (): void {

            //this.resetBindings();
            this.resetBindingsLeft ();
            this.resetBindingsRight();
            this.resetBindingsDates();

            this.unbinds();

            this._leftSemiCircleGraph.destroyGraphic();
            this._leftSemiCircleGraph.actualIndex=0;
            this._rightSemiCircleGraph.destroyGraphic();
            this._rightSemiCircleGraph.actualIndex=0;
            this._vsInfographic= false;
            this._channelCounter= [0,1];
            this._showCounter= [-1, -1];
        }

        private unbinds(): void{
            //$("#vs-left-controllers .vs-channel-date")["selectBox"]("destroy");
            //$("#vs-left-controllers .vs-channel-controlls")["selectBox"]("destroy");
            //$("#vs-left-controllers .vs-show-controlls")["selectBox"]("destroy");
            //
            //$("#vs-right-controllers .vs-channel-date")["selectBox"]("destroy");
            //$("#vs-right-controllers .vs-channel-controlls")["selectBox"]("destroy");
            //$("#vs-right-controllers .vs-show-controlls")["selectBox"]("destroy");


            $("#vs-left-controllers .vs-channel-select").unbind("change");
            $("#vs-left-controllers .vs-show-select").unbind("change");
            $("#vs-right-controllers .vs-channel-select").unbind("change");
            $("#vs-right-controllers .vs-show-select").unbind("change");

            $("#infographic-vs .infographic-disclamer").unbind("click");

        }

        private resetBindingsLeft (): void {
            ko.cleanNode(document.getElementById("vs-channel-select-a"));
            ko.cleanNode(document.getElementById("vs-show-select-a"));
        }

        private resetBindingsRight(): void{
            ko.cleanNode(document.getElementById("vs-channel-select-b"));
            ko.cleanNode(document.getElementById("vs-show-select-b"));
        }

        private resetBindingsDates(): void{
            ko.cleanNode(document.getElementById("vs-left-dates"));
            ko.cleanNode(document.getElementById("vs-right-dates"));
        }

        private getDataSide (): void {

            this.checkLeft();
            this.checkRight();
            //this.getTheDataToshow();
        }

        private checkLeft(): void{


            if(this._showCounter[0] !== -1){

                this._dataLeft= [];
                this._dataLeft.push(this._leftPeriod["category"][this._channelCounter[0]]["shows"][this._showCounter[0]]);
            }else{

                this._dataLeft = this._leftPeriod["category"][this._channelCounter[0]]["shows"];

            };
        }
        private checkRight(): void{

            if(this._showCounter[1] !== -1){
                this._dataRight= [];
                this._dataRight.push(this._rightPeriod["category"][this._channelCounter[1]]["shows"][this._showCounter[1]]);
            } else {
                this._dataRight = this._rightPeriod["category"][this._channelCounter[1]]["shows"];
            };
        }

        private getTheDataToshow (): void {

            var left= this._dataLeft.map(function(d){
                return d["value"];
            });
            var right= this._dataRight.map(function(d){
                return d["value"];
            });
            var maxValue= Math.max.apply(null, left.concat(right));

            this._maxValue= maxValue;

            //console.log(this._maxValue, "@@@@@@");
        }

        private sameChannelsSelected(): boolean {
            return this._channelCounter.length == 2 &&
                this._channelCounter[0] === this._channelCounter[1];
        }

        private samePeriodSelected(): boolean {
            return this._lPeriodId === this._rPeriodId;
        }

        private resetDisabledPrograms(side: string) {
            if (!this.sameChannelsSelected()){
                //console.log('resetting shows');
                //if (side === undefined || side == 'left')
                $('#vs-show-select-a, #vs-show-select-b').find('option').attr('disabled', null);
            }
        }

        private updateDisabledPrograms = (target: HTMLElement) => {

            var currentControl = $(target);

            var getCurrentOppositeControl = (): JQuery => {
                var targetId = currentControl.attr('id');
                return targetId === 'vs-show-select-a' ? $('#vs-show-select-b') : $('#vs-show-select-a');
            };

            var oppositeControl = getCurrentOppositeControl(),
                oppositeOptions = oppositeControl.find('option'),
                currentIndex = currentControl.find('option:selected').index();

            oppositeOptions.attr('disabled', null);
            if (this.samePeriodSelected() && this.sameChannelsSelected() && currentIndex > 0){
                var optionToDisable = oppositeOptions.get(currentIndex);
                $(optionToDisable).attr('disabled', 'true');
            }

        };

        private knockoutLeft(): void{
            var dataA= this._leftPeriod["category"];

            function buildData(data){
                var array= [];
                   _.each(data, (channel: any) => {
                    var item = {
                      text: channel["itemName"],
                      childOptions: []
                    };
                    _.each(channel["shows"], (show: any) => {
                        item.childOptions.push({ text: show["itemName"] });
                    });
                    array.push(item);
                });
                return array;
            }

            var viewModel = {
                categories: buildData(dataA),
                selectedCategory : ko.observable(),
                selectedShow : ko.observable(),
                all: $.i18n.translate( "tr-bt-all" )
            };

            viewModel['show'] = ko.computed(function(){
                return viewModel.selectedCategory() ? viewModel.selectedCategory()['childOptions'] : null;
            });


            ko.applyBindings(viewModel, document.getElementById("vs-channel-select-a"));
            ko.applyBindings(viewModel, document.getElementById("vs-show-select-a"));

            //console.log(viewModel.selectedCategory(), "galletas");

            this.changeIndexOfCategory(viewModel, 0);

            this.onChannelChange("left");
            this.onShowChange("left");

        }

        private knockoutRight (): void {

            var dataB= this._rightPeriod["category"];
            //console.log(dataB, "11111111111");

            var cascadingOption = function(data){
                var self = this;
                self["text"] = data.text;
                self["childOptions"] = data.childOptions;
            }

            function buildData(data){

                var array= [];
                for(var key in data){
                    var childarray=[];
                    for(var keychild in data[key].shows){
                        var child= new cascadingOption({
                            text: data[key].shows[keychild].itemName
                        })
                        childarray.push(child);
                    }
                    var thing= new cascadingOption(
                        {
                            text: data[key].itemName,
                            childOptions: childarray
                        })
                    array.push(thing);
                }
                return array;
            }

            var viewModelb = {
                categories: buildData(dataB),
                selectedCategory : ko.observable(),
                selectedShow : ko.observable(),
                all: $.i18n.translate( "tr-bt-all" )
            };
            viewModelb["show"] = ko.computed(function(){
                return viewModelb.selectedCategory() ? viewModelb.selectedCategory()["childOptions"] : null;
            });


            ko.applyBindings(viewModelb, document.getElementById("vs-channel-select-b"));
            ko.applyBindings(viewModelb, document.getElementById("vs-show-select-b"));
            //ko.applyBindings(viewModel, document.getElementById("vs-left-controllers"));
            //ko.applyBindings(viewModelb, document.getElementById("vs-right-controllers"));

            //viewModelb.selectedCategory(viewModelb.categories[1]);
            this.changeIndexOfCategory(viewModelb, 1);

            this.onChannelChange("right");
            this.onShowChange("right");

            //_.delay(()=>{
            //    ko.cleanNode(document.getElementById("profile-right-controllers"));
            //    ko.cleanNode(document.getElementById("profile-left-controllers"));
            //},1000);
        }

        private changeIndexOfCategory(model, side){
            model.selectedCategory(model.categories[this._channelCounter[side]]);
        }

        private onChannelChange (orientation: string): void {
            var self= this;
            $("#vs-"+ orientation +"-controllers .vs-channel-select").on("change", function(){
                if(orientation == "left"){
                    self._channelCounter[0]= $("#vs-"+ orientation +"-controllers .vs-channel-select option:selected").index();
                    self._showCounter[0]= -1;
                    self._leftSemiCircleGraph.actualIndex= 0;
                }else{
                    self._channelCounter[1]= $("#vs-"+ orientation +"-controllers .vs-channel-select option:selected").index();
                    self._showCounter[1]= -1;
                    self._rightSemiCircleGraph.actualIndex= 0;
                }

                //console.log(self._channelCounter, "Channel")

                self.getDataSide();
                self.getTheDataToshow();
                self.updateChildrenGraphics();
                self.resetDisabledPrograms(orientation);
            })
        }

        private onShowChange (orientation: string): void {
            var self= this;
            $("#vs-"+ orientation +"-controllers .vs-show-select").on("change", function(){
                if(orientation == "left"){
                    self._showCounter[0]= ($("#vs-"+ orientation +"-controllers .vs-show-select option:selected").index())-1;
                    self._leftSemiCircleGraph.actualIndex= 0;
                }else{
                    self._showCounter[1]= ($("#vs-"+ orientation +"-controllers .vs-show-select option:selected").index())-1;
                    self._rightSemiCircleGraph.actualIndex= 0;
                }
                self.getDataSide();
                self.getTheDataToshow();
                self.updateChildrenGraphics();
                self.updateDisabledPrograms(this);

            })
        }

        private updateChildrenGraphics (): void {

            this._leftSemiCircleGraph.data= this._dataLeft;
            this._leftSemiCircleGraph.maxValue= this._maxValue;
            this._rightSemiCircleGraph.data= this._dataRight;
            this._rightSemiCircleGraph.maxValue= this._maxValue;
            this._leftSemiCircleGraph.updateGraphic();
            this._rightSemiCircleGraph.updateGraphic();
        }

        setPeriodDates(data:any):void {
            this._periodDates = data;
            this.periodBindings();
            //console.log(this._periodDates, "·······PPPPPPPPPPPPPPPPP")
        }

        updateLeftPeriod(data:any):void {
            //console.log("Update updateLeftPeriod");

            this._leftPeriod=data;
            this._lPeriodId= data["id"];

            this._channelCounter[0]= 0;
            this._showCounter[0]= -1;

            this.resetBindingsLeft ();

            this.knockoutLeft();

            $("#vs-left-controllers .vs-channel-date")["selectBox"]();
            $("#vs-left-controllers .vs-channel-controlls")["selectBox"]();
            $("#vs-left-controllers .vs-show-controlls")["selectBox"]();

            this.changeGraphData();
        }

        updateRightPeriod(data:any):void {
            //console.log("Update updateRightPeriod");
            this._rightPeriod=data;
            this._rPeriodId= data["id"];

            this._channelCounter[1]= 0;
            this._showCounter[1]= -1;

            this.resetBindingsRight();

            this.knockoutRight();

            $("#vs-right-controllers .vs-channel-date")["selectBox"]();
            $("#vs-right-controllers .vs-channel-controlls")["selectBox"]();
            $("#vs-right-controllers .vs-show-controlls")["selectBox"]();
            this.changeGraphData();

        }

        private changeGraphData():void {
            this.getDataSide ();
            this.getTheDataToshow();
            this.updateChildrenGraphics();
            //this.resetBindings();
            //this._knockOuttry();
        }

        private getUrlYear() {
            var link = window.location.href;
            var match:any =  link.match(/\/([0-9]+)\//);
            return match[1];
        }

        private getUrlId(url?:string) {
            console.log('getUrlId:',url);
            var link = url || window.location.href;
            var match:any =  link.match(/([0-9]+)\/([A-Z|a-z|0-9|-]+)/);
            return match[2];
        }

        private periodBindings(): void{
            var self = this;

            var rModel = {
                availablePeriodDates: ko.observableArray(this._periodDates),
                selectedPeriodDate: ko.observable(),
                onChange:function () {
                    console.log('vsview:', window.location, self.getUrlYear(), self.getUrlId());
                    var event = new InfographicVsEvent(InfographicVsEvent.REQUEST_PERIOD);
                    event.id = self.getUrlId(this.selectedPeriodDate().url);
                    event.year = self.getUrlYear();
                    event.category = 'versus';
                    event.info = "right";
                    self.dispatchEvent(event);
                }
            }

            var lModel = {
                availablePeriodDates: ko.observableArray(this._periodDates),
                selectedPeriodDate: ko.observable(),
                onChange:function () {
                    //console.log(this.selectedPeriodDate());
                    var event = new InfographicVsEvent(InfographicVsEvent.REQUEST_PERIOD);
                    event.id = self.getUrlId(this.selectedPeriodDate().url);
                    event.year = self.getUrlYear();
                    event.category = 'versus';
                    event.info = "left";
                    self.dispatchEvent(event);
                }
            }

            var objectIdL=_.findWhere(lModel.availablePeriodDates(), {id: this._lPeriodId});
            var objectIdR=_.findWhere(rModel.availablePeriodDates(), {id: this._rPeriodId});

            lModel.selectedPeriodDate(objectIdL);
            rModel.selectedPeriodDate(objectIdR);

            ko.applyBindings(lModel, document.getElementById('vs-left-dates'));
            ko.applyBindings(rModel, document.getElementById('vs-right-dates'));
        }


    }
}
