/// <reference path="./../core/ViewManager.ts" />

module media.hrratings.commands {

    import ViewManager = media.hrratings.core.ViewManager;

    export class OpenLegalsEsCommand {

        mainViewManager: ViewManager = null; //injected

        execute () {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenLegalsEsCommand.execute!");

            if(!this.mainViewManager.isViewOpen(MainViews.LegalsEs) ){
                this.mainViewManager.openView(MainViews.LegalsEs);
            }
        }
    }
}