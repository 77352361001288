/**
 * Created by Esteban on 10/28/14.
 */

/// <reference path="../views/InfographicProfileView.ts" />
/// <reference path="../services/HRServices.ts" />
/// <reference path="../events/InfographicProfileEvent.ts" />

module media.hrratings.mediators {

    import EventDispatcher = soma.EventDispatcher;
    import InfographicProfileView = media.hrratings.views.InfographicProfileView;
    import HRServices = media.hrratings.services.HRServices;
    import LocaleEvent = media.hrratings.events.LocaleEvent;
    import InfographicProfileEvent = media.hrratings.events.InfographicProfileEvent;
    import PlaygroundEvents = media.hrratings.events.HomeEvents;

    export class InfographicProfileMediator {

        static inject:string[] = ["target", "dispatcher", "service", "lang"];

        constructor(target: InfographicProfileView, dispatcher: EventDispatcher, service: HRServices, lang:string) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });

            target.addEventListener(InfographicProfileEvent.INFOGRAPHIC_PROFILE, (event: InfographicProfileEvent) => {

                service.getInfographic(event.id, event.caregory, event.year, lang)
                    .done( (data: any) => {
                        //pending VO / client?
                        target.loadResults(data);
                    })
                    .fail( () =>{
                        target.loadError();
                    });
            });
        }
    }
}
