/**
 * OpenWeeklyCommand
 * Created by Esteban on 11/18/14.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/WeeklyView.ts" />

module media.hrratings.commands {

    import ViewManager = media.hrratings.core.ViewManager;
    import WeeklyView = media.hrratings.views.WeeklyView;

    export class OpenWeeklyCommand {

        mainViewManager: ViewManager = null;

        execute () {
            //console.clear();
            console.log("::::::::::::::::::::::::::::");
            console.log("OpenWeeklyCommand.execute!");

            this.mainViewManager.openView(MainViews.Weekly);
        }
    }
}
