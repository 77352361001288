/**
 * Created by raul on 10/15/14.
 */
/// <reference path="../events/ApplicationEvent.ts" />
/// <reference path="../views/MenuView.ts" />
/// <reference path="../events/RoutesEvents.ts" />
/// <reference path="../events/LocaleEvent.ts" />
/// <reference path="../events/MenuEvents.ts" />

module media.hrratings.mediators {

    import EventDispatcher = soma.EventDispatcher;
    import MenuView = media.hrratings.views.MenuView;
    import ApplicationEvent = media.hrratings.events.ApplicationEvent;
    import RoutesEvents = media.hrratings.events.RoutesEvents;
    import LocaleEvent = media.hrratings.events.LocaleEvent;
    import PlaygroundEvents = media.hrratings.events.HomeEvents;
    import MenuEvents = media.hrratings.events.MenuEvents;

    export class MenuViewMediator {

        static inject:string[] = ["target", "dispatcher"];

        constructor(target:MenuView, dispatcher:EventDispatcher) {
            
            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });

            dispatcher.addEventListener(ApplicationEvent.LOADER_COMPLETE, function () {
                target.open();
            });

            dispatcher.addEventListener(RoutesEvents.HOME_ROUTE, (event) => {
                target.closeActiveItem();
                target.deleteActiveItem();

                target.hideMenu();
            });
            dispatcher.addEventListener(RoutesEvents.ABOUT_ROUTE, (event) => {
                if(event.params.data === undefined ){
                    routie('company/strategic-focus');
                }else{
                    target.deleteActiveItem();
                    target.setActiveItemMenu(0, event.params.data);
                }
            });
            dispatcher.addEventListener(RoutesEvents.SERVICES_ROUTE, (event) => {
                if(event.params.data === undefined ){
                    routie('services/television-audience-measurement');
                }else{
                    target.deleteActiveItem();
                    target.setActiveItemMenu(1, event.params.data);
                }
            });

            dispatcher.addEventListener(RoutesEvents.METHODOLOGY_ROUTE, (event) => {
                if(event.params.section === undefined ){
                    routie('methodology/television-measurement');
                }else{
                    target.deleteActiveItem();
                    target.setActiveItemMenu(2, event.params.section, event.params.subsection );
                }
            });

            dispatcher.addEventListener(RoutesEvents.OUR_INFORMATION_ROUTE, () => {
                routie('our-information/charts');
            });
            dispatcher.addEventListener(RoutesEvents.INFOGRAPHICS_ROUTE, (event) => {

                target.deleteActiveItem();
                target.setActiveItemMenu(3, 'charts' );
            });
            dispatcher.addEventListener(RoutesEvents.REPORTS_ROUTE, (event) => {
                target.deleteActiveItem();
                target.setActiveItemMenu(3, 'weekly' );
            });
            dispatcher.addEventListener(RoutesEvents.PRESS_ROUTE, (event) => {

                target.deleteActiveItem();
                target.setActiveItemMenu(3, 'press' );
            });
            
            dispatcher.addEventListener(RoutesEvents.CONTACT_ROUTE, () => {
                target.deleteActiveItem();
                target.setActiveItemMenu(4);
                target.hideMenu();
            });

            dispatcher.addEventListener(RoutesEvents.LEGALS_WEB_ROUTE, () => {
                target.closeActiveItem();
                target.deleteActiveItem();
                target.hideMenu();
            });
            dispatcher.addEventListener(RoutesEvents.LEGALS_ES_ROUTE, () => {
                target.closeActiveItem();
                target.deleteActiveItem();
                target.hideMenu();
            });
            dispatcher.addEventListener(RoutesEvents.LEGALS_PANEL_ROUTE, () => {
                target.closeActiveItem();
                target.deleteActiveItem();
                target.hideMenu();
            });

            dispatcher.addEventListener(RoutesEvents.INFOGRAPHIC_VS_ROUTE, () => {
                target.closeActiveItem();
                target.deleteActiveItem();
                target.hideMenu();
            });
            dispatcher.addEventListener(RoutesEvents.INFOGRAPHIC_PROFILE_ROUTE, () => {
                target.closeActiveItem();
                target.deleteActiveItem();
                target.hideMenu();
            });
            dispatcher.addEventListener(RoutesEvents.INFOGRAPHIC_RANKING_ROUTE, () => {
                target.closeActiveItem();
                target.deleteActiveItem();
                target.hideMenu();
            });

            dispatcher.addEventListener(MenuEvents.MENU_HIDE, ()=>{

                target.hideMenu();
            });
        }
    }
}
