/**
 * ContactViewMediator.ts
 * Created by Esteban on 12/1/14.
 */

/// <reference path="../views/ContactView.ts" />
/// <reference path="../services/HRServices.ts" />
/// <reference path="../events/ContactEvents.ts" />

module media.hrratings.mediators {

    import EventDispatcher = soma.EventDispatcher;
    import ContactView = media.hrratings.views.ContactView;
    import HRServices = media.hrratings.services.HRServices;
    import LocaleEvent = media.hrratings.events.LocaleEvent;
    import ContactEvents = media.hrratings.events.ContactEvents;

    export class ContactViewMediator {

        static inject: string[] = ["target", "dispatcher", "service", "lang"];

        constructor(target: ContactView, dispatcher: EventDispatcher, service: HRServices, lang: string) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });

            target.addEventListener(ContactEvents.CONTACT_FORM_SEND, (event: any) => {

                service.sendContact(event.lang, event.name, event.email, event.company, event.subject, event.message)
                    .done( () => { target.showThxContact()     ;} )
                    .fail( () => { target.showErrorContact()   ;} );
            });
            target.addEventListener(ContactEvents.CONTACT_FORM_NEWSLETTER, (event: any) => {

                service.sendNewsletter(event.email, lang)
                    .done( () => { target.showThxNewsletter()   ;})
                    .fail( () => { target.showErrorNewsletter() ;});
            });
        }
    }
}
