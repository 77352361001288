/**
 * ContactEvents
 * Created by Esteban on 1/16/15.
 */

module media.hrratings.events {
    export class ContactEvents extends createjs.Event {
        static CONTACT_FORM_SEND:string = "ContactEvents.contact_form_send";
        static CONTACT_FORM_NEWSLETTER:string = "ContactEvents.contact_form_newsletter";

        public name: string;
        public email: string;
        public company: string;
        public subject: string;
        public message: string;
        constructor (type: string) {
            super(type,false,false);
        }
    }
}