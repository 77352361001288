/**
 * Created by raul on 10/16/14.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/ServicesView.ts" />

module media.hrratings.commands {

    import ViewManager = core.ViewManager;
    import ServicesView = views.ServicesView;

    export class OpenServicesCommand {

        mainViewManager: ViewManager = null; //injected

        execute (event) {
            
            console.log("::::::::::::::::::::::::::::");
            console.log("OpenServicesCommand.execute!");
            console.log("data: ", event.params.data);


            this.mainViewManager.openView(MainViews.Services);
            var servicesView: ServicesView = <ServicesView>this.mainViewManager.getViewById(MainViews.Services);
            servicesView.showTab(event.params.data);
        }
    }
}
