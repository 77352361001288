/**
 * Created by Ivan on 5/21/15.
 */


module media.hrratings.filters {
    export class WeeklyFilter extends Filter {
        constructor() {
            super();
        }

        public fetchItems(lang: string) {
            this.updateAPIItems(()=> {
                return this.APIService.getWeeklyItems(lang);
            });
        }

        protected addFilterItems(data: any) {
            console.log(data);
            _.each(data, (entry: any)=> {
                this.children.push(new WeeklyFilterItem(entry));
            });

        }

        public getWeeklyUrlOf(item: WeeklyFilterItem) {
            return '#our-information/weekly/' + item.date.year() + '/Week' + item.week;
        }

    }

}