/**
 * Created by Esteban on 10/28/14.
 */

/// <reference path="./AbstractDialogView.ts" />
/// <reference path="./infographicProfile/CircleGraph.ts" />
/// <reference path="./infographicProfile/PieGraph.ts" />
/// <reference path="../events/InfographicProfileEvent.ts" />
/// <reference path="../events/FilterEvents.ts" />


module media.hrratings.views {

    import ViewManager = media.hrratings.core.ViewManager;
    import CircleGraph = media.hrratings.infographicProfile.CircleGraph;
    import PieGraph = media.hrratings.infographicProfile.PieGraph;
    import InfographicProfileEvent = media.hrratings.events.InfographicProfileEvent;
    import PlaygroundEvents = media.hrratings.events.HomeEvents;

    export class InfographicProfileView extends AbstractDialogView{

        private _elPanel            : HTMLElement;
        private _elBtPrev           : HTMLElement;
        private _elBtNext           : HTMLElement;
        private _elBtClose          : HTMLElement;
        private _profileInfographic : boolean;
        private _data               : any;
        private _CircleGraph        : CircleGraph;
        private _PieGraph           : PieGraph;

        public containerNameCircle  : string;
        public containerNamePie     : string;

        private _thaTitDetail       :JQuery;
        private _thaDetail          :JQuery;
        private _thaDetailSpan      :JQuery;

        constructor(_target:HTMLElement) {
            super(_target);
            this._setup();
        }

        private _setup(): void {

            this.containerNameCircle = 'profile-circle-graph-container';
            this.containerNamePie = 'profile-pie-graph-container';
            this._thaTitDetail = $("#infographic-profile .infographic-disclamer .tit-cont h6");
            this._thaDetail = $("#infographic-profile .infographic-disclamer .content");
            this._thaDetailSpan = $("#infographic-profile .infographic-disclamer .content span");

            this._cacheElements();
            this._bindNavigation();
        }

        getPanel (): HTMLElement {

            return this._elPanel;
        }

        setupBindings(): void {

            ko.applyBindings(this, $(this._elPanel).find('.prof-graph-audience').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.infographic-footer').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.prof-header').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.infographic-tooltip').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.prof-description-titles').get(0));
            ko.applyBindings(this, this._elBtClose);
        }

        private _cacheElements (): void {
            this._elPanel = $(this.target).find('#infographic-profile').get(0);
            this._elBtClose = $(this._elPanel).find('.bt-close-dialog').get(0);
            this._elBtNext = $(this._elPanel).find('.infographic-navigation .bt-next').get(0);
            this._elBtPrev = $(this._elPanel).find('.infographic-navigation .bt-prev').get(0);

        }

        private _bindNavigation (): void {
            $(this._elBtClose).click( (e) => {
                var loc = location.hash.toString().match(/.*Week[0-9]*/);
                location.hash = loc[0];
                e.preventDefault();
            });
        }

        private _bind (): void {
            var self= this;
            this.insertheaderTitles();
            this.knockOut();

            $("#prof-category-select")["selectBox"]();

            this._CircleGraph= new CircleGraph(this._data, this.containerNameCircle);

            $(".prof-graph-audience .prof-input-details").parents("li").find(".prof-input-details").css("opacity", "1");
            $(".prof-graph-audience circle").parents("li").find("circle").css("opacity", "1");

            $("#prof-graph-audience-vs li:nth-child(3) .prof-input-details").css("opacity", "0.3");
            $("#prof-graph-audience-vs li:nth-child(3) circle").css("opacity", "0");



            this._CircleGraph.addEventListener(InfographicProfileEvent.ONCOMPLETE, function(event:InfographicProfileEvent){
                self._PieGraph= new PieGraph(event.data[0], self.containerNamePie);
                self._PieGraph.graphColor= event.data[1];
                self._PieGraph.createGraphic();
            });

            this._CircleGraph.addEventListener(InfographicProfileEvent.CLICK, function (event:InfographicProfileEvent) {
                self._PieGraph._actualIndex= event.data[1];
                self._PieGraph.updateGraphic(self._PieGraph._actualIndex);
            });

            this._CircleGraph.addEventListener(InfographicProfileEvent.MOUSEOVER, function (event:InfographicProfileEvent) {
                self._PieGraph.updateGraphic(event.data[1]);
            });

            this._CircleGraph.addEventListener(InfographicProfileEvent.MOUSEOUT, function (event:InfographicProfileEvent) {
                self._PieGraph.updateGraphic(self._PieGraph._actualIndex);
            });

            this._CircleGraph.addEventListener(InfographicProfileEvent.CHANGE, function (event:InfographicProfileEvent) {
                self._PieGraph.data= event.data[0];
                self._PieGraph.graphColor= event.data[1];
                self._PieGraph._actualIndex= 0;
                self._PieGraph.updateGraphic(self._PieGraph._actualIndex);

            });

            this._CircleGraph.addEventListener(InfographicProfileEvent.WIDHTCHANGE, function (event:InfographicProfileEvent) {
                if(self._PieGraph.windowSmall != event.data ){
                    self._PieGraph.windowSmall= event.data;
                    self._PieGraph.redoCircles();
                }
            });


            this.onRadioChange();

            $(this._elBtNext).attr("href", this._data.urlNext + "/profile");
            $(this._elBtPrev).attr("href", this._data.urlPrev + "/profile");

            if(this._data.isNext){
                $(this._elBtNext).css({display: 'block'});
            }else{
                $(this._elBtNext).css({display: 'none'});
            }

            if(this._data.isPrev){
                $(this._elBtPrev).css({display: 'block'});
            }else{
                $(this._elBtPrev).css({display: 'none'});
            }
        }

        /**
         *
         * @param id
         */
        show (id: string, year: string): void {

            if(this._profileInfographic){
                this._CircleGraph.hideElements();
                this.destroyProfGraph();
                this._CircleGraph.tooltipReset();
            }
            $(".infographic-profile .infographic-spinner").css("display", "block");
            TweenLite.set($(".infographic-profile .infographic-spinner"),{autoAlpha: 1});

            console.log('----------');
            console.log('show Infographic Profile');
            var event = new InfographicProfileEvent(InfographicProfileEvent.INFOGRAPHIC_PROFILE);
            event.id = id;
            event.caregory = 'profile';
            event.year = year;
            this.dispatchEvent(event);
            this._profileInfographic= true;
        }

        /**
         *
         * @param data
         */
        loadResults (data: any): void {

            console.log('----------');
            console.log('loadResults Infographic Profile.');
            console.log(data);

            this._data= data;
            TweenLite.to($(".infographic-profile .infographic-spinner"), .05,{autoAlpha: 0, delay: 1, onComplete: () =>{
                $(".infographic-profile .infographic-spinner").css("display", "none");
                this._bind();
            }});
        }

        close_hook (d:Q.Deferred<void>):void {

            this._CircleGraph.reverseAnim();

            _.delay(()=>{
                TweenLite.to(this.getPanel(), 0.25,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                    TweenLite.set(this.getPanel(), {top: '-100%'});
                    TweenLite.set(this.target, {css:{display:'none'}});
                    console.log("cerrada prof view");
                    //destrroy infographic
                    this.destroyProfGraph();

                    d.resolve();
                }});
                $(this._elPanel).scrollTop(0);

            },450);

        }

        private insertheaderTitles(): void {

            $(".prof-header h1").text(this._data.title);
            $(".prof-header h4").text(this._data.date);
        }

        private destroyProfGraph(): void {

            this.unbinds();
            this.resetBindings();
            this._CircleGraph.deleteGraph();
            this._PieGraph.deleteGraph();
            this.defaultRadio();
            this._profileInfographic= false;
        }

        private unbinds(): void {
            //$("#prof-category-select")["selectBox"]("destroy");

            $(".prof-graph-audience input").unbind("change");
            $("#infographic-profile .infographic-disclamer").unbind("click");
        }

        loadError(): void{

            console.log('----------');
            console.log('loadError Infographic Profile');
        }

        private onRadioChange(): void {

            var self= this;
            $('.prof-graph-audience input').on('change', function() {

                $(".prof-graph-audience .prof-input-details").css("opacity", "0.3");
                $(".prof-graph-audience circle").css("opacity", "0");

                $(this).parents("li").find(".prof-input-details").css("opacity", "1");
                $(this).parents("li").find("circle").css("opacity", "1");


                self._PieGraph._audienceCounter= $('input[name=profile-audience]:checked', '.prof-graph-audience').val();
                self._PieGraph.updateDataAudience();
            });
        }


        private defaultRadio(): void {

            $(".radio-profile-home").prop("checked", true);
        }

        private knockOut(): void {

            var theOptions= this._data["categories"];

            var item= function(data){
                var self= this;
                self["text"]= data.text;
                self["id"]= data.id;
            }

            function buildData(data){
                var array= [];
                for(var d in data){
                    var thing= new item(
                        {
                            text: data[d].itemName,
                            id: d
                        })
                    array.push(thing);
                }
                return array;
            }

            var viewModel= {
                categories: buildData(theOptions),
                selectedItem: ko.observable(),
                all: $.i18n.translate( "tr-all" )
            }

            ko.applyBindings(viewModel, document.getElementById("prof-category-select"));
        }

        private resetBindings() {
            ko.cleanNode(document.getElementById("prof-category-select"));
        }
    }
}
