/**
 * Created by had on 11/20/14.
 */
/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/InfographicVsView.ts" />

module media.hrratings.commands {

    import ViewManager = core.ViewManager;
    import InfographicVsView = views.InfographicVsView;

    export class OpenInfographicVsCommand {

        mainViewManager: ViewManager = null; //injected

        execute (event) {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenInfographicVsCommand.execute!");
            console.log("ID infographic Vs:", event.itemId);

            if(!this.mainViewManager.isViewOpen(MainViews.InfographicVs) ){
                this.mainViewManager.openView(MainViews.InfographicVs);
            }
            var infographicView: InfographicVsView = <InfographicVsView>this.mainViewManager.getViewById(MainViews.InfographicVs);
            infographicView.show(event.params.itemId, event.params.year);
        }
    }
}
