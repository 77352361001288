
/// <reference path="./../services/HRServices.ts" />
/// <reference path="./../events/ApplicationEvent.ts" />
/// <reference path="./../events/LocaleEvent.ts" />
/// <reference path="./../locale/I18nStore.ts" />
/// <reference path="./../utils/knockout/bindings/i18n.ts" />

module media.hrratings.commands {

    import Services         = media.hrratings.services.HRServices;
    import ApplicationEvent = media.hrratings.events.ApplicationEvent;
    import LocaleEvent      = media.hrratings.events.LocaleEvent;
    import I18nStore        = media.hrratings.locale.I18nStore;

    export class ApplicationStartCommand {

        service     : Services              = null; //injected
        lang        : string                = null; //injected
        api         : string                = null; //injected
        dispatcher  : soma.EventDispatcher  = null; //injected
        i18n        : I18nStore             = null; //injected

        execute () {
            console.log("******************************", this.lang, this.api);

            this.i18n.init(this.lang, this.api)
                .then( () => {
                    this.service.preload(this.lang).then(()=> {
                        utils.knockout.bindings.i18n();
                        this.dispatcher.dispatch(LocaleEvent.LOCALE_READY);
                        this.dispatcher.dispatch(ApplicationEvent.LOADER_COMPLETE);
                    });

                }, () => {console.log("fail i18n")})
                .done();
        }
    }
}