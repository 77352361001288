/**
 * Created by had on 2/25/15.
 */
/**
 * Created by Esteban on 10/24/14.
 */

module media.hrratings.events {

    export class MenuEvents extends createjs.Event {
        static MENU_HIDE:string = "Events.menu_hide";
    }
}