module media.hrratings.infographicVs{

    export interface IGraphic {
        createGraphic():void;
    }

    export class SemiCircleGraph extends createjs.EventDispatcher implements IGraphic{

        public data:any;
        public maxValue: number;
        public actualIndex: number;

        private _version;
        private _containerName:string;
        private _orientation:number;
        private _arrayDatatoShow:Array<any>;
        private _scGWidht:number;
        private _scGHeight:number;
        private _innerRadius:number;
        private _radius:number;
        private _pie;
        private _arc;
        private _stage;
        private _path;
        private _initialArc;

        private _theColors: Array<any>;


        private _thaTitle;
        private _thaLShow;
        private _thaRShow;
        private _thaLControlls;
        private _thaRControlls;
        private _thaVs;
        private _thaGraphCont;
        private _thaFooter;
        private _thaTooltip;




        constructor(public thedata:any, public themaxValue:number, public thecontainerName:string, public theorientation:number){
            super();

            this.data= thedata;
            this.maxValue= themaxValue;
            this._containerName= thecontainerName;
            this._orientation= theorientation;

            this.setup();
        }

        private setup(){
            this._arrayDatatoShow= this.data["shows"];
            this.actualIndex= 0;

            this._theColors= ["#ff4d5b", "#ff884d"];

            this._thaTitle= $(".vs-header");
            this._thaLShow= $(".vs-left-titles-container");
            this._thaRShow= $(".vs-right-titles-container");
            this._thaLControlls= $("#vs-left-controllers");
            this._thaRControlls= $("#vs-right-controllers");
            this._thaVs= $(".vs-complete-graph h4");
            this._thaGraphCont= $(".vs-complete-graph");
            this._thaFooter= $("#infographic-vs .infographic-disclamer");
            this._thaTooltip= $("#infographic-vs .infographic-tooltip");

        }

        public createGraphic(){
            this.tooltipClose();
            console.log("graph Vs created");
            var self= this;
            //$(window).on("resize", function(){
            //    self.setResize();
            //});

            this.checkDevice();
        }

        private setResize(){
            var windowW:number= window.innerWidth;

            if(windowW <= 480 ){
                this.setWidth(280, 35);
            }
            if(windowW > 480 && windowW <= 745){
                this.setWidth(400, 55);
            }
            if(windowW > 745 && windowW <= 1020){
                this.setWidth(300, 45);
            }
            if(windowW > 1020){
                this.setWidth(420, 65);
            }
        }

        private setWidth(width, innerRad){
            if (width != this._scGWidht) {
                this._scGWidht = width;
                this._scGHeight = width;
                this._innerRadius= innerRad;
                this.exitGraph();
                this.resizeGraphic();
            }
        }

        private checkDevice(){
            try {
                document.createEvent("TouchEvent");
                this._version= "device";
                this.deviceVersion();
                return true;
            } catch (e) {
                this._version= "desktop";
                this.desktopVersion();
                return false;
            }
        }

        private deviceVersion(){
            //alert("wiiiiuu");
            this.setMeasures();
            this.insertTitles(this.actualIndex);
            this.setStageNumbers();
            this.setPie();
            this.setArc();
            this.setInitialArc();
            this.setStage();
            this.insertLineCircles();
            this.setPath(this._arc);
            this.insertLinesPaths();


            if(this._orientation=== 0){

                this._tlEntrance.play();
            }

        }

        private desktopVersion(){
            this.setMeasures();
            this.insertTitles(this.actualIndex);
            this.setStageNumbers();
            this.setPie();
            this.setArc();
            this.setInitialArc();
            this.setStage();
            this.insertLineCircles();
            this.setPath(this._initialArc);
            this.insertLinesPaths();

            this.entranceAnim();

        }

        private resizeGraphic(){
            this.setStageNumbers();
            this.setArc();
            this.setInitialArc();
            this.setStage();
            this.insertLineCircles();
            this.setPath(this._arc);
            this.insertLinesPaths();
        }

        private reDoGraphic(){
            this.setMeasures();
            this.insertTitles(this.actualIndex);
            this.setStageNumbers();
            this.setPie();
            this.setArc();
            this.setInitialArc();
            this.setStage();
            this.insertLineCircles();
            this.setPath(this._initialArc);
            this.insertLinesPaths();
        }

        private tooltipClose(){
            var self= this;
            $("body").on("click", function(){
                TweenLite.to(self._thaTooltip, .1, {autoAlpha: '0', "display": "none"});
                //TweenLite.set(this.getPanel(), {top: '-100%'});
            })
        }

        private setMeasures(){

            this._scGWidht= 420;
            this._scGHeight= 420;
            this._innerRadius= 65;

            //var windowW:number= window.innerWidth;
            //
            //if(windowW <= 480 ){
            //    this._scGWidht= 280;
            //    this._scGHeight= 280;
            //    this._innerRadius= 35;
            //}if(windowW > 480 && windowW <= 745){
            //    this._scGWidht= 400;
            //    this._scGHeight= 400;
            //    this._innerRadius= 55;
            //}if(windowW > 745 && windowW <= 1020){
            //    this._scGWidht= 300;
            //    this._scGHeight= 300;
            //    this._innerRadius= 45;
            //}if(windowW > 1020){
            //    this._scGWidht= 420;
            //    this._scGHeight= 420;
            //    this._innerRadius= 65;
            //}

        }

        private setStageNumbers(){
            this._radius= Math.min(this._scGHeight, this._scGWidht)/2;

        }

        private setPie(){
            var start;
            var end;
            if(this._orientation== 0){
                start=  -180 * (Math.PI/180);
                end= 0 * (Math.PI/180);
            }if(this._orientation== 1){
                start= 0 * (Math.PI/180);
                end= 180 * (Math.PI/180);
            }
            this._pie= d3.layout.pie()
                .value(function(d:any) { return 1})
                .sort(null)
                .startAngle(start)
                .endAngle(end);
        }


        private setArc(){
            var self = this;
            this._arc = d3.svg.arc()
                .innerRadius(self._innerRadius)
                .outerRadius(function (d) {
                    return (self._radius - self._innerRadius) *
                        (d.data.value / self.maxValue) +
                        self._innerRadius;
                });
        }

        private setInitialArc(){
            var self = this;
            this._initialArc = d3.svg.arc()
                .innerRadius(self._innerRadius)
                .outerRadius(self._innerRadius);
        }

        private setStage() {
            var width;
            if(this._orientation== 0){
                width= this._scGWidht/2
            }if(this._orientation== 1){
                width= 0;
            }
            this._stage = d3.select('#' + this._containerName).append('svg')
                .attr('class', "semiCircle-graph")
                .attr('width', this._scGWidht/2)
                .attr('height', this._scGHeight)
                .append('g')
                .attr("transform", "translate(" + width + "," + this._scGHeight / 2 + ")");
        }

        private setPath(arc){
            var self = this;
            var index;

            var tempStage= this._stage.append("g")
            this._path = tempStage.selectAll('.vs-solid-arc'+ this._orientation)
                .data(this._pie(this.data))
                .enter().append('path')
                .attr("fill", "#c0c0c0")
                .attr('class', "vs-solid-arc"+ this._orientation)
                .attr('d', arc)
                .on("click", this.onClick.bind(this))
                .on("mouseover", this.onMouseOver.bind(this))
                .on("mouseout", this.onMouseOut.bind(this))

            d3.select(".vs-solid-arc"+ this._orientation+":nth-child("+ (this.actualIndex+1) +")")
                .attr('fill', function(){
                    return self._theColors[self._orientation]
                })

        }

        private pathTrans(arc, delay){
            this._path.transition()
                .attr("d", arc)
                //.ease("in-out")
                .delay(function (d, i) {
                    return delay + i * 30
                })
        }


        private entranceAnim(){
            if(this._orientation=== 0){

                this._tlEntrance.play();
            }
            this.pathTrans(this._arc, 100);

        }

        private changeAnim(event){

            if(this._version== "desktop"){
                if(event=="salida"){
                    this._tlChange.play();
                    this.pathTrans(this._initialArc, 0);
                    _.delay(()=>{
                        this.exitGraph();
                    },500);

                }else{
                    this.reDoGraphic();
                    this.pathTrans(this._arc, 0);
                    console.log("recreated")
                    _.delay(()=>{
                        this._tlChange.reverse();
                    },10);
                }
            }else{
                this.exitGraph();
                this.resizeGraphic();
            }
        }

        public updateGraphic(){

            if(this._version=="desktop"){
                this.changeAnim("salida");
                console.log("ivan")
                _.delay(()=>{
                    this.changeAnim("entrada");
                },500);
            }else{
                this.exitGraph();
                this.createGraphic();
            }
        }

        private onClick(d, i){
            this.actualIndex= i;
            this.changeActive(this.actualIndex);
            this.insertTitles(this.actualIndex);

        }

        private onMouseOver(d, i){
            this.changeActive(i);
            this.insertTitles(i);
        }

        private onMouseOut(){
            this.changeActive(this.actualIndex);
            this.insertTitles(this.actualIndex);
        }

        private changeActive(index){
            var self= this;
            if(this._version== "desktop"){

                d3.selectAll(".vs-solid-arc"+ this._orientation)
                    .transition()
                    .attr("fill", "#c0c0c0")

                d3.select(".vs-solid-arc"+ this._orientation+":nth-child("+ (this.actualIndex+1) +")")
                    .transition()
                    .attr('fill', function(){
                        return self._theColors[self._orientation]
                    })

                d3.select(".vs-solid-arc"+ this._orientation+":nth-child("+ (index+1) +")")
                    .transition()
                    .attr('fill', function(){
                        return self._theColors[self._orientation]
                    })
            }else{
                d3.selectAll(".vs-solid-arc"+ this._orientation)
                    .attr("fill", "#c0c0c0")

                d3.select(".vs-solid-arc"+ this._orientation+":nth-child("+ (this.actualIndex+1) +")")
                    .attr('fill', function(){
                        return self._theColors[self._orientation]
                    })

                d3.select(".vs-solid-arc"+ this._orientation+":nth-child("+ (index+1) +")")
                    .attr('fill', function(){
                        return self._theColors[self._orientation]
                    })
            }


        }

        private exitGraph(){
            var ori;
            if(this._orientation== 0){
                ori= "left"
            }else{
                ori= "right"
            }
            d3.select("#vs-"+ ori +"-graph-container .semiCircle-graph").remove();
        }

        private formatFloat(num: number) {
            var sFormat = num.toFixed(5);
            var dotIndex = sFormat.toString().indexOf('.');
            return sFormat.substr(0, dotIndex + 2);
        }

        private insertTitles(index){
            var word;
            if(this._orientation== 0){
                word= "left";
            }else{
                word= "right";
            }

            var showName= this.data[index]["itemName"];
            var shortName;

            if(showName.length > 39){
                shortName= showName.substring(0, 39);
                shortName= shortName + "...";
            }else{
                shortName= this.data[index]["itemName"];
            }
            $(".vs-"+word+"-titles-container .vs-show-title").text(shortName);
            $(".vs-"+word+"-titles-container .vs-show-details").text(this.data[index]["details"]);

            var original = this.data[index]["value"];
            $(".vs-"+word+"-titles-container .vs-show-percent").text(this.formatFloat(original));
            $(".vs-"+word+"-titles-container .vs-show-channel").text(this.data[index]["channel"]);
            $(".vs-"+word+"-titles-container .vs-show-tv").text(this.data[index]["emision"]);

            //$(".vs-"+word+"-titles-container .vs-show-title").css("color", this._theColors[this._orientation]);
            $(".vs-"+word+"-titles-container .vs-show-percent").css("color", this._theColors[this._orientation]);

        }

        private insertLineCircles(){
            var start;
            var end;
            var width;
            if(this._orientation== 0){
                start=  -180 * (Math.PI/180);
                end= 0 * (Math.PI/180);
                width= this._scGWidht/2;
            }if(this._orientation== 1){
                start= 0 * (Math.PI/180);
                end= 180 * (Math.PI/180);
                width= 0;
            }

            var stageLines= this._stage.append("g");

            for(var i= this._innerRadius; i< this._scGWidht/2; i+= ((this._scGWidht/2-this._innerRadius))/8){
                var arco= d3.svg.arc()
                    .innerRadius(this._innerRadius)
                    .outerRadius(i)
                    .startAngle(start)
                    .endAngle(end)

                stageLines.append("path")
                    .attr("fill", "none")
                    .attr("stroke", "#dbdbdb")
                    .attr("stroke-width", 1)
                    .attr("d", arco);
            }
        }

        private insertLinesPaths(){

            var stageLines= this._stage.append("g");
            var arco= d3.svg.arc()
                .innerRadius(this._innerRadius)
                .outerRadius(this._radius)

            stageLines.selectAll('.vs-lines-arc'+ this._orientation)
                .data(this._pie(this.data))
                .enter().append('path')
                .attr("fill", "none")
                .attr("stroke", "#dbdbdb")
                .attr("stroke-width", 1)
                .attr("d", arco);

        }

        public destroyGraphic(){
            this.pathTrans(this._initialArc, 0);
            this.unbinds();
            d3.selectAll(".semiCircle-graph").remove();
        }


        private unbinds(){
            //$(window).unbind("resize");
            $("body").unbind("click");
        }

        public reverseAnim(){
            console.log(this._orientation, "·¨¨¨¨¨¨··¨¨¨¨···¨¨¨¨¨¨¨¨···")
            this._tlExit();
        }

        private _tlExit(){
            TweenLite.to([this._thaTitle,
                this._thaLShow,
                this._thaRShow,
                this._thaLControlls,
                this._thaRControlls,
                this._thaGraphCont,
                this._thaVs,
                this._thaFooter], 0.25, {autoAlpha:0} );
                TweenLite.to(this._thaTooltip, .05, {autoAlpha: 0, display: "block"} );

        }

        private get _tlEntrance (): TimelineLite {

            if(this.$tlEntrance == null){
                this.$tlEntrance = new TimelineLite({autoStart: false, ease:Cubic.easeOut});

                this.$tlEntrance.to(this._thaGraphCont, .05, {autoAlpha: 1} );
                this.$tlEntrance.to(this._thaLShow, .1, {autoAlpha: 1, marginRight: "0"}, ".6" );
                this.$tlEntrance.to(this._thaRShow, .1, {autoAlpha: 1, marginLeft: "0"}, "-=0.1" );
                this.$tlEntrance.to(this._thaVs, .1, {autoAlpha: 1}, "-=0.1" );
                this.$tlEntrance.to(this._thaLControlls, .1, {autoAlpha: 1} );
                this.$tlEntrance.to(this._thaRControlls, .1, {autoAlpha: 1}, "-=0.1" );
                this.$tlEntrance.to(this._thaTitle, .1, {autoAlpha: 1});
                this.$tlEntrance.to(this._thaFooter, .1, {autoAlpha: 1} ).call(()=>{
                    TweenLite.to(this._thaTooltip, .05, {autoAlpha: .8, delay: .3, display: "block"} );
                })
                //this.$tlEntrance.to(this._thaTooltip, .05, {autoAlpha: .8, delay: .3, display: "block"} );

            }
            return this.$tlEntrance;
        }


        private get _tlChange (): TimelineLite {

            if(this.$tlChange == null){
                this.$tlChange = new TimelineLite({autoStart: false, ease:Cubic.easeOut});

                this.$tlChange.fromTo(this._thaLShow, .5,{autoAlpha: 1}, {autoAlpha: 0, marginRight: "0"} );
                this.$tlChange.fromTo(this._thaRShow, .5, {autoAlpha: 1}, {autoAlpha: 0, marginLeft: "0"}, "-=.5" );

            }
            return this.$tlChange;
        }


        private $tlChange: TimelineLite = null;
        private $tlEntrance: TimelineLite = null;

    }
}