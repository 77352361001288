/**
 * i18n.ts
 * Created by raul on 10/23/14.
 */

module media.hrratings.utils.knockout.bindings {
    export function i18n ():void {

        ko.bindingHandlers['i18n'] = {
            update: function (element, valueAccessor, allBindings) {
                var key = ko.unwrap(valueAccessor()),
                    options = ko.toJS(allBindings.get('i18n-options') || {}),
                    translation = $.i18n.t(key, options);
                
                // Check whether the translation contains markup
                if (translation.match(/<(\w+)((?:\s+\w+(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*(\/?)>/)) {
                    $(element).html(translation);
                } else {
                    // Check whether the translation contains HTML entities
                    if (translation.match(/&(?:[a-z]+|#x?\d+);/gi)) {
                        $(element).html(translation);
                    } else {
                        $(element).text(translation);
                    }
                }
            }
        };
    }
}