/**
 * AbstractServicesTab
 * Created by Esteban on 11/27/14.
 */

///<reference path="AbstractView.ts"/>

module media.hrratings.views {

    export class AbstractServicesTab extends AbstractView {

        public color: string;
        public elPanel: HTMLElement;

        private _elHalf                : HTMLElement;
        private _elAside                : HTMLElement;

        private _elTitle                : HTMLElement;
        private _elSubtitle             : HTMLElement;
        private _elBlock1               : HTMLElement;

        private _elBlock2               : JQuery;

        constructor(_color: string, _elPanel: HTMLElement, _target: HTMLElement){

            super(_target);
            this.color = _color;
            this.elPanel = _elPanel;

            this._bind();
        }

        private _bind (): void {

            this._elHalf               = $(this.target).find('.down-half').get(0);
            this._elAside               = $(this.target).find('aside').get(0);

            this._elTitle               = $(this.target).find('header h1').get(0);
            this._elSubtitle            = $(this.target).find('header h2').get(0);
            this._elBlock1              = $(this.target).find('.second').get(0);

            this._elBlock2              = $(this.target).find('.third');
        }

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>):void {

            this._tlDialogClose.stop();
            this._tlDialogOpen.play(0);
            TweenLite.set(this.target,{css:{display:'block'}});
            TweenLite.to(this.elPanel, 0.25, {backgroundColor: this.color});
            setTimeout( () => { d.resolve(); }, 900);
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {

            this._tlDialogOpen.stop();
            this._tlDialogClose.play(0);
            setTimeout( () => {
                TweenLite.set(this.target,{css:{display:'none'}});
                $(this.target).scrollTop(0);
                d.resolve();
            }, 250);
        }

        private get _tlDialogOpen (): TimelineLite {

            if(this.$tlDialogOpen == null){
                this.$tlDialogOpen = new TimelineLite({autoStart: false, ease: Cubic.easeInOut});

                this.$tlDialogOpen.to(this._elTitle     , 0.25, {autoAlpha: 1});
                this.$tlDialogOpen.to(this._elSubtitle  , 0.25, {autoAlpha: 1});

                this.$tlDialogOpen.to(this._elBlock1    , 0.25, {autoAlpha: 1});

                this.$tlDialogOpen.to(this._elHalf      , 0.25, {autoAlpha: 1});
                this.$tlDialogOpen.to(this._elAside     , 0.25, {autoAlpha: 1}, "-.25");

                this.$tlDialogOpen.to(this._elBlock2    , 0.25, {autoAlpha: 1}, "-=.25");

            }
            return this.$tlDialogOpen;
        }

        private get _tlDialogClose(): TimelineLite{
            if(this.$tlDialogClose == null){
                this.$tlDialogClose = new TimelineLite({autoStart: false, ease: Cubic.easeInOut});

                this.$tlDialogClose.to([this._elTitle, this._elSubtitle,this._elBlock1, this._elBlock2, this._elAside,this._elHalf], 0.25, {autoAlpha: 0});
            }
            return this.$tlDialogClose;
        }

        private $tlDialogOpen: TimelineLite = null;
        private $tlDialogClose: TimelineLite = null;
    }
}