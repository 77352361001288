/**
 * Created by had on 3/3/15.
 */

/// <reference path="./../AbstractServicesTab.ts" />

module media.hrratings.views.methodology {

    import AbstractServicesTab = media.hrratings.views.AbstractServicesTab;
    export class Tam5500 extends AbstractServicesTab {}
}
