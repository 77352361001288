/**
 * LegalsMediator
 * Created by Esteban on 1/5/15.
 */

/// <reference path="../views/LegalsWebView.ts" />
/// <reference path="../events/ApplicationEvent.ts" />

module media.hrratings.mediators {

    import EventDispatcher  = soma.EventDispatcher;
    import LegalsWebView    = media.hrratings.views.LegalsWebView;
    import LocaleEvent      = media.hrratings.events.LocaleEvent;

    export class LegalsWebViewMediator {

        static inject:string[] = ["target", "dispatcher"];

        constructor(target:LegalsWebView, dispatcher:EventDispatcher) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });


        }
    }
}