/**
 * Created by Esteban on 10/27/14.
 */

module media.hrratings.events {

    export class ArticleEvent extends createjs.Event {
        static ARTICLE: string = "Events.article";

        public id: string;
        constructor (id: string) {
            super(id,false,false);
        }
    }
}