/**
 * Created by Esteban on 10/28/14.
 */


module media.hrratings.events {

    export class InfographicProfileEvent extends createjs.Event {
        static CLICK = "Events.onmouseclick";
        static ONCOMPLETE = "Events.oncomplete";
        static MOUSEOVER = "Events.onmouseover";
        static MOUSEOUT = "Events.onmouseout";
        static CHANGE = "Events.onchange";
        static WIDHTCHANGE = "Events.widhtchange";
        static INFOGRAPHIC_PROFILE:string = "Events.infographic_profile";

        public id: string;
        public year: string;
        public caregory: string;
        constructor (type: string) {
            super(type,false,false);
        }
    }
}