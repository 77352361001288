/**
 * Created by raul on 10/13/14.
 */

module media.hrratings.entities {

    export class VOCollection<T> implements IVOCollection<T> {

        data:T[] = [];

        each(it:(value: T, index: number, list: T[]) => void):void {
            _.each(this.data, it);
        }

        getVoByIndex(index:number):T {
            return this.data[index];
        }

        addVo(value:T):void {
            this.data.push(value);
        }

        mapVOFromJSON(data:any[], c: {new(): T; }): T[] {
            this.data = [];
            _.each(data, (elem) => {
                this.data.push($.extend(new c(), elem));
            });

            return this.data;
        }
    }
}