/**
 * Created by Esteban on 10/14/14.
 */

/// <reference path="AbstractMenuPanel.ts" />

module media.hrratings.views.menu {

    export class ServicesPanel extends AbstractMenuPanel {}
}
