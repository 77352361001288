/**
 * Created by Ivan on 10/16/14.
 */

/// <reference path="../services/HRServices.ts" />
/// <reference path="../events/RoutesEvents.ts" />
/// <reference path="../events/FilterEvents.ts" />
/// <reference path="../events/LocaleEvent.ts" />
/// <reference path="../views/HomeView.ts" />

module media.hrratings.mediators {

    import EventDispatcher = soma.EventDispatcher;
    import HRServices = media.hrratings.services.HRServices;
    import RoutesEvents = media.hrratings.events.RoutesEvents;
    import HomeEvents = media.hrratings.events.HomeEvents;
    import HomeView = media.hrratings.views.HomeView;
    import LocaleEvent = media.hrratings.events.LocaleEvent;

    export class HomeMediator {

        static inject:string[] = ["target", "dispatcher", "service", "lang"];

        constructor(target:HomeView, dispatcher:EventDispatcher, service: HRServices, lang:string) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
                target.initialize(lang);
            });
        }
    }
}