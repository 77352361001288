
/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../events/ApplicationEvent.ts" />

module media.hrratings.commands {
    
    import ViewManager      = media.hrratings.core.ViewManager;
    import ApplicationEvent = media.hrratings.events.ApplicationEvent;

    export class LoaderCompleteCommand {

        mainViewManager:ViewManager = null; //injected
        dispatcher:soma.EventDispatcher = null; //injected

        execute () {
            this.mainViewManager.openView(MainViews.Home)
            .then(() => {
                this.dispatcher.dispatch(ApplicationEvent.STARTUP_COMPLETE)
            }).done();
        }
    }
}