/**
 * Created by had on 3/3/15.
 */

/// <reference path="../services/HRServices.ts" />
/// <reference path="../views/MethodologyView.ts" />
/// <reference path="../events/ApplicationEvent.ts" />
/// <reference path="../events/MethodologyEvents.ts" />

module media.hrratings.mediators {

    import EventDispatcher      = soma.EventDispatcher;
    import LocaleEvent          = media.hrratings.events.LocaleEvent;
    import MethodologyEvents    = media.hrratings.events.MethodologyEvents;
    import MethodologyView      = media.hrratings.views.MethodologyView;
    import ApplicationEvent     = media.hrratings.events.ApplicationEvent;
    import HRServices           = media.hrratings.services.HRServices;

    export class MethodologyViewMediator {

        static inject: string[] = ["target", "dispatcher", "service", "lang"];

        constructor(target: MethodologyView, dispatcher: EventDispatcher, service: HRServices, lang: string) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });

            target.addEventListener(MethodologyEvents.METHODOLOGY_DOCUMENTS, function () {
                service.getMethodologyDocs(lang)
                    .done( (data) => {target.setupDocs(data)});
            });
        }
    }
}