/**
 * InfographicsMediator
 * Created by Esteban on 12/2/14.
 */

/// <reference path="../views/InfographicsView.ts" />
/// <reference path="../events/LocaleEvent.ts" />
/// <reference path="../events/FilterEvents.ts" />

module media.hrratings.mediators {

    import EventDispatcher  = soma.EventDispatcher;
    import InfographicsView = media.hrratings.views.InfographicsView;
    import LocaleEvent      = media.hrratings.events.LocaleEvent;
    import RoutesEvents      = media.hrratings.events.RoutesEvents;

    export class InfographicsViewMediator {

        static inject: string[] = ["target", "dispatcher", "lang"];

        constructor(target: InfographicsView, dispatcher: EventDispatcher, lang: string) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.initialize(lang);
            });

            dispatcher.addEventListener(RoutesEvents.INFOGRAPHICS_ROUTE, (e: any) => {
                if (e.params.year && e.params.week) {
                    target.selectTriad(e.params.year, e.params.week);
                } else {
                    target.selectLatestResults();
                }
            });
        }
    }
}
