/**
 * ServicesViewMediator
 * Created by Esteban on 12/1/14.
 */

/// <reference path="../views/ServicesView.ts" />
/// <reference path="../events/ApplicationEvent.ts" />

module media.hrratings.mediators {

    import EventDispatcher = soma.EventDispatcher;
    import LocaleEvent = media.hrratings.events.LocaleEvent;
    import ServicesView = media.hrratings.views.ServicesView;
    import ApplicationEvent = media.hrratings.events.ApplicationEvent;

    export class ServicesViewMediator {

        static inject: string[] = ["target", "dispatcher"];

        constructor(target: ServicesView, dispatcher: EventDispatcher) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });
        }
    }
}