/**
 * Created by had on 11/20/14.
 */

module media.hrratings.events {

    export class InfographicVsEvent extends createjs.Event {

        static INFOGRAPHIC_VS:string = "Events.infographic_vs";
        static REQUEST_PERIOD:string = "Events.REQUEST_PERIOD";

        fill:string;


        public info:string;

        public id: string;
        public year: string;
        public category: string;
        constructor (type: string) {
            super(type,false,false);
        }
    }
}