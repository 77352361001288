/**
 * Created by Esteban on 10/20/14.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./AbstractDialogView.ts" />
/// <reference path="./about/AboutUs.ts" />
/// <reference path="./about/StrategicApproach.ts" />
/// <reference path="./about/OurPhilosophy.ts" />
/// <reference path="./about/Board.ts" />

/// <reference path="../events/MenuEvents.ts" />

module media.hrratings.views {

    import ViewManager          = core.ViewManager;
    import StrategicApproach    = about.StrategicApproach;
    import OurPhilosophy        = about.OurPhilosophy;
    import Board                = about.Board;
    import MenuEvents           = events.MenuEvents;
    import clientInfo = media.hrratings.utils.browser.ClientInfo;

    export enum MenuAbout {
        StrategicApproach,
        OurPhilosophy,
        Board
    }

    export class AboutView extends AbstractDialogView {

        private _elPanel: HTMLElement;
        private dispatcher: soma.EventDispatcher = null; //injected

        constructor(_target: HTMLElement) {

            super(_target);

            this._cachedElements();
            this._setup();
        }

        private _setup(): void {

            this._tabManager.openView(MenuAbout.StrategicApproach);
            this._tabManager.closeCurrentView();
        }

        private _cachedElements(): void {

            this._elPanel = $(this.target).find('#about').get(0);
        }

        getPanel (): HTMLElement {

            return this._elPanel;
        }

        setupBindings(): void {

            ko.applyBindings(this, this._elPanel);
        }

        /**
         *
         * @param id
         */
        showTab (id: string): void {

            switch (id) {
                case 'strategic-focus'   : { this._tabManager.openView(MenuAbout.StrategicApproach ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
                case 'our-philosophy'    : { this._tabManager.openView(MenuAbout.OurPhilosophy     ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
                case 'board'             : { this._tabManager.openView(MenuAbout.Board             ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
                default                  : { this._tabManager.openView(MenuAbout.StrategicApproach           ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
            }
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {

            this._tabManager.closeCurrentView();

            TweenLite.to(this.getPanel(), 0.15,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                TweenLite.set(this.getPanel(), {autoAlpha: 0, top: '-100%'});
                TweenLite.set(this.target, {css:{display:'none'}});

                this._tabManager.reset();
                d.resolve();
            }});

            $(this._elPanel).scrollTop(0);
        }

        /**
         *
         * @returns {ViewManager}
         * @private
         */
        private get _tabManager (): ViewManager {

            if (this.$tabManager == null) {

                this.$tabManager = new ViewManager();
                this.$tabManager.addView(MenuAbout.StrategicApproach,   new StrategicApproach   ( $(this._elPanel).find(".tab-1").get(0) ));
                this.$tabManager.addView(MenuAbout.OurPhilosophy,       new OurPhilosophy       ( $(this._elPanel).find(".tab-2").get(0) ));
                this.$tabManager.addView(MenuAbout.Board,               new Board               ( $(this._elPanel).find(".tab-3").get(0) ));
            }
            return this.$tabManager;
        }

        private $tabManager: ViewManager = null;
    }
}