/**
 * Created by Esteban on 10/27/14.
 */

/// <reference path="../views/ArticleView.ts" />
/// <reference path="../services/HRServices.ts" />
/// <reference path="../events/ArticleEvent.ts" />
/// <reference path="../events/LocaleEvent.ts" />
/// <reference path="../vo/ArticleVO.ts" />

module media.hrratings.mediators {

    import ArticleView = media.hrratings.views.ArticleView;
    import HRServices = media.hrratings.services.HRServices;
    import EventDispatcher = soma.EventDispatcher;
    import ArticleEvent = media.hrratings.events.ArticleEvent;
    import ArticleVO = media.hrratings.vo.ArticleVO;

    export class ArticleViewMediator {

        static inject:string[] = ["target", "dispatcher", "service"];

        constructor(target: ArticleView, dispatcher: EventDispatcher, service: HRServices) {

            //target.addEventListener(ArticleEvent.ARTICLE, (event: ArticleEvent) => {
            //
            //    service.getArticle(event.id)
            //        .done( (data:any) => {
            //            target.loadResults(ArticleVO.fromRawData(data));
            //        })
            //        .fail( () =>{
            //            target.loadError();
            //        });
            //});



        }
    }
}
