/**
 * Created by Esteban on 10/14/14.
 */

module media.hrratings.services {

    export class HRServices {

        private _PATH = 'http://api.hrratings.media/api/';

        static REQUEST_LIMIT = 1;
        static pendingRequests = [];
        static activeRequests = [];
        static cachedRequests = {};

        static getHashFromRequest(request: string) {
            var hash = 0, i, chr, len;
            if (request.length == 0) return hash;
            for (i = 0, len = request.length; i < len; i++) {
                chr   = request.charCodeAt(i);
                hash  = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
            }
            return hash;
        }

        static processPendingRequests() {
            var self = this;
            if (self.activeRequests.length < self.REQUEST_LIMIT) {
                var nextRequest = self.pendingRequests.pop();
                if (nextRequest) {
                    self.activeRequests.push(nextRequest);
                    var cachedResponse = this.cachedRequests[nextRequest.id];
                    if (cachedResponse) {
                        nextRequest.deferred.resolve(cachedResponse);
                        self.activeRequests.splice(self.activeRequests.indexOf(nextRequest), 1);
                        self.processPendingRequests();
                    } else {
                        $.ajax(nextRequest.settings).done((data)=> {
                            self.activeRequests.splice(self.activeRequests.indexOf(nextRequest), 1);
                            nextRequest.deferred.resolve(data);
                            this.cachedRequests[nextRequest.id] = data;
                            self.processPendingRequests();
                        }).always(()=> {
                            console.log('done!');
                        });
                    }
                }
            }
        }

        static addRequest(requestSettings:any):any {
            var def = jQuery.Deferred();
            this.pendingRequests.push({
                id: this.getHashFromRequest(JSON.stringify(requestSettings)),
                url: requestSettings.url,
                settings: requestSettings,
                deferred: def,
            });
            this.processPendingRequests();
            return def;
        }

        sendContact(language:string, name:string, email:string, company:string, subject:string, message:string):JQueryXHR {
            var _data = {
                'name': name,
                'email': email,
                'company': company,
                'subject': subject,
                'message': message
            };

            return HRServices.addRequest({
                type: 'POST',
                dataType: 'json',
                url: this._PATH + 'contacto/mensaje',
                data: _data
            });
        }

        sendNewsletter(email:string, lang):JQueryXHR {
            return HRServices.addRequest({
                url: this._PATH + 'newsletter/suscribir',
                type: 'POST',
                data: {'lang': lang, 'email': email},
                dataType: 'json'
            });
        }

        getMethodologyDocs(lang:string):JQueryXHR {
            return HRServices.addRequest({
                url: this._PATH + 'metodologias',
                type: 'POST',
                data: {'lang': lang},
                dataType: 'json'
            });
        }


        getInfographic(id:string, category:string, year:string, lang:string = 'es'):JQueryXHR {
            return HRServices.addRequest({
                url: this._PATH + "infografias",
                type: 'POST',
                dataType: 'json',
                data: {lang: lang, year: year, id: id, type: category}
            });
        }

        getVSInfographicAvailablePeriods(lang:string = 'es'):JQueryXHR {
            return HRServices.addRequest({
                url: this._PATH + 'infografias/periodos',
                type: 'POST',
                dataType: 'json',
                data: {lang: lang}
            });
            //return $.getJSON( this._PATH + 'infografias/periodos');
        }

        getVSInfographicPeriod(id:string, category:string, year:string, lang:string = 'es'):JQueryXHR {
            return HRServices.addRequest({
                url: this._PATH + 'infografias',
                type: 'POST',
                dataType: 'json',
                data: {lang: lang, year: '' + year + '', id: id, type: category}
            });
        }

        public getChartItems(lang:string):JQueryXHR {
            return HRServices.addRequest({
                type: 'POST',
                dataType: 'json',
                url: this._PATH + 'particulas',
                data: {lang: lang}
            });

        }

        public getWeeklyItems(lang:string):JQueryXHR {
            return HRServices.addRequest({
                type: 'POST',
                dataType: 'json',
                url: this._PATH + 'weeklys',
                data: {lang: lang}
            });

        }


        public getPressItems(lang:string): JQueryXHR {
            return HRServices.addRequest({
                type: 'POST',
                dataType: 'json',
                url: this._PATH + 'comunicados',
                data: {lang: lang},
            });
        }

        public preload(lang: string):Q.Promise<boolean> {
            var d = Q.defer<boolean>();
            var self = this;
            console.log('preloading...');


            $.when(
                this.getMethodologyDocs(lang),
                self.getPressItems(lang),
                self.getWeeklyItems(lang),
                self.getChartItems(lang),
                self.getVSInfographicAvailablePeriods(lang)
            ).then(()=> {
                console.log('finished!');
                d.resolve(true);
            });


            return d.promise;
        }

    }

}
