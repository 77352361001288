/**
 * Created by Esteban on 10/21/14.
 */

/// <reference path="./../AbstractServicesTab.ts" />

module media.hrratings.views.services {

    import AbstractServicesTab = media.hrratings.views.AbstractServicesTab;
    export class ADSpendMonitoring extends AbstractServicesTab {}
}
