/**
 * Created by Esteban on 10/28/14.
 */

/// <reference path="./AbstractDialogView.ts" />
/// <reference path="./infographicRanking/BarsGraph.ts" />
/// <reference path="../events/InfographicRankingEvent.ts" />
/// <reference path="../events/FilterEvents.ts" />

module media.hrratings.views {

    import ViewManager = core.ViewManager;
    import BarsGraph = infographicRanking.BarsGraph;
    import InfographicRankingEvent = events.InfographicRankingEvent;
    import PlaygroundEvents = events.HomeEvents;


    export class InfographicRankingView extends AbstractDialogView{

        private _elPanel: HTMLElement;
        private _elBtPrev: HTMLElement;
        private _elBtNext: HTMLElement;
        private _elClose: HTMLElement;
        private _rankingInfographic: boolean;

        public _data: any;
        public containerName: string;

        private _barsGraph: BarsGraph;



        //private _barsGraph: RankingGraphBars;

        constructor(_target:HTMLElement) {

            super(_target);

            this._setup();
        }

        private _setup(): void {
            this.containerName = 'rank-bar-graph-container';

            this._cacheElements();
            this._bindNav();
        }

        getPanel (): HTMLElement {

            return this._elPanel;
        }

        setupBindings(): void {

            //ko.applyBindings(this, $(this._elPanel).find('#prof-graph-audience-vs').get(0));

            ko.applyBindings(this, $(this._elPanel).find('#rank-controllers .rank-audience-radio').get(0));
            ko.applyBindings(this, $(this._elPanel).find('#rank-category-select h4').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.rank-header').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.infographic-footer').get(0));
            ko.applyBindings(this, $(this._elPanel).find('#rank-controllers .infographic-tooltip').get(0));
            ko.applyBindings(this, $(this._elPanel).find('.rank-bar-graph-titles').get(0));
            ko.applyBindings(this, this._elClose);
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {

            this._barsGraph.reverseAnim();
            _.delay(()=>{
                TweenLite.to(this.getPanel(), 0.25,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                    TweenLite.set(this.getPanel(), {top: '-100%'});
                    TweenLite.set(this.target, {css:{display:'none'}});
                    console.log("cerrada rank view");
                    //destrroy infographic
                    this.destroyRankGraph();

                    d.resolve();
                }});
                $(this._elPanel).scrollTop(0);
            },450);


        }

        private destroyRankGraph (){
            this.unbinds();

            this.resetBindings();
            this._barsGraph.destroyBarGraph();
            this.deleteMainTittle();
            this._data= null;
            this._rankingInfographic= false;
            //document.getElementById("rank-radio-btn-a")["checked"] = true;
        }

        private unbinds(){
            $("#rank-category-select")["selectBox"]("destroy");
            $(".rank-audience-radio")["selectBox"]("destroy");

            $(".rank-category-controll").unbind("change");
            $(".rank-pie-graph-audience input").unbind("change");
            $("#infographic-ranking .infographic-disclamer").unbind("click");
        }

        private _cacheElements (): void {

            this._elPanel = $(this.target).find('#infographic-ranking').get(0);

            this._elClose = $(this._elPanel).find('.bt-close-dialog').get(0);
            this._elBtNext = $(this._elPanel).find('.infographic-navigation .bt-next').get(0);
            this._elBtPrev = $(this._elPanel).find('.infographic-navigation .bt-prev').get(0);
        }

        private _bindNav (): void {
            $(this._elClose).on('click', (e) => {
                var loc = location.hash.toString().match(/.*Week[0-9]*/);
                location.hash = loc[0];
                e.preventDefault();
            });
        }

        private _bind (): void {

            this._barsGraph = new BarsGraph(this._data, this.containerName);

            this.insertMainTittle();


            this.onRadioChange();

            this.knockOut();


            $("#rank-category-select")["selectBox"]();
            $(".rank-audience-radio")["selectBox"]();

            if(this._data["categories"][0]["data"][0]["personValue"]== 0){
                //$(".rank-audience-radio")
                document.getElementById("rank-audience-select").disabled = true;
                $(".rank-audience-radio").addClass("select-disable");
            }else{
                document.getElementById("rank-audience-select").disabled = false;
                $(".rank-audience-radio").removeClass("select-disable");
            }

            $(this._elBtNext).attr("href", this._data.urlNext + "/ranking");
            $(this._elBtPrev).attr("href", this._data.urlPrev + "/ranking");

            if(this._data.isNext){
                $(this._elBtNext).css({display: 'block'});
            }else{
                $(this._elBtNext).css({display: 'none'});
            }

            if(this._data.isPrev){
                $(this._elBtPrev).css({display: 'block'});
            }else{
                $(this._elBtPrev).css({display: 'none'});
            }


            //console.log('options!',$( ".rank-category-controll option").last().attr('selected', 'selected'));
            //this._barsGraph.categoryCounter = 1;
            this.onSelectChange();
        }

        /**
         *
         * @param id
         */
        show (id: string, year: string): void {



            if(this._rankingInfographic){
                this._barsGraph.hideElements();
                this.destroyRankGraph();
                this._barsGraph.tooltipReset();
            }

            $(".infographic-ranking .infographic-spinner").css("display", "block");
            TweenLite.set($(".infographic-ranking .infographic-spinner"),{autoAlpha: 1});

            this._rankingInfographic = true;

            console.log('----------');
            console.log('show Infographic Ranking');
            var event = new InfographicRankingEvent(InfographicRankingEvent.INFOGRAPHIC_RANKING);
            event.id = id;
            event.category = 'ranking';
            event.year = year;
            this.dispatchEvent(event);

        }

        /**
         *
         * @param data
         */
        loadResults (data: any): void {

            console.log('----------');
            console.log('loadResults Infographic Ranking.', data);
            console.log(data);

            this._data = data;
            TweenLite.to($(".infographic-ranking .infographic-spinner"), .05,{autoAlpha: 0, delay: 1, onComplete: () =>{
                $(".infographic-ranking .infographic-spinner").css("display", "none");
                this._bind();
            }});
        }

        private onRadioChange (){

            var self= this;
            $('#rank-audience-select').on('change', function() {

                console.log("····.·.·.·.·.····")
                console.log($( "#rank-audience-select option:selected" ).index())
               if($( "#rank-audience-select option:selected" ).index()== 0){
                    self._barsGraph.audienceSelected= "homeValue"
               }if($( "#rank-audience-select option:selected" ).index()== 1){
                    self._barsGraph.audienceSelected= "personValue"
               }
               self._barsGraph.updateData();
            });
        }

        private onSelectChange (){

            var self= this;
            $('.rank-category-controll').on('change', function() {
                self._barsGraph.categoryCounter= $( ".rank-category-controll option:selected" ).val();
                console.log("On Select Change", self._barsGraph.categoryCounter);
                console.log($( ".rank-category-controll option:selected" ).val());

                self._barsGraph.updateCategory();

            });
        }

        private insertMainTittle (){

            $(".infographic-ranking-main-tittle").text(this._data["title"]);
            $(".infographic-ranking-date").text(this._data["date"]);
        }

        private deleteMainTittle (){

            $(".infographic-ranking-main-tittle").empty();
            $(".infographic-ranking-date").empty();
        }

        private knockOut (){

            var theOptions= this._data["categories"];

            var item= function(data){
                var self= this;
                self["text"]= data.text;
                self["id"]= data.id;
            }

            function buildData(data){
                var array= [];
                for(var d in data){
                    var thing= new item(
                        {
                            text: data[d].itemName,
                            id: d
                        })
                    array.push(thing);
                }
                return array;
            }

            var viewModel= {
                categories: buildData(theOptions),
                selectedItem: ko.observable()
            };

            // Select last category item by default!
            _.defer(()=> {
                viewModel.selectedItem(theOptions.length-1);
                this._barsGraph.updateCategory();
            });

            ko.applyBindings(viewModel, document.getElementById("rank-category-controll"));

        }

        //chargeSelect(){
        //    var serverOptions= this.data["categories"];
        //    var categoryModel = {
        //        categories: ko.observable()
        //    };

        //

        //    ko.applyBindings(categoryModel, document.getElementsByClassName("rank-category-select"));
        //}

        loadError(): void{

            console.log('----------');
            console.log('loadError Infographic Ranking');
        }

        private resetBindings(){

            ko.cleanNode(document.getElementById("rank-category-controll"));
        }


    }
}