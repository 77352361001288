/**
 * Created by Ivan on 5/21/15.
 */

/// <reference path="../base/FilterItem.ts" />

module media.hrratings.filters {
    export class ChartFilterItem extends FilterItem {
        chartHref: string;

        constructor(jsonEntry: any) {
            super(jsonEntry);
            var chartType = this.type == 'versus' ? 'vs' : this.type;
            this.chartHref = jsonEntry.href + '/' + chartType;
        }
    }
}