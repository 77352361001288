/**
 * Created by Esteban on 10/14/14.
 */
/// <reference path="./../views/IView.ts" />

module media.hrratings.core {

    import IView = media.hrratings.views.IView;

    export class ViewManager {

        private _views: Array<IView> = [];
        private _currentView: IView = null;
        private _lastHash: string;


        addView = (id:number, view: IView) => {
            this._views[id] = view;
        };

        getViewById(id:number):IView {
            return this._views[id];
        }

        isViewOpen(id:number):boolean{
            return this.getViewById(id).isOpen;
        }

        closeView(id:number):Q.Promise<void>  {
            var view = this.getViewById(id);
            view.isOpen = false;
            console.log('View is not opened');
            return view.close();
        }

        openView = (id:number):Q.Promise<void> => {

            if(this._currentView != null && (this.getViewById(id) === this._currentView)){
                var d = Q.defer<void>();
                d.resolve();
                return d.promise;
            }

            if (this._currentView != null) {

                return this._currentView.close()
                    .then( () => {
                        this._currentView = this.getViewById(id);
                        return this._currentView.open();
                    })
            }
            this._currentView = this.getViewById(id);
            return this._currentView.open();
        };
        closeCurrentView = ():Q.Promise<void> => {

            if (this._currentView != null) {
                var activeView = this._currentView;
                this._currentView = null;
                return activeView.close();
            }
        };



        reset () : void {

            this._currentView = null;
        }
    }
}
