/**
 * Created by Ivan on 4/14/15.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/WeeklyView.ts" />

module media.hrratings.commands {

    import ViewManager = media.hrratings.core.ViewManager;

    export class OpenPressCommand {

        mainViewManager: ViewManager = null;

        execute () {
            //console.clear();
            console.log("::::::::::::::::::::::::::::");
            console.log("OpenPressCommand.execute!");

            this.mainViewManager.openView(MainViews.Press);
        }
    }
}
