/**
 * Created by Esteban on 10/22/14.
 */

/// <reference path="./../core/ViewManager.ts" />

module media.hrratings.commands {

    import ViewManager = media.hrratings.core.ViewManager;

    export class OpenContactCommand {

        mainViewManager: ViewManager = null; //injected

        execute () {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenContactCommand.execute!");


            if(!this.mainViewManager.isViewOpen(MainViews.Contact) ){
                this.mainViewManager.openView(MainViews.Contact);
            }
        }
    }
}