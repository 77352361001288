/**
 * Created by Esteban on 10/14/14.
 */

/// <reference path="./services/HRServices.ts" />

/// <reference path="./events/ApplicationEvent.ts" />
/// <reference path="./events/RoutesEvents.ts" />
/// <reference path="./events/LocaleEvent.ts" />
/// <reference path="./events/MenuEvents.ts" />

/// <reference path="./commands/ApplicationStartCommand.ts" />
/// <reference path="./commands/SetupRoutesCommand.ts" />
/// <reference path="./commands/LoaderCompleteCommand.ts" />
/// <reference path="./commands/OpenHomeCommand.ts" />
/// <reference path="./commands/OpenServicesCommand.ts" />
/// <reference path="./commands/OpenMethodologyCommand.ts" />

/// <reference path="./commands/OpenAboutCommand.ts" />
/// <reference path="./commands/OpenContactCommand.ts" />
/// <reference path="./commands/OpenWeeklyCommand.ts" />
/// <reference path="./commands/OpenInfographicProfileCommand.ts" />
/// <reference path="./commands/OpenInfographicRankingCommand.ts" />
/// <reference path="./commands/OpenInfographicVsCommand.ts" />

/// <reference path="./commands/OpenLegalsWebCommand.ts" />
/// <reference path="./commands/OpenLegalsEsCommand.ts" />
/// <reference path="./commands/OpenLegalsPanelCommand.ts" />

/// <reference path="./mediators/MenuViewMediator.ts" />
/// <reference path="./mediators/HomeMediator.ts" />
/// <reference path="./mediators/PressViewMediator.ts" />
/// <reference path="./mediators/AboutViewMediator.ts" />
/// <reference path="./mediators/ContactViewMediator.ts" />
/// <reference path="./mediators/ServicesViewMediator.ts" />
/// <reference path="./mediators/WeeklyViewMediator.ts" />
/// <reference path="./mediators/InfographicProfileMediator.ts" />
/// <reference path="./mediators/InfographicRankingMediator.ts" />
/// <reference path="./mediators/InfographicVsMediator.ts" />
/// <reference path="./mediators/MethodologyViewMediator.ts" />

/// <reference path="./mediators/LegalsWebViewMediator.ts" />
/// <reference path="./mediators/LegalsEsViewMediator.ts" />
/// <reference path="./mediators/LegalsPanelViewMediator.ts" />

/// <reference path="./views/MainLoaderView.ts" />
/// <reference path="./views/HomeView.ts" />
/// <reference path="./views/PressView.ts" />
/// <reference path="./views/MenuView.ts" />
/// <reference path="./views/ServicesView.ts" />
/// <reference path="./views/MethodologyView.ts" />
/// <reference path="./views/AboutView.ts" />
/// <reference path="./views/WeeklyView.ts" />
/// <reference path="./views/InfographicsView.ts" />
/// <reference path="./views/InfographicProfileView.ts" />
/// <reference path="./views/InfographicRankingView.ts" />
/// <reference path="./views/InfographicVsView.ts" />

/// <reference path="./views/LegalsWebView.ts" />
/// <reference path="./views/LegalsEsView.ts" />
/// <reference path="./views/LegalsPanelView.ts" />

/// <reference path="./core/ViewManager.ts" />
/// <reference path="./locale/I18nStore.ts" />

module media.hrratings {

    import ApplicationEvent = media.hrratings.events.ApplicationEvent;
    import RoutesEvents     = media.hrratings.events.RoutesEvents;
    import LocaleEvent      = media.hrratings.events.LocaleEvent;

    export enum MainViews {
        MainLoader,
        Home,
        Weekly,
        Press,
        Infographics,
        Services,
        About,
        Methodology,
        Contact,
        LegalsWeb,
        LegalsEs,
        LegalsPanel,
        InfographicProfile,
        InfographicRanking,
        InfographicVs
    }

    export class App extends soma.Application {

        public lang                     : string;
        public api                      : string
        public loaderView               : any;
        public homeView                 : any;
        public weeklyView               : any;
        public pressView                : any;
        public infographicsView         : any;
        public servicesView             : any;
        public aboutView                : any;
        public methodologyView          : any;
        public legalsWebView            : any;
        public legalsEsView             : any;
        public legalsPanelView          : any;
        public contactView              : any;
        public menuView                 : any;
        public infographicProfileView   : any;
        public infographicRankingView   : any;
        public infographicVsView        : any;

        constructor(_lang:string, _api:string) {
            this.lang = _lang;
            this.api = _api;

            super();
        }

        init () {

            console.log("Application Init");
            console.log("Mapping all dependencies...");

            this.mapClasses();
            this.mapViews();
            this.mapViewMediators();
            this.mapCommands();

            console.log("Mapping dependencies complete.");
        }

        start (){

            var manager:core.ViewManager = this.injector.getValue("mainViewManager");

            manager.addView(MainViews.MainLoader,           this.loaderView             );
            manager.addView(MainViews.Home,                 this.homeView               );
            manager.addView(MainViews.Weekly,               this.weeklyView             );
            manager.addView(MainViews.Infographics,         this.infographicsView       );
            manager.addView(MainViews.Services,             this.servicesView           );
            manager.addView(MainViews.Methodology,          this.methodologyView        );
            manager.addView(MainViews.About,                this.aboutView              );
            manager.addView(MainViews.LegalsWeb,            this.legalsWebView          );
            manager.addView(MainViews.LegalsEs,             this.legalsEsView           );
            manager.addView(MainViews.LegalsPanel,          this.legalsPanelView        );
            manager.addView(MainViews.Contact,              this.contactView            );
            manager.addView(MainViews.InfographicProfile,   this.infographicProfileView );
            manager.addView(MainViews.InfographicRanking,   this.infographicRankingView );
            manager.addView(MainViews.InfographicVs,        this.infographicVsView      );
            manager.addView(MainViews.Press,                this.pressView              );

            manager.openView(MainViews.MainLoader).then( () => {
                this.dispatcher.dispatch(ApplicationEvent.STARTUP);
            });
        }

        private mapClasses ():void {
            console.log("Application mapClasses");

            this.injector.mapValue("lang"           , this.lang                   );
            this.injector.mapValue("api"            , this.api                    );
            this.injector.mapClass("service"        , services.HRServices,    true); // Services as singleton
            this.injector.mapClass("mainViewManager", core.ViewManager,       true); // ViewManager as singleton
            this.injector.mapClass("i18n"           , locale.I18nStore,       true); // I18nStore as singleton
        }

        private mapViews ():void {

            console.log("Application mapViews");

            var elDialogs = $("#dialogs");

            this.menuView               = this.mediators.create(views.MenuView,                 $("#panel-menu")    );
            this.loaderView             = this.mediators.create(views.MainLoaderView,           $("#main-loader")   );
            this.homeView               = this.mediators.create(views.HomeView,                 elDialogs           );
            this.pressView              = this.mediators.create(views.PressView,                elDialogs           );
            this.servicesView           = this.mediators.create(views.ServicesView,             elDialogs           );
            this.methodologyView        = this.mediators.create(views.MethodologyView,          elDialogs           );
            this.aboutView              = this.mediators.create(views.AboutView,                elDialogs           );
            this.legalsWebView          = this.mediators.create(views.LegalsWebView,            elDialogs           );
            this.legalsEsView           = this.mediators.create(views.LegalsEsView,             elDialogs           );
            this.legalsPanelView        = this.mediators.create(views.LegalsPanelView,          elDialogs           );
            this.contactView            = this.mediators.create(views.ContactView,              elDialogs           );
            this.weeklyView             = this.mediators.create(views.WeeklyView,               elDialogs           );
            this.infographicsView       = this.mediators.create(views.InfographicsView,         elDialogs           );
            this.infographicProfileView = this.mediators.create(views.InfographicProfileView,   elDialogs           );
            this.infographicRankingView = this.mediators.create(views.InfographicRankingView,   elDialogs           );
            this.infographicVsView      = this.mediators.create(views.InfographicVsView,        elDialogs           );
        }

        private mapViewMediators ():void {
            console.log("Application mapViewMediators");
            this.mediators.create(mediators.MenuViewMediator,           this.menuView               );
            this.mediators.create(mediators.HomeMediator,               this.homeView               );
            this.mediators.create(mediators.PressViewMediator,          this.pressView              );
            this.mediators.create(mediators.AboutViewMediator,          this.aboutView              );
            this.mediators.create(mediators.MethodologyViewMediator,    this.methodologyView        );
            this.mediators.create(mediators.ContactViewMediator,        this.contactView            );
            this.mediators.create(mediators.LegalsWebViewMediator,      this.legalsWebView          );
            this.mediators.create(mediators.LegalsEsViewMediator,       this.legalsEsView           );
            this.mediators.create(mediators.LegalsPanelViewMediator,    this.legalsPanelView        );
            this.mediators.create(mediators.ServicesViewMediator,       this.servicesView           );
            this.mediators.create(mediators.WeeklyViewMediator,         this.weeklyView             );
            this.mediators.create(mediators.InfographicProfileMediator, this.infographicProfileView );
            this.mediators.create(mediators.InfographicsViewMediator,   this.infographicsView       );
            this.mediators.create(mediators.InfographicRankingMediator, this.infographicRankingView );
            this.mediators.create(mediators.InfographicVsMediator,      this.infographicVsView      );
        }

        private mapCommands ():void {
            console.log("Application mapCommands");

            this.commands.add(ApplicationEvent.STARTUP,                 commands.ApplicationStartCommand        );
            this.commands.add(ApplicationEvent.STARTUP_COMPLETE,        commands.SetupRoutesCommand             );
            this.commands.add(ApplicationEvent.LOADER_COMPLETE,         commands.LoaderCompleteCommand          );

            this.commands.add(RoutesEvents.HOME_ROUTE,                  commands.OpenHomeCommand                );
            this.commands.add(RoutesEvents.REPORTS_ROUTE,               commands.OpenWeeklyCommand              );
            this.commands.add(RoutesEvents.INFOGRAPHICS_ROUTE,          commands.OpenInfographicsCommand        );
            this.commands.add(RoutesEvents.PRESS_ROUTE,                 commands.OpenPressCommand               );

            this.commands.add(RoutesEvents.SERVICES_ROUTE,              commands.OpenServicesCommand            );
            this.commands.add(RoutesEvents.METHODOLOGY_ROUTE,           commands.OpenMethodologyCommand         );

            this.commands.add(RoutesEvents.ABOUT_ROUTE,                 commands.OpenAboutCommand               );
            this.commands.add(RoutesEvents.LEGALS_WEB_ROUTE,            commands.OpenLegalsWebCommand           );
            this.commands.add(RoutesEvents.LEGALS_ES_ROUTE,             commands.OpenLegalsEsCommand            );
            this.commands.add(RoutesEvents.LEGALS_PANEL_ROUTE,          commands.OpenLegalsPanelCommand         );

            this.commands.add(RoutesEvents.CONTACT_ROUTE,               commands.OpenContactCommand             );
            this.commands.add(RoutesEvents.INFOGRAPHIC_PROFILE_ROUTE,   commands.OpenInfographicProfileCommand  );
            this.commands.add(RoutesEvents.INFOGRAPHIC_RANKING_ROUTE,   commands.OpenInfographicRankingCommand  );
            this.commands.add(RoutesEvents.INFOGRAPHIC_VS_ROUTE,        commands.OpenInfographicVsCommand       );
            
        }
    }
}
