/**
 * Created by Esteban on 10/28/14.
 */

module media.hrratings.events {

    export class InfographicRankingEvent extends createjs.Event {

        static MOUSE_OVER               :string = "CircleGraphEvent.onmouseover";
        static MOUSE_OUT                :string = "CircleGraphEvent.onmouseout";
        static CLICK                    :string = "CircleGraphEvent.onmouseclick";

        static INFOGRAPHIC_RANKING      :string  = "Events.infographic_ranking";
        //static INFOGRAPHIC_RANKING_NEXT :string  = "Events.infographic_ranking_next";
        //static INFOGRAPHIC_RANKING_PREV :string  = "Events.infographic_ranking_prev";

        //fill:string;

        public id: string;
        public year: string;
        public category: string;
        constructor (type: string) {
            super(type,false,false);
        }
    }
}