/// <reference path="../views/LegalsEsView.ts" />
/// <reference path="../events/ApplicationEvent.ts" />

module media.hrratings.mediators {

    import EventDispatcher  = soma.EventDispatcher;
    import LegalsEsView    = media.hrratings.views.LegalsEsView;
    import LocaleEvent      = media.hrratings.events.LocaleEvent;

    export class LegalsEsViewMediator {

        static inject:string[] = ["target", "dispatcher"];

        constructor(target:LegalsEsView, dispatcher:EventDispatcher) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });


        }
    }
}