

module media.hrratings.utils.statistics {

    export class Analytics {
        _$el: any =  {};

        static init ($clickTags: JQuery) {

            var self = this;
            $clickTags.on('click', function (e) {
                var $this, data;
                $this = $(this);
                data = $this.data('click-tag');

                self.gaSendEvent(data, e.type);
            });
        }


        static gaSendEvent (category, action): void {
            if (typeof ga !== undefined) {
                ga('send', 'event', category, action);
            }
        }

        static gaSendPageView (view: string): void {
            if (typeof ga !== undefined) {
                ga('send', 'pageview', view);
            }
        }
    }
}