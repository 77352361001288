
module media.hrratings.utils.browser {
    export class ConsoleLog {
        static _log: any;
        static _group: any;
        static _groupCollapsed: any;

        static off() {
            ConsoleLog._log = console.log;
            ConsoleLog._group = console.group;
            ConsoleLog._groupCollapsed = console.groupCollapsed;
            console.log('Console logs disabled');
            console.log = console.group = console.groupCollapsed = ()=> {};
        }

        static on() {
            console.log = ConsoleLog._log;
            console.group = ConsoleLog._group;
            console.groupCollapsed = ConsoleLog._groupCollapsed;
            console.log('Console logs enabled.');
        }
    }
    console['on'] = ConsoleLog.on;
    console['off'] = ConsoleLog.off;
}