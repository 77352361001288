/**
 * Created by Esteban on 10/14/14.
 */

/// <reference path="./AbstractView.ts" />
/// <reference path="./menu/ServicesPanel.ts" />
/// <reference path="./menu/AboutPanel.ts" />
/// <reference path="../utils/browser/clientInfo.ts" />

module media.hrratings.views {

    import ViewManager = media.hrratings.core.ViewManager;
    import ServicesPanel = media.hrratings.views.menu.ServicesPanel;
    import AboutPanel = media.hrratings.views.menu.AboutPanel;
    import clientInfo = media.hrratings.utils.browser.ClientInfo;
    import PlaygroundEvents = media.hrratings.events.HomeEvents;

    enum MenuPanel {Services, About}

    export class MenuView extends AbstractView {

        private _elOverlay: HTMLElement;
        private _elBtHidePanel: HTMLElement;
        private _elBtMenu: JQuery;
        private _elMenu: HTMLElement;
        private _elMenuFirst: HTMLElement;
        private _elMenuSecond: HTMLElement;

        private _elMenuMain: HTMLElement;
        private _elMenuSecondA: JQuery;
        private _elShare: JQuery;
        private _elSocial: HTMLElement;
        private _elLanguage: HTMLElement;
        private _elTextHidden: HTMLElement;
        private _elSocialH4: JQuery;
        private _elLogo: HTMLElement;
        private _submenuServices: HTMLElement;
        private _submenuAbout: HTMLElement;

        private _elMenuOverlay: HTMLElement;
        private _elMenuFirstLevel: JQuery;
        private _elMenuSecondLevel: JQuery;
        private _submenuMask: HTMLElement;
        private _elOverflow: JQuery;

        private _somethingOpen;
        private _elMenuThirdLevel;
        private _elLogoSvg;

        private _subsubOpen;


        private _lastActiveMenuElement: JQuery;
        private _animsTimingSlow:number;
        private _animsTimingFast:number;
        private _menuMask: HTMLElement
        private _tlMenuIntro;
        private _subsectionName: string;

        constructor(_target: HTMLElement) {

            super(_target);
            this._setup();

        }

        private _setup(): void {


            this._cacheElements();
            this._bind();

            if(clientInfo.IS_MOBILE){
                this._animsTimingSlow= 0;
                this._animsTimingFast= 0;
            }else{
                this._animsTimingSlow= 0.25;
                this._animsTimingFast= 0.01;
            }
        }

        setupBindings(): void {
            ko.applyBindings(this, this.target);
        }

        open_hook (d:Q.Deferred<void>): void {


            this._tlMenuIntro = new TimelineMax();
            this._tlMenuIntro.staggerFromTo( [
                this.target,
                $(this.target).find(".logo"),
                $(this.target).find(".main li.first-level").eq(0),
                $(this.target).find(".main li.first-level").eq(1),
                $(this.target).find(".main li.first-level").eq(2),
                $(this.target).find(".main li.first-level").eq(3),
                $(this.target).find(".main li.first-level").eq(4)], 0.15, {
                x: -320
            },{
                delay: 1,
                x: 0,
                ease: Cubic.easeOut
            }, 0.05);

            this._tlMenuIntro.fromTo(
                [this._elSocialH4, this._elLanguage, this._elShare, this._elTextHidden],
                0.25,
                {autoAlpha: 0},
                {autoAlpha: 1, ease: Cubic.easeOut, onComplete: function(){d.resolve();}},
                '-=0.05'
            );

            _.delay(()=>{
                this._restoreActiveMenu();
                this._restoreActiveSub();
            },2000);
        }

        openSection(subsectionName?: string, subsubsectionName?: string): void{
            this._subsectionName = subsectionName;
            this._selectMenuSecondA(subsectionName);

            this._selectMenuThirdA(subsubsectionName);

            if(subsubsectionName){
                this._subsubOpen = true;
            }else{
                this._subsubOpen = false;
            }
        }

        private get _tlPanel (): TimelineLite {
            if( this.$tlPanel == null){
                this.$tlPanel = new TimelineLite({autoStart: false});
                this.$tlPanel.to( this._elBtMenu    , this._animsTimingFast,  {autoAlpha: 0                   } );
                this.$tlPanel.to( this._elOverflow    , this._animsTimingFast,  {display:'block'                  } );
                this.$tlPanel.to( this._elOverlay   , this._animsTimingSlow, {display:'block', left:'0px'    } );
                this.$tlPanel.to( this._elOverlay   , this._animsTimingSlow, {autoAlpha: 0.3                 } );
                this.$tlPanel.to( this.target       , this._animsTimingSlow, {left:'0px'                     } );
            }
            return this.$tlPanel;
        }

        private _cacheElements(): void{
            this._menuMask              = document.getElementById('menu-mask'           );
            this._elOverlay             = document.getElementById('overlay'             );
            this._elBtMenu              = $('.bt-menu-open'                             );
            this._elLogo                = $(this.target).find('#logo'                   ).get(0);
            this._elBtHidePanel         = $(this.target).find('#bt-hide-panel-menu'     ).get(0);
            this._elMenu                = $(this.target).find('.menu'                   ).get(0);
            this._elMenuFirst           = $(this.target).find('.menu-first'             ).get(0);
            this._elMenuSecond          = $(this.target).find('.menu-second'            ).get(0);

            this._elMenuMain            = $(this.target).find('ul.main'                 ).get(0);
            this._elMenuFirstLevel          = $(this.target).find('ul.main li.first-level'              );
            this._elMenuSecondLevel          = $(this.target).find('ul.main .submenu .second-level');
            this._elMenuThirdLevel          = $(this.target).find('ul.main .submenu .second-level .subsubmenu .third-level');

            this._submenuMask              = document.getElementById('submenu-mask'           );
            this._elOverflow         = $(this.target).parent('#menu-overflow-cont');

            this._elMenuSecondA         = $(this.target).find('.menu-second ul a'       );
            this._elShare               = $(this.target).find('.share'                  );
            this._elSocial              = $(this.target).find('.social'                 ).get(0);
            this._elLanguage            = $(this.target).find('.social .language'       ).get(0);
            this._elTextHidden          = $(this.target).find('#menu-text-hidden'       ).get(0);
            this._elSocialH4            = $(this.target).find('.social h4'              );

            this._submenuServices       = $(this.target).find('#submenu-services'       ).get(0);
            this._submenuAbout          = $(this.target).find('#submenu-about'          ).get(0);
            this._elMenuOverlay         = $('#overlay-menu').get(0);
        }

        private _bind(): void{
            var self = this;
            $(this._elMenuFirstLevel).hover(
                function() { self._mouseEnterItemMenu( $(this) ); },
                function() { self._mouseLeaveItemMenu( $(this) ); }
            );

            $(this._elMenuSecondLevel).hover(
                function(){ self._mouseEnterItemMenuSub( $(this) ); },
                function(){ self._mouseLeaveItemMenuSub(); }
            );


            $(this._elMenu).mouseleave(function(){
                self._restoreActiveMenu();
                self._restoreActiveSub();
            });

            $(this._elMenuMain).mouseleave(function(){
                self._restoreActiveMenu();
                self._restoreActiveSub();

            });

            $(this._elBtMenu)
                .on( 'click', () => { this._showMenu(); } );

            _.forEach(
                [$(this._elLogo), $(this._elOverlay), $(this._elBtHidePanel)],
                (elem: any) =>{ elem.on('click', function(){ self.hideMenu(); }) });
        }

        private _showMenu (): void {

            this._tlPanel.play();
        }

        public hideMenu (): void {

            this._tlPanel.reverse();
        }

        private _closeSubmenu (el: JQuery): void {

            this._somethingOpen=[];
            TweenLite.set(el, {css: {display: 'none', width: "0px"} });
            //TweenLite.to(el, this._animsTimingFast,{css: {display: 'none', width: "0px"} });
        }

        private _openSubmenu (el: JQuery): void{

            this._somethingOpen= el;
            //TweenLite.to(el, this._animsTimingSlow,{css: {display: 'block', width: "110px"} });
            TweenLite.set(el, {css: {display: 'block', width: "97px"} });
            var offset= el.parent().offset();
            if (offset){
                var firstChild= el.find("li.second-level:nth-child(1)");
                TweenLite.set(el, {height: $(this.target).height(), top: '-'+ (offset.top+1) +'px'});
                TweenLite.set(firstChild, { marginTop:  offset.top + 'px'});
            }
        }

        private _openSubmenuSub (el: JQuery): void{

            TweenLite.set(el,       {css: {display: 'block', width: "90px"}    });
            var offset= el.parent().offset();
            if (offset){
                var firstChild= el.find("li.third-level:nth-child(1)");
                TweenLite.set(el,       {height: $(this.target).height() , top: '-'+ (offset.top+1) +'px'});
                TweenLite.set(firstChild,       { marginTop:  offset.top + 'px'});
            }
        }


        private _restoreActiveMenu(){

            for(var index in this._elMenuFirstLevel){
                if(this._elMenuFirstLevel.eq(index).hasClass("active")){
                    this._mouseEnterItemMenu( this._elMenuFirstLevel.eq(index) );
                }
            }
        }

        private _restoreActiveSub(){

            for(var index in this._elMenuSecondLevel){
                if(this._elMenuSecondLevel.eq(index).hasClass("selected")){
                    if(this._subsubOpen=== true){
                        this._mouseEnterItemMenuSub( this._elMenuSecondLevel.eq(index) );
                    }
                }
            }
        }

        private _mouseEnterItemMenu (el: JQuery): void {



                if (this._lastActiveMenuElement == undefined) {
                    this.closeActiveItem();
                    this.deleteActiveItem();

                } else{
                    this._mouseLeaveItemMenu(this._lastActiveMenuElement);
                }



            TweenLite.killTweensOf(el.find('span.mask'));
            TweenLite.to(el.find('span.mask'), this._animsTimingSlow, {width: '100%', ease:Cubic.easeOut});

            if(el.has(".submenu").length != 0) {
                var submenu= el.find(".submenu");
                TweenLite.killTweensOf(this._menuMask);
                TweenLite.to(this._menuMask, this._animsTimingSlow, {x: 203, ease: Cubic.easeOut});
                this._openSubmenu(submenu);
            }

            if(el.has(".subsubmenu").length === 0){
                this._mouseLeaveItemMenuSub();
            }
        }

        private _mouseLeaveItemMenu (el: JQuery, isSubmenu: boolean = false): void {


            var submenu = el.find(".submenu");

            TweenLite.killTweensOf(el.find('span.mask'));
            TweenLite.to(el.find('span.mask'), this._animsTimingSlow, {width: '0%', ease:Cubic.easeOut});
            TweenLite.killTweensOf(this._menuMask);
            TweenLite.to(this._menuMask, this._animsTimingSlow, { x: 0, ease: Cubic.easeOut });

            this._closeSubmenu(submenu);


        }

        setActiveItemMenu(id:number, submenu?:string, subsection?:string):void{

            //if (clientInfo.IS_MOBILE) {

            if (this._lastActiveMenuElement && this._lastActiveMenuElement.index() !== id ) {
                this._mouseLeaveItemMenu(this._lastActiveMenuElement, (this._elMenuFirstLevel.eq(id).length != 0));
                this.closeActiveItem();
                this.deleteActiveItem();
                this._lastActiveMenuElement = undefined;
            }
            this._lastActiveMenuElement = this._elMenuFirstLevel.eq(id);

            if(submenu != "methodology"){
            }
            this._lastActiveMenuElement = this._elMenuFirstLevel.eq(id);

            this._mouseEnterItemMenu( this._elMenuFirstLevel.eq(id) );
            this._elMenuFirstLevel.eq(id).addClass("active");
            this.openSection(submenu, subsection );

            //this.openedSubsection(subsection);

        }

        deleteActiveItem () {


            this._elMenuFirstLevel.removeClass("active");
            this._elMenuSecondLevel.removeClass("selected");
            this._elMenuThirdLevel.removeClass("selective");
        }

        closeActiveItem () {


            for(var index in this._elMenuFirstLevel){
                if(this._elMenuFirstLevel.eq(index).hasClass("active")){
                    this._mouseLeaveItemMenu( this._elMenuFirstLevel.eq(index) );
                }
            }
            for(var index in this._elMenuSecondLevel){
                if(this._elMenuSecondLevel.eq(index).hasClass("selected")){
                    this._mouseLeaveItemMenuSub( );
                }
            }
        }

        private _mouseEnterItemMenuSub (el: JQuery): void {


            if(el.has(".subsubmenu").length != 0){
                TweenLite.killTweensOf(this._submenuMask);
                TweenLite.set(this._submenuMask,{ x: 300, ease: Cubic.easeOut });
                //TweenLite.to(this._submenuMask, this._animsTimingFast, { x: 230, ease: Cubic.easeOut });
            }


            //if(){
                this._openSubmenuSub(el.find(".subsubmenu"));

            //}
        }

        private _mouseLeaveItemMenuSub (): void {

            TweenLite.killTweensOf(this._submenuMask);
            //TweenLite.to(this._submenuMask, this._animsTimingSlow, { x: 0, ease: Cubic.easeOut });
            TweenLite.set(this._submenuMask, {x: 0, ease: Cubic.easeOut});
            this._closeSubmenu($(this.target).find(".subsubmenu"));

        }

        private _selectMenuSecondA (id: string): void {

            this._elMenuSecondLevel.removeClass("selected");
            var el = _.findWhere(this._elMenuSecondLevel, {id: "bt-" + id});
            if(el != undefined){
                $(el).addClass('selected'); }
        }
        private _selectMenuThirdA (id: string): void {

            this._elMenuThirdLevel.removeClass("selective");

            var el = _.findWhere(this._elMenuThirdLevel, {id: "bt-" + id});
            if(el != undefined){
                $(el).addClass('selective'); }
        }


        //Don't use this variables.
        private $tlPanel: TimelineLite = null;

    }
}