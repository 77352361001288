/// <reference path="../../definitions/greensock/greensock.d.ts" />
/// <reference path="../../definitions/google.analytics/ga.d.ts" />
/// <reference path="../../definitions/q/Q.d.ts" />
/// <reference path="../../definitions/knockout/knockout.d.ts" />
/// <reference path="../../definitions/underscore/underscore.d.ts" />
/// <reference path="../../definitions/jquery/jquery.d.ts" />
/// <reference path="../../definitions/jquery.validation/jquery.validation.d.ts" />
/// <reference path="../../definitions/soma/soma.d.ts" />
/// <reference path="../../definitions/createjs-lib/createjs-lib.d.ts" />
/// <reference path="../../definitions/d3/d3.d.ts" />
/// <reference path="../../definitions/routie/routie.d.ts" />
/// <reference path="../../definitions/moment/moment.d.ts" />
/// <reference path="../../definitions/chroma-js/chroma-js.d.ts" />
/// <reference path="../../definitions/modernizr/modernizr.d.ts" />
/// <reference path="../../definitions/i18next/i18next.d.ts" />

/// <reference path="./App.ts" />
/// <reference path="./utils/browser/consoleLog.ts" />
/// <reference path="./utils/statistics/analytics.ts" />
/// <reference path="./utils/browser/clientInfo.ts" />

import App = media.hrratings.App;
import browserInfo = media.hrratings.utils.browser.ClientInfo;
import browserConsole = media.hrratings.utils.browser.ConsoleLog;
import analytics = media.hrratings.utils.statistics.Analytics;

$(document).ready(function () {
    Error["stackTraceLimit"] = 35;
    Q.longStackSupport = true;
    browserInfo.fingerprint();
    browserConsole.off();
    analytics.init( $('.click-tag') );
});