/**
 * Created by Esteban on 10/20/14.
 */

/// <reference path="./../AbstractView.ts" />

module media.hrratings.views.menu {

    export class AbstractMenuPanel extends AbstractView {

        constructor(_target: HTMLElement){
            super(_target);
        }

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>):void {
            TweenLite.killTweensOf(this.target);
            TweenLite.to($(this.target), 0.15, {
                left: "0%",
                delay: 0.25,
                onComplete:function () {
                    d.resolve();
                }
            });
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {
            TweenLite.killTweensOf(this.target);
            TweenLite.to(this.target, 0.15,{
                left: "-100%",
                onComplete:function () {
                    d.resolve();
                }
            });
        }

    }
}