/// <reference path="../views/LegalsPanelView.ts" />
/// <reference path="../events/ApplicationEvent.ts" />

module media.hrratings.mediators {

    import EventDispatcher  = soma.EventDispatcher;
    import LegalsPanelView    = media.hrratings.views.LegalsPanelView;
    import LocaleEvent      = media.hrratings.events.LocaleEvent;

    export class LegalsPanelViewMediator {

        static inject:string[] = ["target", "dispatcher"];

        constructor(target:LegalsPanelView, dispatcher:EventDispatcher) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();
            });


        }
    }
}