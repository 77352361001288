/**
 * Created by raul on 10/16/14.
 */

/// <reference path="./AbstractDialogView.ts" />
/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./services/ADSpendMonitoring.ts" />
/// <reference path="./services/ProgramMonitoring.ts" />
/// <reference path="./services/StudiesAdHoc.ts" />
/// <reference path="./services/TelevisionMesurement.ts" />


/// <reference path="../events/MenuEvents.ts" />

module media.hrratings.views {

    import ViewManager           = media.hrratings.core.ViewManager;
    import ADSpendMonitoring     = media.hrratings.views.services.ADSpendMonitoring;
    import ProgramMonitoring     = media.hrratings.views.services.ProgramMonitoring;
    import StudiesAdHoc          = media.hrratings.views.services.StudiesAdHoc;
    import TelevisionMesurement  = media.hrratings.views.services.TelevisionMesurement;

    import MenuEvents     = media.hrratings.events.MenuEvents;


    export enum MenuServices {
        TelevisionMeasurement,
        ADSpendMonitoring,
        ProgramMonitoring,
        StudiesAdHoc
    }

    export class ServicesView extends AbstractDialogView {

        private _elPanel: HTMLElement;
        private dispatcher: soma.EventDispatcher = null; //injected

        constructor(_target: HTMLElement) {

            super(_target);

            this._cachedElements();
            this._setup();
        }

        private _setup():void {

            this._tabManager.openView(MenuServices.TelevisionMeasurement);
            this._tabManager.closeCurrentView();
        }

        private _cachedElements(): void {

            this._elPanel = $(this.target).find('#services').get(0);
        }

        getPanel(): HTMLElement {

            return this._elPanel;
        }

        setupBindings(): void {

            ko.applyBindings(this, this._elPanel);
        }

        /**
         *
         * @param id
         */
        showTab(idSection: string, idSubsection?: string): void {

            switch (idSection) {

                case 'television-audience-measurement':     { this._tabManager.openView(MenuServices.TelevisionMeasurement  ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
                case 'ad-spend-monitoring-service':         { this._tabManager.openView(MenuServices.ADSpendMonitoring      ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
                case 'program-monitoring-service':          { this._tabManager.openView(MenuServices.ProgramMonitoring      ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
                case 'ad-hoc-studies':                      { this._tabManager.openView(MenuServices.StudiesAdHoc           ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
                default:                                    { this._tabManager.openView(MenuServices.TelevisionMeasurement  ); this.dispatcher.dispatch(MenuEvents.MENU_HIDE); break; }
            }
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {

            this._tabManager.closeCurrentView();

            TweenLite.to(this.getPanel(), 0.15,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                TweenLite.set(this.getPanel(), {top: '-100%'});
                TweenLite.set(this.target, {css:{display:'none'}});

                this._tabManager.reset();
                d.resolve();

            }});

            $(this._elPanel).scrollTop(0);
        }

        /**
         *
         * @returns {ViewManager}
         * @private
         */
        private get _tabManager(): ViewManager {

            if (this.$tabManager == null) {
                this.$tabManager = new ViewManager();

                this.$tabManager.addView(MenuServices.TelevisionMeasurement,    new TelevisionMesurement('#ffa64d', this._elPanel, $(this._elPanel).find(".tab-1").get(0)));
                this.$tabManager.addView(MenuServices.ADSpendMonitoring,        new ADSpendMonitoring   ('#ff7a4d', this._elPanel, $(this._elPanel).find(".tab-2").get(0)));
                this.$tabManager.addView(MenuServices.ProgramMonitoring,        new ProgramMonitoring   ('#ff5c4d', this._elPanel, $(this._elPanel).find(".tab-3").get(0)));
                this.$tabManager.addView(MenuServices.StudiesAdHoc,             new StudiesAdHoc        ('#ff4d5d', this._elPanel, $(this._elPanel).find(".tab-4").get(0)));
            }
            return this.$tabManager;
        }

        private $tabManager: ViewManager = null;
    }
}