/**
 * Created by Esteban on 10/14/14.
 */

/// <reference path="./AbstractView.ts" />

module media.hrratings.views{

    export class MainLoaderView extends AbstractView {

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>):void {
            d.resolve();
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {
            var tl = new TimelineLite({onComplete: function () {
                d.resolve();
            }});
            tl.to($(this.target).find('span'), 1.15, {x: 300, autoAlpha: 0 });
            tl.to($(this.target).find('.loader'),0.45, {scale: 0.1 });
            tl.to(this.target,0.45,{ autoAlpha: 0 }, 0.15);
        }
    }
}
