/**
 * WeeklyView
 * Created by Esteban on 11/18/14.
 */

/// <reference path="./AbstractFilterView.ts" />
/// <reference path="./../utils/statistics/analytics.ts" />
/// <reference path="../filters/weekly/WeeklyFilter.ts" />

module media.hrratings.views {

    import EventDispatcher = soma.EventDispatcher;
    import Analytics    = media.hrratings.utils.statistics.Analytics;
    import WeeklyFilter = media.hrratings.filters.WeeklyFilter;
    import WeeklyFilterItem = media.hrratings.filters.WeeklyFilterItem;

    export class WeeklyView extends AbstractFilterView {
        private ITEM_TYPES = ['report'];
        private timeline: TimelineLite;

        public koTimeSpanTitle: KnockoutObservable<string>;
        public koSelectedLink: KnockoutObservable<string>;
        public koSelectedYear: KnockoutObservable<string>;
        public koSelectedWeek: KnockoutObservable<string>;

        private _elTitleCol: HTMLElement;
        private _elTextLinkDocument: HTMLElement;
        private _elIconLinkDocument: HTMLElement;
        private _elSpanTitle: HTMLElement;
        private _elWeekContainer: HTMLElement;
        private _elYearContainer: HTMLElement;

        constructor(_target: HTMLElement) {
            super(_target);
            this._cacheElements();
            this.createTimeline();

            // filter init
            this.filter = new WeeklyFilter();
            this.types = this.ITEM_TYPES;

        }

        public onYearDropdownChange = () => {
            var selectedOption = $(this._elDropboxYear).find('option:selected');
            var year = selectedOption.data('year');
            this.updateYearSelection(year | 0);
            this.selectLatestWeek();
        };

        public onWeekDropdownChange = () => {
            var selectedOption = $(this._elDropboxWeek).find('option:selected');
            var week = selectedOption.data('week');
            var year = selectedOption.data('year');
            this.updateWeekSelection(year, week);
        };

        private updateWeekSelection(year: number, week: number) {
            var weeklyFilter = <WeeklyFilter>this.filter;
            if (year && week) {
                var item = <WeeklyFilterItem>weeklyFilter.getItemByDate(year, week);
                this.koTimeSpanTitle(item.dateSpan);
                this.koSelectedLink(item.fileSrc);
                routie(weeklyFilter.getWeeklyUrlOf(item));
            } else {
                console.warn('updateWeekSelection:', 'Year and/or week are not valid:', year, week);
            }
        }

        private updateYearSelection(year: number) {
            var self = this;
            var weeks = this.filter.getItemsTimeLine(year);
            this.koWeeks.removeAll();
            _.each(weeks, (w: IWeekEntry)=> {
                self.koWeeks.push(w);
            });
        }

        private selectLatestWeek() {
            //Wait KO to update controls
            _.defer(()=> {
                this._elDropboxWeek.selectedIndex = this._elDropboxWeek.length - 1;
                this.onWeekDropdownChange();
            });
        }

        public selectWeekly(year: string, week: string) {
            if (year.length == 4 && week.length > 4) {
                var yearMatch:any = year.match(/([0-9]+)/);
                var weekMatch:any = week.match(/Week([0-9]+)/);
                var rYear = yearMatch[1]|0;
                var rWeek = weekMatch[1]|0;

                if (this.filter.itemWithDateExists(rYear, rWeek)) {
                    this.setComboItemsTo(rYear, rWeek);
                    this.updateWeekSelection(rYear, rWeek);
                } else {
                    this.selectLatestResults();
                }
            } else {
                console.log('Url params ignored:', year, week);
            }
        }

        private selectLatestYear() {
            this._elDropboxYear.selectedIndex = this._elDropboxYear.length - 1;
            this.onYearDropdownChange();
        }

        public selectLatestResults() {
            //Wait KO to update controls
            _.delay(()=> {
                this.selectLatestYear();
                this.selectLatestWeek();
            }, 10);
        }

        private updateComboItem(element: HTMLSelectElement, dataName: string, value: number): JQuery {
            var updatedOption:JQuery = undefined;
            $(element).find('option').each((index: any, item: any)=> {
                if ($(item).data(dataName) == value) {
                    $(item).prop('selected', true);
                    updatedOption = $(item);
                }
            });
            return updatedOption;
        }

        private setComboItemsTo(year: number, week: number) {
            var selectedYearOption = this.updateComboItem(this._elDropboxYear, 'year', year);
            this.koSelectedYear(selectedYearOption.text());
            this.updateYearSelection(year);
            _.delay(()=> {
                var selectedWeekOption = this.updateComboItem(this._elDropboxWeek, 'week', week);
                this.koSelectedWeek(selectedWeekOption.text());
            }, 10);
        }

        public onFilterReady = () => {
            this.filter.setTypes(this.ITEM_TYPES);
            var years: Array<number> = this.filter.getAvailableYears();
            _.each(years, (y: number)=> {
                this.koYears.push({ label: y.toString() });
            });
        };

        public initialize(lang: string) {
            (<WeeklyFilter>this.filter).fetchItems(lang);
            this.initializeFilter();
            this.setupKOBindings();
        }

        private setupKOBindings(): void {
            this.koSelectedLink = ko.observable('#no-link');
            this.koTimeSpanTitle = ko.observable('koTitleSpanTitle');
            this.koSelectedYear = ko.observable('0');
            this.koSelectedWeek = ko.observable('None');
            ko.applyBindings(this, this._elPanel);
        }

        open_hook (d:Q.Deferred<void>):void {
            this.hideAll();
            this.showView().then(()=> {
                TweenLite.set(this._elPanel, {top: '0px', autoAlpha: 1});
                this.timeline.play(0);
                d.resolve();
            });
        }

        close_hook (d:Q.Deferred<void>):void {
            this.hideView().then(()=> {
                TweenLite.set(this._elPanel, {top: '-100%', autoAlpha: 0});
                d.resolve();
            });
        }

        private _cacheElements (): void {
            this._elPanel = $(this.target).find('#weekly').get(0);
            this._elDropboxWeek = <HTMLSelectElement>$(this._elPanel).find('#week-dropdown').get(0);
            this._elDropboxYear = <HTMLSelectElement>$(this._elPanel).find('#year-dropdown').get(0);

            this._elTitleCol = $(this._elPanel).find('.title-col').get(0);
            this._elSpanTitle = $(this._elPanel).find('.report-col h2').get(0);
            this._elTextLinkDocument = $(this._elPanel).find('a.document-link').get(0);
            this._elIconLinkDocument = $(this._elPanel).find('a.download-btn').get(0);

            this._elWeekContainer = $(this._elPanel).find('.week-select').get(0);
            this._elYearContainer = $(this._elPanel).find('.year-select').get(0);
        }

        private hideAll() {
            TweenLite.set([
                this._elTitleCol,
                this._elSpanTitle,
                this._elTextLinkDocument,
                this._elIconLinkDocument,
                this._elWeekContainer,
                this._elYearContainer
            ], {autoAlpha: 0});
        }

        public createTimeline() {
            var e = Cubic.easeOut;
            this.timeline = new TimelineLite();

            //Section Title
            this.timeline.fromTo(this._elTitleCol, 0.25,
                {y: 30, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: e});

            //Report
            this.timeline.fromTo(this._elSpanTitle, 0.25,
                {y: 30, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: e}, '-=0.12');
            this.timeline.fromTo(this._elTextLinkDocument, 0.2,
                {y: 30, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: e}, '-=0.15');
            this.timeline.fromTo(this._elIconLinkDocument, 0.2,
                {y: 30, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: e}, '-=0.15');

            //Combos
            this.timeline.fromTo(this._elYearContainer, 0.2,
                {x: 30, autoAlpha: 0}, {x: 0, autoAlpha: 1, ease: e}, '-=0.15');
            this.timeline.fromTo(this._elWeekContainer, 0.2,
                {x: 30, autoAlpha: 0}, {x: 0, autoAlpha: 1, ease: e}, '-=0.1');

            this.timeline.timeScale(1.3);
        }


    }
}
