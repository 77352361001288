/**
 * InfographicsView
 * Created by Esteban on 11/20/14.
 */

/// <reference path="./AbstractFilterView.ts" />
/// <reference path="../events/FilterEvents.ts" />
/// <reference path="../filters/charts/ChartFilter.ts" />
/// <reference path="../filters/charts/ChartFilterItem.ts" />

module media.hrratings.views {

    import EventDispatcher = soma.EventDispatcher;
    import PlaygroundEvents = media.hrratings.events.HomeEvents;
    import Profile = media.hrratings.icons.Profile;
    import Vs = media.hrratings.icons.Vs;
    import Ranking = media.hrratings.icons.Ranking;

    import ChartFilter = media.hrratings.filters.ChartFilter;
    import ChartFilterItem = media.hrratings.filters.ChartFilterItem;

    export class InfographicsView extends AbstractFilterView {
        private ITEM_TYPES = ['ranking', 'profile', 'versus'];
        private _elContainer: HTMLElement;
        private _elMiniChartsRank: HTMLElement;
        private _elMiniChartsProf: HTMLElement;
        private _elMiniChartsVs: HTMLElement;
        private _elMiniChartLinkRanking: HTMLElement;
        private _elMiniChartLinkVersus: HTMLElement;
        private _elMiniChartLinkProfile: HTMLElement;

        private _elTitleCol: HTMLElement;
        private _elDropContainerYear: HTMLElement;
        private _elDropContainerWeek: HTMLElement;
        private _elSpanTitle: HTMLElement;
        private _elTitleVersus: HTMLElement;
        private _elTitleRanking: HTMLElement;
        private _elTitleProfile: HTMLElement;
        private _elLinkVersus: HTMLElement;
        private _elLinkRanking: HTMLElement;
        private _elLinkProfile: HTMLElement;

        private timeLine: TimelineLite;

        public koSelectedVersus: KnockoutObservable<ChartFilterItem>;
        public koSelectedRanking: KnockoutObservable<ChartFilterItem>;
        public koSelectedProfile: KnockoutObservable<ChartFilterItem>;
        public koTimeSpanTitle: KnockoutObservable<string>;
        public koSelectedYear: KnockoutObservable<string>;
        public koSelectedWeek: KnockoutObservable<string>;

        constructor(_target: HTMLElement) {
            super(_target);

            this.cacheElements();
            this.createTimeline();

            // filter init
            this.filter = new ChartFilter();
            this.types = this.ITEM_TYPES;
            this.onViewShowCallback = this.onViewShow;
        }

        private newEmptyObservableItem(): KnockoutObservable<ChartFilterItem> {
            return ko.observable( <ChartFilterItem> {
                date: moment(),
                title: '',
                dateSpan: '',
                type: '',
                tags: '',
                href: '',
                chartHref: '#',
                fileSrc: '',
                enabled: false,
                week: 0
            });
        }

        public initialize(lang: string) {
            (<ChartFilter>this.filter).fetchItems(lang);
            this.initializeFilter();
            this.createMiniCharts();
            this.setupKOBindings();
        }

        private setupKOBindings(): void {
            this.koTimeSpanTitle = ko.observable('');
            this.koSelectedProfile = this.newEmptyObservableItem();
            this.koSelectedVersus = this.newEmptyObservableItem();
            this.koSelectedRanking = this.newEmptyObservableItem();
            this.koSelectedWeek = ko.observable('');
            this.koSelectedYear = ko.observable('');
            ko.applyBindings(this, this._elPanel);
        }

        private cacheElements (): void {
            this._elPanel = $(this.target).find('#infographics').get(0);
            this._elContainer = $(this.target).find('#infographics-container').get(0);
            this._elDropboxWeek = <HTMLSelectElement>$(this._elPanel).find('#week-dropdown').get(0);
            this._elDropboxYear = <HTMLSelectElement>$(this._elPanel).find('#year-dropdown').get(0);
            this._elMiniChartsRank = document.getElementById("charts-rank-icon-cont");
            this._elMiniChartsProf = document.getElementById("charts-profile-icon-cont");
            this._elMiniChartsVs = document.getElementById("charts-vs-icon-cont");
            this._elMiniChartLinkRanking = document.getElementById('link-ranking');
            this._elMiniChartLinkVersus = document.getElementById('link-versus');
            this._elMiniChartLinkProfile = document.getElementById('link-profile');
            //
            this._elTitleCol = $(this._elPanel).find('.title-col').get(0);
            this._elDropContainerWeek = $(this._elPanel).find('.dropdowns-col .week-select').get(0);
            this._elDropContainerYear = $(this._elPanel).find('.dropdowns-col .year-select').get(0);
            this._elSpanTitle = $(this._elPanel).find('.timeSpanTitle').get(0);
            this._elTitleVersus = $(this._elPanel).find('.titles .versus').get(0);
            this._elTitleRanking = $(this._elPanel).find('.titles .ranking').get(0);
            this._elTitleProfile = $(this._elPanel).find('.titles .profile').get(0);
            this._elLinkVersus = $(this._elPanel).find('.links .versus').get(0);
            this._elLinkRanking = $(this._elPanel).find('.links .ranking').get(0);
            this._elLinkProfile = $(this._elPanel).find('.links .profile').get(0);
        }

        public onYearDropdownChange = () => {
            var selectedOption = $(this._elDropboxYear).find('option:selected');
            var year = selectedOption.data('year');
            this.updateYearSelection(year | 0);
            this.selectLatestWeek();
            //console.log('updateYearSelection', selectedOption.data('year'));
        };

        public onWeekDropdownChange = () => {
            var selectedOption = $(this._elDropboxWeek).find('option:selected');
            var week = selectedOption.data('week');
            var year = selectedOption.data('year');
            this.updateWeekSelection(year, week);
        };

        private updateWeekSelection(year: number, week: number) {
            var chartFilter = <ChartFilter>this.filter;
            if (year && week) {
                var triad = chartFilter.getTriadByDate(year, week);
                console.log('Selected Triad:', triad);
                this.koSelectedProfile( <ChartFilterItem> _.findWhere(triad, {type: 'profile'}) );
                this.koSelectedRanking( <ChartFilterItem> _.findWhere(triad, {type: 'ranking'}) );
                this.koSelectedVersus( <ChartFilterItem> _.findWhere(triad, {type: 'versus'}) );
                var firstElement = <ChartFilterItem>_.first(triad);
                this.koTimeSpanTitle(firstElement.dateSpan);
                routie(chartFilter.getTriadUrlOf(firstElement));

            } else {
                console.warn('updateWeekSelection:', 'Year and/or week are not valid');
            }
        }

        private updateYearSelection(year: number) {
            var weeks = this.filter.getItemsTimeLine(year);
            this.koWeeks.removeAll();
            _.each(weeks, (w: IWeekEntry)=> {
                this.koWeeks.push(w);
            });

        }

        private selectLatestYear() {
            this._elDropboxYear.selectedIndex = this._elDropboxYear.length - 1;
            this.onYearDropdownChange();
        }

        private selectLatestWeek() {
            //Wait KO to update controls
            _.defer(()=> {
                this._elDropboxWeek.selectedIndex = this._elDropboxWeek.length - 1;
                this.onWeekDropdownChange();
            });
        }

        public selectLatestResults() {
            //Wait KO to update controls
            _.delay(()=> {
                this.selectLatestYear();
                this.selectLatestWeek();
            }, 10);
        }

        private createMiniCharts():void {
            console.log('MiniCharts Bindings!');

            var rank = new Ranking(this._elMiniChartsRank);
            var versus = new Vs(this._elMiniChartsVs);
            var profile = new Profile(this._elMiniChartsProf);

            var bindChart = (chartObject:any, chartElement: HTMLElement,
                             chartLinkElement: HTMLElement, linkElement: HTMLElement) => {

                $(chartElement)
                    .mouseenter( ()=> {
                        chartObject.pathTrans(1);
                    }).mouseleave( ()=> {
                        chartObject.pathTrans(0);
                    });

                $(chartLinkElement).click( (e)=> {
                    e.preventDefault();
                    routie($(linkElement).find('a').attr('href'));
                });
            };

            bindChart(rank, this._elMiniChartsRank, this._elMiniChartLinkRanking, this._elLinkRanking);
            bindChart(versus, this._elMiniChartsVs, this._elMiniChartLinkVersus, this._elLinkVersus);
            bindChart(profile, this._elMiniChartsProf, this._elMiniChartLinkProfile, this._elLinkProfile);
        }

        private updateComboItem(element: HTMLSelectElement, dataName: string, value: number): JQuery {
            var updatedOption:JQuery = undefined;
            $(element).find('option').each((index: any, item: any)=> {
                if ($(item).data(dataName) == value) {
                    $(item).prop('selected', true);
                    updatedOption = $(item);
                }
            });
            return updatedOption;
        }

        private setComboItemsTo(year: number, week: number) {
            var selectedYearOption = this.updateComboItem(this._elDropboxYear, 'year', year);
            this.koSelectedYear(selectedYearOption.text());
            this.updateYearSelection(year);
            _.defer(()=> {
                var selectedWeekOption = this.updateComboItem(this._elDropboxWeek, 'week', week);
                this.koSelectedWeek(selectedWeekOption.text());
            });
        }

        public selectTriad(year: string, week: string) {

            if (year.length == 4 && week.length > 4) {
                var yearMatch:any = year.match(/([0-9]+)/);
                var weekMatch:any = week.match(/Week([0-9]+)/);
                var rYear = yearMatch[1]|0;
                var rWeek = weekMatch[1]|0;

                if (this.filter.itemWithDateExists(rYear, rWeek)) {
                    this.setComboItemsTo(rYear, rWeek);
                    this.updateWeekSelection(rYear, rWeek);
                } else {
                    this.selectLatestResults();
                }
            } else {
                console.log('Url params ignored:', year, week);
            }
        }

        public onFilterReady = () => {
            this.filter.setTypes(this.ITEM_TYPES);
            var years: Array<number> = this.filter.getAvailableYears();
            _.each(years, (y: number)=> {
                this.koYears.push({ label: y.toString() });
            });
        };

        public onViewShow() {
            //console.log('playing timeline');
            this.timeLine.play(0);
        }

        /**
         *
         * @returns {TimelineLite}
         * @private
         */
        public createTimeline() {

            var e = Cubic.easeOut;
            this.timeLine = new TimelineLite();

            var pChartsGraphics = {from: {opacity: 0, y: 30}, to: {opacity: 1, y: 0, ease: e}};
            var pChartsTitles = {from: {opacity: 0, y: 8}, to: {opacity: 1, y: 0, ease: e}};
            var pChartsLinks = {from: {opacity: 0, y: 15}, to: {opacity: 1, y: 0, ease: e}};

            this.timeLine.fromTo(this._elTitleCol, 0.25,
                {y: 30, opacity: 0}, {y: 0, opacity: 1, ease: e});
            this.timeLine.fromTo(this._elDropContainerYear, 0.25,
                {x: 30, opacity: 0}, {x: 0, opacity: 1, ease: e}, '-=0.15');
            this.timeLine.fromTo(this._elDropContainerWeek, 0.25,
                {x: 30, opacity: 0}, {x: 0, opacity: 1, ease: e}, '-=0.1');
            this.timeLine.fromTo(this._elSpanTitle, 0.25,
                {opacity: 0, y: 20},{opacity: 1, y: 0, ease: e}, '-=0.1');

            this.timeLine.fromTo(this._elMiniChartsRank, 0.3,
                pChartsGraphics.from, pChartsGraphics.to, '-=.15');
            this.timeLine.fromTo(this._elTitleRanking, 0.25,
                pChartsTitles.from, pChartsTitles.to, '-=0.15');
            this.timeLine.fromTo(this._elLinkRanking, .15,
                pChartsLinks.from, pChartsLinks.to, '-=0.15');

            this.timeLine.fromTo(this._elMiniChartsVs, 0.3,
                pChartsGraphics.from, pChartsGraphics.to, '-=.3');
            this.timeLine.fromTo(this._elTitleVersus, 0.25,
                pChartsTitles.from, pChartsTitles.to, '-=0.15');
            this.timeLine.fromTo(this._elLinkVersus, .15,
                pChartsLinks.from, pChartsLinks.to, '-=0.15');

            this.timeLine.fromTo(this._elMiniChartsProf, 0.3,
                pChartsGraphics.from, pChartsGraphics.to, '-=.3');
            this.timeLine.fromTo(this._elTitleProfile, 0.25,
                pChartsTitles.from, pChartsTitles.to, '-=0.15');
            this.timeLine.fromTo(this._elLinkProfile, .15,
                pChartsLinks.from, pChartsLinks.to, '-=0.15');

            this.timeLine.timeScale(1.3);
        }



    }
}