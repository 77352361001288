/// <reference path="../../utils/browser/clientInfo.ts" />
module media.hrratings.infographicRanking {

    import ClientInfo = utils.browser.ClientInfo;

    export interface IGraphic {
        createGraphic():void;
    }

    export class BarsGraph extends createjs.EventDispatcher implements IGraphic{

        public data:any;
        public categoryCounter:number;
        public audienceSelected:string;

        private containerName:string;
        private _bGWidht:number;
        private _bGHeight:number;
        private _stage:D3.Selection;
        private _xAxis:D3.Scale.OrdinalScale;
        private _yAxis:D3.Scale.LinearScale;
        private _bars:D3.Selection;
        private _theColors: Array<any>;
        private _version:string;
        private _activeIndex:number;

        private _thaTitle;
        private _thaGraphCont;
        private _tha1Place;
        private _tha2Place;
        private _tha3Place;
        private _tha4Place;
        private _tha5Place;
        private _thaControlls;
        private _thaFooter;
        private _thaTooltip;


        constructor(public thedata:any, public thecontainerName:string){
            super();

            this.data= thedata;
            this.containerName= thecontainerName;
            this.setup();

            this.categoryCounter= 0;
            this._activeIndex= 0;
            this.audienceSelected= "homeValue";
            this._theColors= ["#ff4d79", "#ff4d5b", "#ff5b4d", "#ff794d", "#ffa64d"];
            this.createGraphic();
        }

        get _maxCategoryValue(){
            var actualCategory= this.data["categories"][this.categoryCounter]["data"];
            var maxHomeValue;
            var maxPersonValue;

            maxHomeValue= _.max(actualCategory, function(actualCategory){
                return actualCategory["homeValue"];
            })["homeValue"];
            maxPersonValue= _.max(actualCategory, function(actualCategory){
                return actualCategory["personValue"];
            })["personValue"];

            console.log(_.max([maxHomeValue, maxPersonValue]));

            return _.max([maxHomeValue, maxPersonValue]);
        }

        get _minCategoryValue(){
            var actualCategory= this.data["categories"][this.categoryCounter]["data"];
            var minHomeValue;
            var minPersonValue;

            minHomeValue= _.min(actualCategory, function(actualCategory){
                return actualCategory["homeValue"];
            })["homeValue"];
            minPersonValue= _.min(actualCategory, function(actualCategory){
                return actualCategory["personValue"];
            })["personValue"];

            minPersonValue = minPersonValue === 0 ? minHomeValue : minPersonValue;

            return (_.min([minHomeValue, minPersonValue]))-2;

        }

        get _allActualValues(){
            var self= this;
            return this.data["categories"][this.categoryCounter].data.map(function(i){return [i["itemName"], i[self.audienceSelected]]});
        }


        private setup():void{
            console.log("setup");
            this._thaTitle= $(".rank-header");
            this._thaGraphCont=$(".infographic-rank-graph");
            this._tha1Place= $(".rank-bar-graph-titles li:nth-child(1)");
            this._tha2Place= $(".rank-bar-graph-titles li:nth-child(2)");
            this._tha3Place= $(".rank-bar-graph-titles li:nth-child(3)");
            this._tha4Place= $(".rank-bar-graph-titles li:nth-child(4)");
            this._tha5Place=$(".rank-bar-graph-titles li:nth-child(5)");
            this._thaControlls=$("#rank-controllers");
            this._thaFooter= $("#infographic-ranking .infographic-disclamer");
            this._thaTooltip= $("#infographic-ranking .infographic-tooltip");
        }


        public createGraphic():void{

            console.log("createGraphic");
            var self= this;
            this.tooltipClose();
            //$(window).on("resize", function(){
            //    self.setResize();
            //});
            console.log("creating bars");
            this.checkDevice();
        }

        public destroyBarGraph():void{
            console.log("destroyBarGraph");
            $("#rank-bar-graph-container").empty();
            $(".infographic-ranking-main-tittle").empty();
            $(".infographic-ranking-date").empty();
            $(".rank-show-tittle").empty();
            $(".rank-channel").empty();
            $(".rank-tv").empty();

            this.unbinds();
            this.resetCounters();
        }
        private resetCounters():void{
            console.log("resetCounters");
            this.categoryCounter= 0;
            this._activeIndex= 0;
            this.audienceSelected= "homeValue";
        }

        private setWidth(width:number):void{
            console.log("setWidth");

            if (width != this._bGWidht) {
                this._bGWidht = width;
                if(this._bGWidht < 450){
                    this.updateGraphSmall();
                }else{
                    this.updateGraph();
                }
            }
        }

        private insertShowsTitles():void{
            console.log("insertShowsTitles");

            for(var show in this.data["categories"][this.categoryCounter].data){

                var showName= this.data.categories[this.categoryCounter].data[show]["itemName"];
                var shortName;
                if(showName.length > 33){
                    shortName= showName.substring(0, 30);
                    shortName= shortName + "...";
                }else{
                    shortName= this.data.categories[this.categoryCounter].data[show]["itemName"];
                }

                var place:number;
                place = (parseFloat(show)) + 1;
                $(".rank-bar-graph-titles ul li:nth-child("+ place +") .rank-place").text(place);
                $(".rank-bar-graph-titles ul li:nth-child("+ place +") .rank-show-tittle").text(shortName);
                $(".rank-bar-graph-titles ul li:nth-child("+ place +") .rank-channel").text(this.data.categories[this.categoryCounter].data[show]["channel"]);
                $(".rank-bar-graph-titles ul li:nth-child("+ place +") .rank-tv").text(this.data.categories[this.categoryCounter].data[show]["emision"]);
            }
        }

        private updateGraphSmall():void{
            console.log("updateGraphSmall")
            d3.selectAll(".bar-chart").remove();
            this.setStage();
            this.setXaxis();
            this.setYaxis();
            this.setBars();
            this.insertBarsNoAnimSmall();
            this.insertShowsTitles();
            //this.animateDynamicBars();
            this.insertListeners(".graphBars", "click", this.onClick.bind(this));
            //this.insertBars(80, this._bGHeight, "percentBars");
            this.animateTitles(this._activeIndex);
            this.insertPercentLabels(this._activeIndex);

        }

        private updateGraph():void {

            console.log("updateGraph")
            d3.selectAll(".bar-chart").remove();
            this.setStage();
            this.setXaxis();
            this.setYaxis();
            this.setBars();
            this.insertBarsNoAnim();
            this.insertShowsTitles();
            this.insertLabels();
            this.resetWidthTitles()

        }

        private insertBarsNoAnim():void{
            console.log("insertBarsNoAnim");

            var self= this;
            this._bars.append("rect")
                .attr("class", "graphBars")
                .attr("x", function(d, i) { return self._xAxis(i); })
                .attr("width", self._xAxis.rangeBand())
                .attr('fill', function(d, i){
                    return self._theColors[i];
                })
                //.each(function(d:any) { self._current.push(d); })
                .attr("height", function(d) { return (self._bGHeight - self._yAxis(d[1]))+60; })
                .attr("y", function(d) { return (self._yAxis(d[1])); })
        }

        private insertBarsNoAnimSmall():void{
            console.log("insertBarsNoAnimSmall");

            var self= this;
            this._bars.append("rect")
                .attr("class", "graphBars")
                .attr("x", function(d, i) {
                    if(i==self._activeIndex){
                        return self._xAxis(i)-self._activeIndex*20;
                    }if(i==0){
                        return self._xAxis(i)
                    }else{
                        if(i>0 && i < self._activeIndex ){
                            return self._xAxis(i)-(i*20)
                        } else{
                            return self._xAxis(i)+(100-(i*20))
                        }
                    }})
                .attr("width", function(d, i) { if(i==self._activeIndex){ return self._xAxis.rangeBand()+80}else{return self._xAxis.rangeBand()-20}})
                .attr('fill', function(d, i){
                    return self._theColors[i];
                })
                //.each(function(d:any) { self._current.push(d); })
                .attr("height", function(d) { return (self._bGHeight - self._yAxis(d[1]))+60; })
                .attr("y", function(d) { return (self._yAxis(d[1])); })
        }

        private setResize():void{
            console.log("setResize");

            var windowW:number= window.innerWidth;

            if(windowW <= 480 ){
                this.setWidth(280);
            }if(windowW > 480 && windowW <= 745){
                this.setWidth(400);
            }if(windowW > 745 && windowW <= 1020){
                this.setWidth(590);
            }if(windowW > 1020){
                this.setWidth(800);
            }
        }

        private setMeasures():void{
            console.log("setMeasures");
            this._bGWidht= 800;
        }

        private checkDevice():void{
            console.log("checkDevice");

            if(ClientInfo.IS_MOBILE){
                console.log("Mobile Graph Version");
                this._version= "device";
                this.deviceVersion();
            } else {
                console.log("Desktop Graph Version");
                this._version= "desktop";
                this.desktopVersion();
            }
        }

        private desktopVersion():void{
            console.log("desktopVersion");

            //debugger;
            this.setMeasures();
            this.setStage();
            this.setXaxis();
            this.setYaxis();
            this.setBars();
            this.insertBars(60, this._bGHeight, "graphBars");
            this.animateDynamicBars();
            if(this._bGWidht< 450){
                this.insertListeners(".graphBars", "click", this.onClick.bind(this));
              //this.insertListeners(".graphBars", "mouseover", this.onMouseOver.bind(this));
              //this.insertListeners(".percentBars", "mouseover", this.onMouseOver.bind(this));
              //this.insertListeners(".graphBars", "mouseout", this.onMouseOut.bind(this));
              //this.insertListeners(".percentBars", "mouseout", this.onMouseOut.bind(this));
                  this.animateTitles(this._activeIndex);
          }
            _.delay(()=>{
                this.insertPercentLabels(this._activeIndex);
            },750);
 //         //this.insertRatingWord(this._activeIndex);

            this.insertShowsTitles();
            this._tlEntrance.play();

           // this.animateTitles(this._activeIndex);
        }

        private deviceVersion():void{
            console.log("deviceVersion");

            this.setMeasures();
            this.setStage();
            this.setXaxis();
            this.setYaxis();
            this.setBars();
            if(this._bGWidht>500){
                this.insertBarsNoAnim();
            }else{
                this.insertBarsNoAnimSmall();
            }
            //this.insertBars(10, this._bGHeight, "graphBars");
            //this.animateDynamicBars();
            if(this._bGWidht< 450){
                this.insertListeners(".graphBars", "click", this.onClick.bind(this));
                this.animateTitles(this._activeIndex);
            }
            this.insertPercentLabels(this._activeIndex);

            this.insertShowsTitles();

            this._tlEntrance.play();
        }

        private setStage():void{
            console.log("setStage");

            var heightOfStaticBars:number= 84;
            this._bGHeight= 200-60;

            this._stage = d3.select('#'+ this.containerName).append('svg')
                .attr('width', this._bGWidht)
                .attr('class', "bar-chart")
                .attr('height', this._bGHeight+ 60);
        }

        private setXaxis():void{
            console.log("setXaxis");

            this._xAxis= d3.scale.ordinal()
                .rangeRoundBands([0, this._bGWidht], 0.025, 0)
                .domain(this._allActualValues.map(function(d, i) {return i; }));
        }

        private setYaxis():void{
            console.log("setYaxis");

            var self= this;
            this._yAxis= d3.scale.linear()
                .range([self._bGHeight, 0])
                .domain([self._minCategoryValue, self._maxCategoryValue]);
        }

        private setBars():void{
            console.log("setBars");

            this._bars= this._stage.selectAll(".bar").data(this._allActualValues).enter()
                .append("g").attr("class", "bar");
        }


        private insertListeners(classSelected, event,functionToInvoque):void{
            console.log("insertListeners");

            d3.selectAll(classSelected).on(event, functionToInvoque);
        }

        private animateDynamicBars():void{
           console.log("animateDynamicBars")

            var self= this;
            d3.selectAll(".graphBars").transition()
                .attr("height", function(d) { return (self._bGHeight - self._yAxis(d[1]))+60; })
                .attr("y", function(d) { return (self._yAxis(d[1])); })
                .ease("out")
                .duration(350)
                .delay(function(d, i){return i*100});
        }

        private insertBars(height, y, className):void{
            console.log("insertBars");

            var self=this;

            this._bars.append("rect")
                .attr("class", className)
                .attr("x", function(d, i) { if(self._bGWidht<450){
                        if(i==self._activeIndex){
                            return self._xAxis(i)-self._activeIndex*20;
                        }if(i==0){
                            return self._xAxis(i);
                        }else{
                            if(i>0 && i < self._activeIndex ){
                                return self._xAxis(i)-(i*20);
                            } else{
                                return self._xAxis(i)+(100-(i*20));
                            }
                        }
                    }else{
                        return self._xAxis(i);
                    }
                })
                .attr("y", y+60)
                .attr("width", function(d, i){ if(self._bGWidht < 450){
                        if(i==self._activeIndex){ return self._xAxis.rangeBand()+80 }else{return self._xAxis.rangeBand()-20}
                    }else{
                        return self._xAxis.rangeBand();
                    }
                })
                //.each(function(d:any) { self._current.push(d); })
                .attr("height", height)
                .attr('fill', function(d, i){
                    return self._theColors[i];
                })
        }


        private animateTitles(index):void{

            console.log("animateTitles");

                var smallWidth:number= 0;
                var bigWidth:number= 0;
                if(this._bGWidht== 280){
                    smallWidth= 35;
                    bigWidth= 135;
                }if(this._bGWidht== 400){
                    smallWidth= 58;
                    bigWidth= 158;
                }
                for(var i:number=1; i<=5; i++){
                    if(i!= index+1){
                        var smaller=  $(".rank-bar-graph-titles li:nth-child(" + i + ")");
                        var smallText=  $(".rank-bar-graph-titles li:nth-child(" + i + ") .rank-show-data");
                        TweenMax.to(smaller, 0.1, {width: smallWidth});
                        TweenMax.to(smallText, 0.1, {width: 0});
                    }
                }

                var bigger=  $(".rank-bar-graph-titles li:nth-child(" + (index+1) + ")");
                var bigText=  $(".rank-bar-graph-titles li:nth-child(" + (index+1) + ") .rank-show-data");
                TweenMax.to(bigger, 0.1, {width: bigWidth});
                TweenMax.to(bigText, 0.1, {width: bigWidth});

        }


        private resetWidthTitles():void{
            console.log("resetWidthTitles");

            var container= $(".rank-bar-graph-titles li");
            var titles= $(".rank-bar-graph-titles li .rank-show-data");
            if(this._bGWidht== 590){
                TweenMax.to(container, 0.1, {width: 112});
                TweenMax.to(titles, 0.1, {width: 112});
            }if(this._bGWidht== 800){
                TweenMax.to(container, 0.1, {width: 152});
                TweenMax.to(titles, 0.1, {width: 152});
            }
        }

        private onClick(d, i):void{
            console.log("onClick");

            this._activeIndex= i;
            var self= this;

            this.animateTitles(this._activeIndex);

            d3.selectAll(".bar text").remove();


            d3.selectAll(".graphBars")
                .transition()
                .attr("x", function(d, i) {
                    if(i==self._activeIndex){
                        return self._xAxis(i)-self._activeIndex*20;
                    }if(i==0){
                        return self._xAxis(i)
                    }else{
                        if(i>0 && i < self._activeIndex ){
                            return self._xAxis(i)-(i*20)
                        } else{
                            return self._xAxis(i)+(100-(i*20))
                        }
                    }})
                .attr("width", function(d, i) { if(i==self._activeIndex){ return self._xAxis.rangeBand()+80}else{return self._xAxis.rangeBand()-20}})

            _.delay(()=>{
                this.insertPercentLabels(this._activeIndex);
            },100);
        }

        private onMouseOver(d, index){
            var self= this;
            //this._activeIndex= index;

            this.animateTitles(index);

            d3.selectAll(".bar text").remove();

            this.insertPercentLabels(index);

            d3.selectAll(".graphBars")
                .transition()
                .attr("x", function(d, i) {
                    if(i==index){
                        return self._xAxis(i)-index*20;
                    }if(i==0){
                        return self._xAxis(i)
                    }else{
                        if(i>0 && i < index ){
                            return self._xAxis(i)-(i*20)
                        } else{
                            return self._xAxis(i)+(100-(i*20))
                        }
                    }})
                .attr("width", function(d, i) { if(i==index){ return self._xAxis.rangeBand()+80}else{return self._xAxis.rangeBand()-20}})
        }

        private onMouseOut(){
            var self= this;

            this.animateTitles(this._activeIndex);

            d3.selectAll(".bar text").remove();

            this.insertPercentLabels(this._activeIndex);

            d3.selectAll(".graphBars")
                .transition()
                .attr("x", function(d, i) {
                    if(i==self._activeIndex){
                        return self._xAxis(i)-self._activeIndex*20;
                    }if(i==0){
                        return self._xAxis(i)
                    }else{
                        if(i>0 && i < self._activeIndex ){
                            return self._xAxis(i)-(i*20)
                        } else{
                            return self._xAxis(i)+(100-(i*20))
                        }
                    }})
                .attr("width", function(d, i) { if(i==self._activeIndex){ return self._xAxis.rangeBand()+80}else{return self._xAxis.rangeBand()-20}})

        }

        private insertLabels():void{
            console.log("insertLabels");

            var self= this;
            this._bars.append("text").text(function(d){ return d3.format(",")(d[1])})
                .attr("x", function(d, i) { return self._xAxis(i)+self._xAxis.rangeBand()/2; })
                .attr("y", self._bGHeight+46)
                .attr("font-size", "38")
                .attr("font-weight", "700")
                .attr("style", "fill:white")
                .attr("text-anchor", "middle")
        }
        private formatFloat(num: number) {
            var sFormat = num.toFixed(5);
            var dotIndex = sFormat.toString().indexOf('.');
            return sFormat.substr(0, dotIndex + 2);
        }

        private insertPercentLabels(index):void{
            console.log("insertPercentLabels");

            var self= this;
            d3.selectAll(".bar").append("text")
                .text(function(d, i){
                    var original = d[1];

                    return self.formatFloat(original);

                })
                .attr("x", function(d,i) {if(self._bGWidht< 450){
                        if(self._bGWidht< 400){return 67.5+(i*35)+(i)}else{ return 76+(i*58)+(i*2)}
                    }else{
                        console.log(self._xAxis(i)+self._xAxis.rangeBand()/2);
                        return self._xAxis(i)+self._xAxis.rangeBand()/2;
                    }
                })
                .attr("y", self._bGHeight+40)
                .attr("font-size", "36")
                .attr("class", "textData")
                .attr("font-weight", "600")
                .attr("style", "fill:white")
                .attr("text-anchor", "middle")
        }

        //private insertRatingWord(index){
        //    var self= this;
        //    d3.selectAll(".bar").append("text")
        //        .text(function(d, i){ if(self._bGWidht< 450){
        //            if(i==index){ return "Rating"}
        //        }else{
        //            return "Rating";
        //        }
        //        })
        //        .attr("x", function(d,i) {if(self._bGWidht< 450){
        //            if(self._bGWidht< 400){return 67.5+(i*35)+(i)}else{ return 76+(i*58)+(i*2)}
        //        }else{
        //            return self._xAxis(d[0])+self._xAxis.rangeBand()/2;
        //        }
        //        })
        //        .attr("y", self._bGHeight+59)
        //        .attr("font-size", "12")
        //        .attr("class", "textRat")
        //        .attr("font-weight", "700")
        //        .attr("style", "fill:white")
        //        .attr("text-anchor", "middle")
        //}


        public updateData():void{
            console.log("updateData");

            var self = this;
            this._yAxis.domain([self._minCategoryValue, self._maxCategoryValue]);



            // Attach the new data to the bars.
            this._bars= this._stage.selectAll(".bar").data(this._allActualValues);


            // transition the height and color of rectangles.
            if(this._version== "desktop"){
                this._bars.select("rect").transition().duration(500)
                    .attr("y", function(d) { return (self._yAxis(d[1])); })
                    .attr("height", function(d) { return (self._bGHeight - self._yAxis(d[1]))+60; });
                // transition the frequency labels location and change value.

                if(this._bGWidht> 450){
                    this._bars.select("text").transition().duration(500)
                        .text(function(d){ return d3.format(",")(d[1])})
                }else{
                    d3.selectAll(".bar text").remove();
                    this.insertPercentLabels(this._activeIndex);
                }
            }else{
                this._bars.select("rect")
                    .attr("y", function(d) { return (self._yAxis(d[1])); })
                    .attr("height", function(d) { return self._bGHeight - self._yAxis(d[1]); });
                d3.selectAll(".bar text").remove();
                this.insertPercentLabels(this._activeIndex);
            }


        }

        public updateCategory():void{
            console.log("updateCategory");


            if(this._version== "desktop"){
                this.exitAnim();
            }else{
                if(this._bGWidht < 450){
                    this.updateGraphSmall();
                }else{
                    this.updateGraph();
                }
            }
        }



        private unbinds():void{
            console.log("unbinds");

            //$(window).unbind("resize");
            $(".infographic-tooltip .close").unbind("click");
        }

        public hideElements():void{
            console.log("hideElements")

            TweenLite.set([this._thaTitle, this._thaGraphCont, this._tha1Place, this._tha2Place, this._tha3Place, this._tha4Place, this._tha5Place ], {autoAlpha : 0});
            //this.$tlEntrance.reverse();
        }


        private exitAnim():void{
            console.log("exitAnim");

            var self= this;
            console.log(this._allActualValues);
            //this._xAxis.domain(this._allActualValues.map(function(d, i) { return i; }));
            this._yAxis.domain([self._minCategoryValue, self._maxCategoryValue]);

            console.log(this._xAxis.rangeBand());
            console.log(this._xAxis.domain(), "riiiin");
            // Attach the new data to the bars.
            this._bars= this._stage.selectAll(".bar").data(this._allActualValues);

            if(this._version== "desktop"){
                this._bars.select("rect").transition().duration(500)
                    .attr("height", function(d) { return (self._bGHeight - self._yAxis(d[1]))+60; })
                    .attr("y", function(d) { return (self._yAxis(d[1])); })
                //d3.selectAll(".bar text").transition()
                //    .tween("text",this._arcTweenPercent.bind(this));
            }


            //if(this._bGWidht> 450){
            //    this._bars.select("text").transition().duration(500)
            //        .text(function(d){ return d3.format(",")(d[1])})
            //}else{
            //    d3.selectAll(".bar text").remove();
            //    this.insertPercentLabels(this._activeIndex);
            //}

                d3.selectAll(".bar text").remove();
                this.insertPercentLabels(this._activeIndex);

            this.insertShowsTitles();

        }

        //private _arcTweenPercent(a, x): (t:any) => string {
        //    var self = this;
        //    console.log(self._current[x], a , "@@@@@@si@@");
        //    var i = d3.interpolate(self._current[x], a);
        //    self._current[x]= i(0);
        //    return function(t) {
        //        self._current = i(t);
        //        this.textContent = Math.round(i(t));
        //    };
        //}


        private tooltipClose():void{
            console.log("tooltipClose");

            var self= this;
            $(".infographic-tooltip .close").on("click", function(){
                TweenLite.to(self._thaTooltip, .1, {autoAlpha: '0', "display": "none"});
                //TweenLite.set(this.getPanel(), {top: '-100%'});
            })
        }

        public tooltipReset(){
            TweenLite.to(this._thaTooltip, .05, {autoAlpha :0})
        }

        public reverseAnim():void{
            console.log("reverseAnim");

            this.tlExit();
        }

        private tlExit():void{
            TweenLite.to([this._thaGraphCont,
                            this._tha1Place,
                            this._tha2Place,
                            this._tha3Place,
                            this._tha4Place,
                            this._tha5Place,
                            this._thaTitle,
                            this._thaControlls,
                            this._thaFooter],.25, {autoAlpha : 0});
            TweenLite.to(this._thaTooltip, .05, {autoAlpha :0})
        }



        private get _tlEntrance (): TimelineLite {

            if(this.$tlEntrance == null){
                this.$tlEntrance = new TimelineLite({autoStart: false, ease:Cubic.easeOut});

                this.$tlEntrance.to(this._thaGraphCont, .05, {autoAlpha: 1} );
                this.$tlEntrance.to(this._tha1Place, .12, {autoAlpha: 1} );
                this.$tlEntrance.to(this._tha2Place, .12, {autoAlpha: 1});
                this.$tlEntrance.to(this._tha3Place, .12, {autoAlpha: 1} );
                this.$tlEntrance.to(this._tha4Place, .12, {autoAlpha: 1} );
                this.$tlEntrance.to(this._tha5Place, .12, {autoAlpha: 1});
                this.$tlEntrance.to(this._thaTitle, .15, {autoAlpha: 1}, "+=.15");
                this.$tlEntrance.to(this._thaControlls, .15, {autoAlpha: 1}, "-=.15");
                this.$tlEntrance.to(this._thaFooter, .15, {autoAlpha: 1} ).call(()=>{
                    TweenLite.to(this._thaTooltip, .05, {autoAlpha: .8, delay:".3" ,display: "block"} );
                })
                //this.$tlEntrance.to(this._thaTooltip, .05, {autoAlpha: .8, delay: .8, display: "block"} );

            }
            return this.$tlEntrance;
        }




        private $tlEntrance: TimelineLite = null;

    }
}