/**
 * Created by raul on 10/16/14.
 */

/// <reference path="./../events/RoutesEvents.ts" />
/// <reference path="./../utils/statistics/analytics.ts" />

module media.hrratings.commands {

    import RoutesEvents = media.hrratings.events.RoutesEvents;
    import Analytics    = media.hrratings.utils.statistics.Analytics;

    export class SetupRoutesCommand {

        dispatcher:soma.EventDispatcher = null; //injected

        execute() {

            routie({
                '' : () => {
                    Analytics.gaSendPageView('home');
                    this.dispatcher.dispatch(RoutesEvents.HOME_ROUTE, {});
                },
                'contact' : () => {
                    Analytics.gaSendPageView('contact');
                    this.dispatcher.dispatch(RoutesEvents.CONTACT_ROUTE);
                },


                'privacy-web' : () => {
                    Analytics.gaSendPageView('privacy-web');
                    this.dispatcher.dispatch(RoutesEvents.LEGALS_WEB_ROUTE);
                },
                'privacy-es' : () => {
                    Analytics.gaSendPageView('privacy-es');
                    this.dispatcher.dispatch(RoutesEvents.LEGALS_ES_ROUTE);
                },
                'privacy-panel' : () => {
                    Analytics.gaSendPageView('privacy-panel');
                    this.dispatcher.dispatch(RoutesEvents.LEGALS_PANEL_ROUTE);
                },


                'company/:section?' : (section) => {
                    Analytics.gaSendPageView('company/' + section );
                    this.dispatcher.dispatch(RoutesEvents.ABOUT_ROUTE, {data: section});
                },
                'services/:section?' : (section) => {
                    Analytics.gaSendPageView('services/' + section );
                    this.dispatcher.dispatch(RoutesEvents.SERVICES_ROUTE, {data: section});
                },
                'methodology/:section?/:subsection?' : (section, subsection) => {
                    Analytics.gaSendPageView('methodology/' + section );
                    this.dispatcher.dispatch(RoutesEvents.METHODOLOGY_ROUTE, {section: section, subsection: subsection});
                },




                'our-information': ()=> {
                    this.dispatcher.dispatch(RoutesEvents.OUR_INFORMATION_ROUTE);
                },
                'our-information/press': ()=> {
                    Analytics.gaSendPageView('our-information/press');
                    this.dispatcher.dispatch(RoutesEvents.PRESS_ROUTE);
                },
                'our-information/weekly/:year?/:id?': (year, week) => {
                    Analytics.gaSendPageView('our-information/weekly/' + year + '/' + week);
                    this.dispatcher.dispatch(RoutesEvents.REPORTS_ROUTE, { year: year, week: week});
                },
                'our-information/charts/:year?/:week?' : (year, week) => {
                    Analytics.gaSendPageView('our-information/charts/' + year + '/' + week);
                    this.dispatcher.dispatch(RoutesEvents.INFOGRAPHICS_ROUTE, {year: year, week: week});
                },




                'profile our-information/charts/:year?/:id?/profile' : (year, id) => {
                    Analytics.gaSendPageView('our-information/charts/' + year + '/' + id + '/profile');
                    this.dispatcher.dispatch(RoutesEvents.INFOGRAPHIC_PROFILE_ROUTE , {year: year, itemId: id});
                },
                'ranking our-information/charts/:year?/:id?/ranking' : (year, id) => {
                    Analytics.gaSendPageView('our-information/charts/' + year + '/' + id + '/ranking');
                    this.dispatcher.dispatch(RoutesEvents.INFOGRAPHIC_RANKING_ROUTE , {year: year, itemId: id});
                },
                'vs our-information/charts/:year?/:id?/vs' : (year, id) => {
                    Analytics.gaSendPageView('our-information/charts/' + year + '/' + id + '/vs');
                    this.dispatcher.dispatch(RoutesEvents.INFOGRAPHIC_VS_ROUTE, {year: year, itemId: id});
                }
            });
        }
    }
}
