/**
 * Created by Ivan on 5/21/15.
 */

/// <reference path="../base/Filter.ts" />

module media.hrratings.filters {
    export class ChartFilter extends Filter {

        constructor() {
            super();
        }

        public fetchItems(lang: string) {
            this.updateAPIItems(()=> {
                return this.APIService.getChartItems(lang);
            });
        }

        protected addFilterItems(data: any) {
            _.each(data, (entry: any)=> {
                this.children.push(new ChartFilterItem(entry));
            });
        }

        private normalizeTriad(chartItems: Array<ChartFilterItem>): Array<ChartFilterItem> {
            console.log('normalizing triad:', chartItems);
            var triad = _.last(chartItems, 3);
            var typeGroups = _.groupBy(triad, function (child:FilterItem) { return child.type; });
            var isNormalized = triad.length === 3 && _.size(typeGroups) === 3;
            if (! isNormalized) {
                console.warn('Triad results are not normalized:', triad);
            }
            return triad;
        }

        public getTriadByDate( year: number, week: number): Array<ChartFilterItem> {
            var triad:Array<ChartFilterItem> = this.getItemsBy(year, week);
            return this.normalizeTriad(triad);
        }

        public getLastChartsTriad(): Array<ChartFilterItem> {
            if (this.itemsReady) {
                var lastPubParams = this.getLastPublicationParams();
                var triad:Array<ChartFilterItem> = this.getItemsBy(lastPubParams.year, lastPubParams.week);
                return this.normalizeTriad(triad);
            }
        }

        public getTriadUrlOf(item: FilterItem) {
            return '#our-information/charts/' + item.date.year() + '/Week' + item.week;
        }

    }

}