/**
 * Created by Esteban on 10/28/14.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/InfographicRankingView.ts" />

module media.hrratings.commands {

    import ViewManager = core.ViewManager;
    import InfographicRankingView = views.InfographicRankingView;

    export class OpenInfographicRankingCommand {

        mainViewManager: ViewManager = null;

        execute (event) {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenInfographicRankingCommand.execute!");
            console.log("ID infographic Ranking:", event.params.year);
            console.log("ID infographic Ranking:", event.params.itemId);

            if(!this.mainViewManager.isViewOpen(MainViews.InfographicRanking) ){
                this.mainViewManager.openView(MainViews.InfographicRanking);
            }
            var infographicView: InfographicRankingView = <InfographicRankingView>this.mainViewManager.getViewById(MainViews.InfographicRanking);
            infographicView.show(event.params.itemId, event.params.year);
        }
    }
}
