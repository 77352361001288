module media.hrratings.icons {

    export class Ranking {
        private _stage;
        private _widht;
        private _height;
        private _xAxis;
        private _yAxis;
        private _bars;
        private _arrays;
        private _theColors;

        constructor(private _container){

            this._widht= 136;
            this._height= 125;

            this._arrays = [[96,75,60,40,28], [80,55,90,60,35]];
            this._theColors= ["#ff4d79", "#ff4d5b", "#ff5b4d", "#ff794d", "#ffa64d"];

            this.create();
        }

        private create():void {
            this.setStage();
            this.setXAxis();
            this.setYAxis();
            this.setBars();
            this.insertBars();
        }

        private setStage():void {
            this._stage = d3.select(this._container).append('svg')
                .attr('width', this._widht)
                .attr('height', this._height)
                .attr('class', "rank-icon")
        }

        private setXAxis(){
            this._xAxis= d3.scale.ordinal()
                .rangeRoundBands([0, this._widht], 0.04, 0)
                .domain([0,1,2,3,4]);
        }

        private setYAxis(){

            var self = this;

            this._yAxis = d3.scale
                .linear()
                .range([self._height, 0])
                .domain([0, 100]);
        }

        private setBars():void{

            this._bars= this._stage
                .selectAll(".bar-icon")
                .data(this._arrays[0])
                .enter()
                .append("g").attr("class", "bar-icon");
        }

        private insertBars():void{

            this._bars.append("rect")
                .attr("class", "icon-rect")
                .attr("x",      (d, i)  => { return this._xAxis(i);                 } )
                .attr("y",      (d, i)  => { return this._yAxis(d);                 } )
                .attr("width",  ()      => { return this._xAxis.rangeBand();        } )
                .attr("height", (d)     => { return this._height - this._yAxis(d);  } )
                .attr('fill',   (d, i)  => { return this._theColors[i]              } );
        }

        pathTrans (arraynumber:number) {

            this._bars = this._stage
                .selectAll(".bar-icon")
                .data(this._arrays[arraynumber]);

            this._bars
                .select("rect")
                .transition()
                .duration(500)
                .attr("y",      (d) => { return this._yAxis(d);                 })
                .attr("height", (d) => { return this._height - this._yAxis(d)   });
        }

    }
}