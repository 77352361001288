/**
 * Created by had on 3/3/15.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./AbstractDialogView.ts" />
/// <reference path="../events/MethodologyEvents.ts" />

/// <reference path="./methodology/TelevisionMeasurement.ts" />
/// <reference path="./methodology/AdMonitoring.ts" />
/// <reference path="./methodology/Tam5500.ts" />
/// <reference path="./methodology/Tam300.ts" />

/// <reference path="../events/MenuEvents.ts" />

module media.hrratings.views {

    import ViewManager              = media.hrratings.core.ViewManager;


    import TelevisionMeasurement    = media.hrratings.views.methodology.TelevisionMeasurement;
    import AdMonitoring             = media.hrratings.views.methodology.AdMonitoring;
    import Tam5500                  = media.hrratings.views.methodology.Tam5500;
    import Tam300                   = media.hrratings.views.methodology.Tam300;


    import MethodologyEvents        = media.hrratings.events.MethodologyEvents;
    import MenuEvents               = media.hrratings.events.MenuEvents;
    import clientInfo               = media.hrratings.utils.browser.ClientInfo;

    export enum MenuMethodology {
        TelevisionMeasurement,
        AdMonitoring,
        Tam5500,
        Tam300,
    }

    export class MethodologyView extends AbstractDialogView {

        private _elPanel: HTMLElement;

        constructor(_target: HTMLElement) {

            super(_target);
            this._cachedElements();
            this._setup();
        }

        private _setup(): void {

            this._tabManager.openView(MenuMethodology.TelevisionMeasurement);
            this._tabManager.closeCurrentView();
        }

        private _cachedElements(): void {

            this._elPanel = $(this.target).find('#methodology').get(0);
        }

        private _bind(): void {
            this.dispatchEvent(MethodologyEvents.METHODOLOGY_DOCUMENTS);
        }

        getPanel (): HTMLElement {

            return this._elPanel;
        }

        setupBindings () : void {
            this._bind();
            ko.applyBindings(this, this._elPanel);
        }

        setupDocs (data: any) : void {
            console.log("METHODOLOGY", data);
            var html = "";
            for (var i = 0; i < data[0].TAM300.length; i++) {
                var row = "";
                for (var j = 0; j < data[0].TAM300[i].docs.length; j++) {
                    var p = "<p>"+data[0].TAM300[i].docs[j].date+"</p><p>"+data[0].TAM300[i].docs[j].title+"</p>"
                    row = row + "<li data-title='"+data[0].TAM300[i].docs[j].title+"' data-filetext='"+$.i18n.translate('tr-press-download-file')+"' data-file='"+data[0].TAM300[i].docs[j].file+"' data-content='"+JSON.stringify(data[0].TAM300[i].docs[j].description)+"'>"+p+"</li>";
                }
                html = html + "<div class='row'><strong data-title='"+data[0].TAM300[i].title+"' data-content='"+JSON.stringify(data[0].TAM300[i].description)+"'>"+data[0].TAM300[i].title+"</strong><ul>"+row+"</ul></div>";
            }
            $("#tabs-cols-tam300 .col:first-child").append(html);

            $("#tabs-cols-tam300")["tabsCols"]();

            html = "";
            for (var i = 0; i < data[1].TAM5500.length; i++) {
                var row = "";
                for (var j = 0; j < data[1].TAM5500[i].docs.length; j++) {
                    var p = "<p>"+data[1].TAM5500[i].docs[j].date+"</p><p>"+data[1].TAM5500[i].docs[j].title+"</p>"
                    row = row + "<li data-title='"+data[1].TAM5500[i].docs[j].title+"' data-filetext='"+$.i18n.translate('tr-press-download-file')+"' data-file='"+data[1].TAM5500[i].docs[j].file+"' data-content='"+JSON.stringify(data[1].TAM5500[i].docs[j].description)+"'>"+p+"</li>";
                }
                html = html + "<div class='row'><strong data-title='"+data[1].TAM5500[i].title+"' data-content='"+JSON.stringify(data[1].TAM5500[i].description)+"'>"+data[1].TAM5500[i].title+"</strong><ul>"+row+"</ul></div>";
            }
            $("#tabs-cols-tam5500 .col:first-child").append(html);

            $("#tabs-cols-tam5500")["tabsCols"]();
        }

        /**
         *
         * @param id
         */
        showTab (id: string, subsection?: string): void {

            if(subsection != undefined){
                switch (subsection) {
                    case 'tam-5500' : {
                        this._tabManager.openView(MenuMethodology.Tam5500 );
                        this.dispatchEvent(MenuEvents.MENU_HIDE);
                        break;
                    }
                    case 'tam-300' : {
                        this._tabManager.openView(MenuMethodology.Tam300 );
                        this.dispatchEvent(MenuEvents.MENU_HIDE);
                        break;
                    }
                    default: {
                        this._tabManager.openView(MenuMethodology.TelevisionMeasurement);
                        this.dispatchEvent(MenuEvents.MENU_HIDE);
                        break;
                    }
                }
            }
            else{
                switch (id) {
                    case 'television-measurement' : {
                        this._tabManager.openView(MenuMethodology.TelevisionMeasurement);
                        this.dispatchEvent(MenuEvents.MENU_HIDE);
                        break;
                    }
                    case 'ad-monitoring' : {
                        this._tabManager.openView(MenuMethodology.AdMonitoring );
                        this.dispatchEvent(MenuEvents.MENU_HIDE);
                        break;
                    }
                    default: {
                        this._tabManager.openView(MenuMethodology.TelevisionMeasurement);
                        this.dispatchEvent(MenuEvents.MENU_HIDE);
                        break;
                    }
                }
            }
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {

            this._tabManager.closeCurrentView();

            setTimeout( () => {
                TweenLite.to(this.getPanel(), 0.15,{autoAlpha: 0, ease:Cubic.easeOut, onComplete: () => {
                    TweenLite.set(this.getPanel(), {top: '-100%'});
                    TweenLite.set(this.target, {css:{display:'none'}});

                    this._tabManager.reset();
                    d.resolve();
                }});

                $(this._elPanel).scrollTop(0);
            }, 150);
        }

        /**
         *
         * @returns {ViewManager}
         * @private
         */
        private get _tabManager (): ViewManager {

            if (this.$tabManager == null) {

                this.$tabManager = new ViewManager();

                this.$tabManager.addView(MenuMethodology.TelevisionMeasurement, new TelevisionMeasurement('#ffa64d', this._elPanel, $(this._elPanel).find(".tab-1").get(0)));
                this.$tabManager.addView(MenuMethodology.AdMonitoring,          new AdMonitoring         ('#ff7a4d', this._elPanel, $(this._elPanel).find(".tab-2").get(0)));
                this.$tabManager.addView(MenuMethodology.Tam5500,               new Tam5500              ('#f15b4d', this._elPanel, $(this._elPanel).find(".tab-3").get(0)));
                this.$tabManager.addView(MenuMethodology.Tam300,                new Tam300               ('#f15b4d', this._elPanel, $(this._elPanel).find(".tab-4").get(0)));

            }
            return this.$tabManager;
        }

        private $tabManager: ViewManager = null;
    }
}