/**
 * Created by Esteban on 10/21/14.
 */

/// <reference path="./../core/ViewManager.ts" />

module media.hrratings.commands {

    import ViewManager = core.ViewManager;

    export class OpenHomeCommand {

        mainViewManager: ViewManager = null; //injected

        execute () {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenHomeCommand.execute!");

            if(!this.mainViewManager.isViewOpen(MainViews.Home) ){
                this.mainViewManager.openView(MainViews.Home);
            }
        }
    }
}