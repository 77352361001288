/**
 * Created by Ivan on 4/14/15.
 */

/// <reference path="../views/PressView.ts" />
/// <reference path="../events/ApplicationEvent.ts" />

module media.hrratings.mediators {

    import EventDispatcher = soma.EventDispatcher;
    import LocaleEvent = media.hrratings.events.LocaleEvent;
    import PressView = media.hrratings.views.PressView;
    import ApplicationEvent = media.hrratings.events.ApplicationEvent;
    import HRServices = media.hrratings.services.HRServices;

    export class PressViewMediator {

        static inject: string[] = ["target", "dispatcher", "service", "lang"];

        constructor(target: PressView, dispatcher: EventDispatcher, service: HRServices, lang: string) {
            dispatcher.addEventListener(LocaleEvent.LOCALE_READY, function () {
                target.setupBindings();

                service.getPressItems(lang).then(function(data) {
                    target.loadJSONData(data);
                });

            });


        }
    }
}