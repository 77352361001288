/**
 * Created by Ivan on 3/11/15.
 */

module media.hrratings.filters {

    export class FilterItem {
        date: Moment;
        title: string;
        dateSpan: string;
        type: string;
        tags: string;
        href: string;
        chartHref: string;
        fileSrc: string;
        enabled: boolean;
        week: number;

        constructor(jsonEntry: any) {
            this.title = jsonEntry.title;
            this.date = moment(<string>jsonEntry.publishDate, 'DD-MM-YYYY');
            this.type = jsonEntry.type;
            this.dateSpan = jsonEntry.timeSpanTitle;
            this.tags = jsonEntry.tags;
            this.href = jsonEntry.href;
            this.fileSrc = 'http://' + window.location.host + '/' + jsonEntry.downloadfile;
            this.enabled = false;
            this.week = this.getWeekFromLink(this.href) | 0;

        }

        protected getWeekFromLink(link: string): number {
            var match:any =  link.match(/Week([0-9]+)/);
            return match && match[1] || -1;
        }

        static getYearFromLink(link: string): number {
            var match:any =  link.match(/\/([0-9]+)\//);
            return match[1];
        }

        public isTypeOf(types: Array<string>) {
            return types && types.length == 0 || types.indexOf(this.type) != -1;
        }



    }

}