/**
 * Created by had on 3/3/15.
 */
/**
 * Created by Esteban on 10/20/14.
 */

/// <reference path="./../AbstractServicesTab.ts" />

module media.hrratings.views.methodology {

    import AbstractServicesTab = media.hrratings.views.AbstractServicesTab;
    export class AdMonitoring extends AbstractServicesTab {}
}
