/**
 * Created by Esteban on 10/20/14.
 */

/// <reference path="./../AbstractAboutTab.ts" />

module media.hrratings.views.about {

    import AbstractAboutTab = views.AbstractAboutTab;
    export class StrategicApproach extends AbstractAboutTab {}
}
