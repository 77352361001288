/**
 * OpenServicesMethodologyCommand
 * Created by Esteban on 02/25/15.
 */

/// <reference path="./../core/ViewManager.ts" />
/// <reference path="./../views/ServicesView.ts" />

module media.hrratings.commands {

    import ViewManager = core.ViewManager;
    import ServicesView = views.ServicesView;

    export class OpenServicesMethodologyCommand {

        mainViewManager: ViewManager = null; //injected

        execute (event) {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenServicesCommand.execute!");
            console.log("data: ", event.params.data);
            console.log("submenu: ", event.params.subsection);

            if(!this.mainViewManager.isViewOpen(MainViews.Services) ){
                this.mainViewManager.openView(MainViews.Services);
            }

            var servicesView: ServicesView = <ServicesView>this.mainViewManager.getViewById(MainViews.Services);
            servicesView.showTab(event.params.data, event.params.subsection);
        }
    }
}
