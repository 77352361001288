/// <reference path="./../core/ViewManager.ts" />

module media.hrratings.commands {

    import ViewManager = media.hrratings.core.ViewManager;

    export class OpenLegalsPanelCommand {

        mainViewManager: ViewManager = null; //injected

        execute () {

            console.log("::::::::::::::::::::::::::::");
            console.log("OpenLegalsPanelCommand.execute!");

            if(!this.mainViewManager.isViewOpen(MainViews.LegalsPanel) ){
                this.mainViewManager.openView(MainViews.LegalsPanel);
            }
        }
    }
}