/**
 * Created by Ivan on 4/14/15.
 */
/// <reference path="./AbstractDialogView.ts" />

interface IPressEntry {
    date: string;
    publishDate: boolean;
    narrative: string;
    downloadfile: string;
}

module media.hrratings.views {

    import ViewManager = media.hrratings.core.ViewManager;
    import HRServices = media.hrratings.services.HRServices;
    import RoutesEvents     = media.hrratings.events.RoutesEvents;

    export class PressView extends AbstractDialogView {

        private timeline: TimelineLite;

        private _elPanel: HTMLElement;
        private _pageUp: HTMLElement;
        private _pageDown: HTMLElement;
        private _scrollList: HTMLElement;
        private _elTitleCol: HTMLElement;
        private _currentPageIndex: number;

        public koPressEntries: KnockoutObservableArray<IPressEntry>;

        private MAX_ENTRIES_PER_PAGE = 3;
        private ANIMATION_SHOW = 2;
        private ANIMATION_HIDE = 3;
        private DIRECTION_TOP = 4;
        private DIRECTION_DOWN = 5;

        constructor(_target: HTMLElement) {
            super(_target);
            this._currentPageIndex = 0;
            this.koPressEntries = ko.observableArray([]);
            this.cacheElements();
        }

        setupBindings(): void {
            ko.applyBindings(this, this._elPanel);
            this.bindScroll(this._scrollList);

        }

        open_hook (d:Q.Deferred<void>):void {
            this.hideAll();
            this.showView().then(()=> {
                TweenLite.set(this._elPanel, {top: '0px', autoAlpha: 1});
                this.timeline.play(0);
                d.resolve();
            });
        }

        private getLastThreeNews() {
            return  $(this._scrollList).find('li').slice(0, 3);
        }

        private toggleAnimationItem(itemIndex: number, animationType: number, directionType: number) {
            var item = $(this._scrollList).find('li').get(itemIndex);
            var height = $(item).height();
            switch (animationType) {
                case this.ANIMATION_SHOW:
                    TweenMax.to(item, 0.3, {
                        autoAlpha: 1,
                        top: directionType == this.DIRECTION_TOP ? 0 : 230,
                        marginTop: 20,
                        borderBottom: 1,
                        ease: Quad.easeOut
                    });
                    break;
                case this.ANIMATION_HIDE:
                    TweenMax.to(item, 0.4, {
                        autoAlpha: 0,
                        //height: 0,
                        marginTop: directionType == this.DIRECTION_DOWN ? -230 : 0,
                        borderBottom: 0,
                        ease: Quad.easeOut
                    });
                    break;
            }
        }

        private scrollPage(step: number) {
            var totalItems = $(this._scrollList).find('li').length;

            var idxFirstVisible = this._currentPageIndex;
            var idxLastVisible = idxFirstVisible + this.MAX_ENTRIES_PER_PAGE - 1;
            if (step  == 1 && this._currentPageIndex > 0) {
                    this._currentPageIndex--;
                    //console.log('UP', this._currentPageIndex);
                    //push from top
                    this.toggleAnimationItem(idxFirstVisible - 1, this.ANIMATION_SHOW, this.DIRECTION_DOWN);
                    //pop last from bottom
                    this.toggleAnimationItem(idxLastVisible, this.ANIMATION_HIDE, this.DIRECTION_TOP);
                    //console.log('-1');
            } else if (step == -1 && this._currentPageIndex <  totalItems - this.MAX_ENTRIES_PER_PAGE) {
                //inside boundaries?
                    this._currentPageIndex++;
                    //console.log('DOWN', this._currentPageIndex);
                    //pop from top
                    this.toggleAnimationItem(idxFirstVisible, this.ANIMATION_HIDE, this.DIRECTION_DOWN);
                    //push from bottom
                    this.toggleAnimationItem(idxLastVisible + 1, this.ANIMATION_SHOW, this.DIRECTION_TOP);
            }

            $(this._pageUp).toggleClass('enabled', this._currentPageIndex > 0);
            $(this._pageDown).toggleClass('enabled', this._currentPageIndex <  totalItems - this.MAX_ENTRIES_PER_PAGE);
        }

        private bindScroll(element: HTMLElement): void {

            $(this._scrollList).find('li').each( (index: number, item: Element)=> {
                var showValue = index < this.MAX_ENTRIES_PER_PAGE ? 1 : 0;
                TweenMax.set(item, {autoAlpha: showValue , height: showValue * 100 + '%', marginTop: showValue * 20});
            });

            $(this._pageUp).click((e: JQueryEventObject)=> {
                e.preventDefault();
                $(this._pageUp).hasClass('enabled') && this.scrollPage(1);
            });

            $(this._pageDown).click((e: JQueryEventObject)=> {
                e.preventDefault();
                $(this._pageDown).hasClass('enabled') && this.scrollPage(-1);
            });

        }

        close_hook (d:Q.Deferred<void>):void {
            this.hideView().then(()=> {
                TweenLite.set(this._elPanel, {autoAlpha: 0, top: '-100%'});
                d.resolve();
            });
        }

        private cacheElements (): void {
            this._elPanel = $(this.target).find('#press').get(0);
            this._scrollList = $(this.target).find('ul.news-list').get(0);
            this._pageUp = $(this.target).find('.arrow.up').get(0);
            this._pageDown = $(this.target).find('.arrow.down').get(0);
            this._elTitleCol = $(this.target).find('header.col h2').get(0);
        }

        private resetScrollPaging() {
            this._currentPageIndex = 0;
            $(this._pageUp).toggleClass('enabled', false);
            $(this._pageDown).toggleClass('enabled', $(this._scrollList).find('li').length > 3);

            TweenLite.set($(this._scrollList).find('li'), {
                autoAlpha: 0,
                top: 0,
                marginTop: 20
            });
        }

        private hideAll() {
            this.resetScrollPaging();
            TweenLite.set([
                this._elTitleCol,
            ], {autoAlpha: 0});
        }

        private createTimeline() {
            var e = Cubic.easeOut;
            this.timeline = new TimelineLite();

            this.timeline.fromTo(this._elTitleCol, 0.25,
                {y: 30, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: e});

            var lastNews = this.getLastThreeNews();
            _.each(lastNews, (entry: any)=> {
                this.timeline.fromTo(entry, 0.25,
                    {y: 30, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: e}, '-=0.15');
            });

            this.timeline.fromTo(this._pageUp, 0.2,
                {marginTop: 10, opacity: 0}, {marginTop: 0, opacity: 0.2, onComplete: ()=> {
                    $(this._pageUp).attr('style', '');
                }}, '+=0.2');
            this.timeline.fromTo(this._pageDown, 0.2,
                {autoAlpha: 0, marginTop: 0}, {autoAlpha: 1, marginTop: 10, onComplete: ()=> {
                    $(this._pageDown).attr('style', '');
                }}, '-=0.2');

            this.timeline.timeScale(1.3);
        }

        public loadJSONData( jsonData: any) {
            _.each(jsonData, (item: IPressEntry, index: number)=> {
                this.koPressEntries.push(item);
                //console.log(item, index);
            });
            this.createTimeline();
        }
    }
}