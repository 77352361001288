/**
 * Created by had on 11/20/14.
 */

/// <reference path="../views/InfographicVsView.ts" />
/// <reference path="../services/HRServices.ts" />
/// <reference path="../events/InfographicVsEvent.ts" />
/// <reference path="../events/FilterEvents.ts" />


module media.hrratings.mediators {

    import InfographicVsView = media.hrratings.views.InfographicVsView;
    import HRServices = media.hrratings.services.HRServices;
    import EventDispatcher = soma.EventDispatcher;
    import LocaleEvent = media.hrratings.events.LocaleEvent;
    import InfographicVsEvent = media.hrratings.events.InfographicVsEvent;
    import PlaygroundEvents = media.hrratings.events.HomeEvents;


    export class InfographicVsMediator {

        static inject:string[] = ["target", "dispatcher", "service", "lang"];

        constructor(target: InfographicVsView, dispatcher: EventDispatcher, service: HRServices, lang:string) {

            dispatcher.addEventListener(LocaleEvent.LOCALE_READY,
                function () {
                    target.setupBindings();
            });


            target.addEventListener(InfographicVsEvent.REQUEST_PERIOD, (event: InfographicVsEvent) => {

                service.getVSInfographicPeriod(event.id, event.category, event.year, lang)
                .then(function(data:any) {
                    if(event.info == "left") {
                        target.updateLeftPeriod(data);
                    } else {
                        target.updateRightPeriod(data);
                    }
                });
            });


            target.addEventListener(InfographicVsEvent.INFOGRAPHIC_VS, (event: InfographicVsEvent) => {

                service.getInfographic(event.id, event.category, event.year, lang)
                    .done( (data: any) => {
                        //pending VO / client?
                        target.loadResults(data);

                        service.getVSInfographicAvailablePeriods(lang)
                            .then(function(data:Array<any>){
                                target.setPeriodDates(data);
                            });
                    })

                    .fail( () =>{
                        target.loadError();
                    });
            });
        }
    }
}
