/** Created by Ivan on 3/11/15. */

/// <reference path="./AbstractDialogView.ts" />
/// <reference path="./icons/Profile.ts" />
/// <reference path="./icons/Vs.ts" />
/// <reference path="./icons/Ranking.ts" />
/// <reference path="./../filters/charts/ChartFilter.ts" />
/// <reference path="./../filters/charts/ChartFilterItem.ts" />

module media.hrratings.views {

    import ViewManager = media.hrratings.core.ViewManager;
    import Profile = media.hrratings.icons.Profile;
    import Vs = media.hrratings.icons.Vs;
    import Ranking = media.hrratings.icons.Ranking;
    import ChartFilter = media.hrratings.filters.ChartFilter;
    import ChartFilterItem = media.hrratings.filters.ChartFilterItem;

    export class HomeView extends AbstractDialogView {

        private _elPanel: HTMLElement;
        private _elMiniChartsRank: HTMLElement;
        private _elMiniChartsProf: HTMLElement;
        private _elMiniChartsVs: HTMLElement;
        private _chartFilter: ChartFilter;

        private _elBg: HTMLElement;
        private _elTitle: HTMLElement;
        private _elRowA: JQuery;
        private _elRowB: JQuery;
        private _elBottom: JQuery;

        private _elRankingLink: JQuery;
        private _elProfileLink: JQuery;
        private _elVersusLink: JQuery;

        constructor(_target: HTMLElement) {
            super(_target);
            this._chartFilter = new ChartFilter();
            this._chartFilter.setTypes(['ranking', 'profile', 'versus']);

            this._cacheElements();
            this._createMiniCharts();
        }

        initialize(lang: string) {
            this._chartFilter.fetchItems(lang);
            this._bindChartLinks();
        }

        getPanel (): HTMLElement {
            return this._elPanel;
        }

        setupBindings(): void {
            ko.applyBindings(this, this._elPanel);
        }

        /**
         * @protected
         * @param d
         */
        open_hook (d:Q.Deferred<void>):void {
            TweenLite.set(this.target, {css:{display:'block'}});
            TweenLite.set(this.getPanel(), {top: '0px', autoAlpha: 0});
            this._tlDialogClose.stop();
            this._tlDialogOpen.play(0);
            setTimeout( () => { d.resolve(); }, 500);
        }

        /**
         * @protected
         * @param d
         */
        close_hook (d:Q.Deferred<void>):void {

            this._tlDialogOpen.stop();
            this._tlDialogClose.play(0);
            setTimeout( () => {
                TweenLite.set(this.getPanel(), {top: '-100%'});
                TweenLite.set(this.target, {css:{display:'none'}});
                d.resolve();
            }, 250);
        }

        private _cacheElements (): void {
            this._elPanel = $(this.target).find('#home').get(0);

            this._elMiniChartsRank  = document.getElementById("rank-icon-cont"              );
            this._elMiniChartsProf  = document.getElementById("profile-icon-cont"           );
            this._elMiniChartsVs    = document.getElementById("vs-icon-cont"                );
            this._elBg              = $("#home .w-dialog-wrapper"                           ).get(0);
            this._elTitle           = $("#home h2"                                          ).get(0);
            this._elRowA            = $("#row-a"                                             );
            this._elRowB            = $("#row-b"                                             );
            this._elBottom          = $("#hline1, #hline2, #row-c, #row-d, #bottom-block"   );
            this._elProfileLink     = $("#profile-link");
            this._elRankingLink     = $("#ranking-link");
            this._elVersusLink      = $("#versus-link");
        }

        private _bindChartLinks(): void {
            this._chartFilter.onFilterReady(()=> {
                var triad = this._chartFilter.getLastChartsTriad();
                if (triad.length !== 3) return;
                //console.log(triad);
                var rankingItem = <ChartFilterItem>_.findWhere(triad, {type: 'ranking'});
                var versusItem = <ChartFilterItem>_.findWhere(triad, {type: 'versus'});
                var profileItem = <ChartFilterItem>_.findWhere(triad, {type: 'profile'});
                this._elProfileLink.attr('href', profileItem.chartHref);
                this._elVersusLink.attr('href', versusItem.chartHref);
                this._elRankingLink.attr('href', rankingItem.chartHref);
            });
        }

        private _createMiniCharts():void {
            var rank = new Ranking(this._elMiniChartsRank);
            var vs = new Vs(this._elMiniChartsVs);
            var profile = new Profile(this._elMiniChartsProf);

            $(this._elMiniChartsRank)
                .mouseenter( function(){ rank.pathTrans(1); })
                .mouseleave( function(){ rank.pathTrans(0); });

            $(this._elMiniChartsProf)
                .mouseenter( function(){ profile.pathTrans(1); })
                .mouseleave( function(){ profile.pathTrans(0); });

            $(this._elMiniChartsVs)
                .mouseenter( function(){ vs.pathTrans(1); })
                .mouseleave( function(){ vs.pathTrans(0); });

        }

        private get _tlDialogOpen (): TimelineLite {
            if(this.$tlDialogOpen == null){
                this.$tlDialogOpen = new TimelineLite({autoStart: false, ease: Cubic.easeInOut});
                this.$tlDialogOpen.to([this._elBg, this.getPanel(), this._elTitle]  , 0.25, {autoAlpha: 1        }          );
                this.$tlDialogOpen.to(this._elRowA                                  , 0.25, {top: 0, autoAlpha: 1}, "-=0.1" );
                this.$tlDialogOpen.to(this._elRowB                                  , 0.25, {top: 0, autoAlpha: 1}, "-=0.1" );
                this.$tlDialogOpen.to(this._elBottom                                , 0.25, {autoAlpha: 1        }, "-=0.1" );
            }
            return this.$tlDialogOpen;
        }

        private get _tlDialogClose (): TimelineLite {
            if(this.$tlDialogClose == null){
                this.$tlDialogClose = new TimelineLite({autoStart: false, ease: Cubic.easeInOut});
                this.$tlDialogClose.to([this._elBg, this.getPanel(), this._elTitle, this._elBottom ]  , 0.25, {autoAlpha: 0});
                this.$tlDialogClose.to([this._elRowA, this._elRowB], 0.25, {autoAlpha: 0, onComplete: () => {
                    this._elRowA.css({"top": "100px"});
                    this._elRowB.css({"top": "100px"});
                }}, 0 );
            }
            return this.$tlDialogClose;
        }

        private $tlDialogOpen: TimelineLite = null;
        private $tlDialogClose: TimelineLite = null;
    }
}