/**
 * Created by Esteban on 10/27/14.
 */

module media.hrratings.vo {
    export class ArticleVO {
        public title:string;
        public subtitle:string;
        public content:string;
        public articleName:string;
        public articleURL:string;

        static fromRawData(data:any):ArticleVO {
            return $.extend(new ArticleVO(), data);
        }
    }
}