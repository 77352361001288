/**
 * Created by had on 2/25/15.
 */
/**
 * Created by Esteban on 10/21/14.
 */

/// <reference path="./../AbstractServicesTab.ts" />

module media.hrratings.views.services {

    import AbstractServicesTab = views.AbstractServicesTab;
    export class MethodologyMonitoring extends AbstractServicesTab {}

}
